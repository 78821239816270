import store from '@/store/index.js'

import { Auth } from 'aws-amplify'
import Settings from '@/views/Settings/Settings'
import SettingsNoSelection from '@/views/Settings/SettingsNoSelection'
import CompanyDetails from '@/views/Settings/CompanyDetails/CompanyDetails'
import DriverFeedback from '@/views/Settings/DriverFeedback/DriverFeedback'
import Messages from '@/views/Settings/Messages/Messages'
import Messenger from '@/views/Settings/Messenger/Messenger'
import DropDowns from '@/views/Settings/DropDowns/DropDowns'
import UserIndex from '@/views/Settings/Users/UserIndex'
import UserForm from '@/views/Settings/Users/components/UserForm'
import InvoiceIndex from '@/views/Settings/Billing/InvoiceIndex'
import AccountDetails from '@/views/Settings/Accounts/AccountDetails'
import HeraAIIndex from '@/views/Settings/HeraAI/HeraAI'
import DailyRoster from '@/views/Settings/DailyRoster/SettingsDailyRoster'
import Counselings from '@/views/Counseling/components/SettingsCounselings'
import CustomLists from '@/views/Settings/CustomLists/CustomLists'
import VehiclePhotoLogs from '@/views/Settings/VehiclePhotoLogs/VehiclePhotoLogs'
import RosterChecklistIndex from '@/views/Settings/RosterChecklist/RosterChecklistIndex'
import RosterChecklistEditor from '@/views/Settings/RosterChecklist/components/RosterChecklistEditor'
import RosterChecklistsTableCard from '@/views/Settings/RosterChecklist/components/RosterChecklistsTableCard'
import Error from '@/views/Settings/Error/Error'
import Alert from '@/views/Settings/Error/Alert'
import { isAllowedByRoute } from '@/middlewares/permissions'

import Labels from '@/views/Settings/Labels/LabelsIndex'

var USER_PERMISSIONS =  process.env.VUE_APP_USER_PERMISSIONS === "1";

export default [
    {
      path: '',
      name: 'SettingsNoSelection',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: SettingsNoSelection
      }
    },
    {
      path: 'company',
      name: 'SettingsCompanyDetails',
      meta: { crumbTitle: 'Company Details'},
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: CompanyDetails
      }
    },
    {
      path: 'driver-feedback',
      name: 'SettingsThresholds',
      meta: { crumbTitle: 'Driver Feedback'},
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: DriverFeedback
      }
    },
    {
      path: 'messages',
      name: 'SettingsMessageLogs',
      meta: { crumbTitle: 'Messages'},
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: Messages
      }
    },
    {
      path: 'messenger',
      name: 'SettingsMessenger',
      meta: { crumbTitle: 'Messenger'},
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: Messenger
      }
    },
    {
      path: 'counselings',
      name: 'SettingsCounselings',
      meta: { crumbTitle: 'Counselings'},
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: Counselings
      }
    },
    {
      path: 'drop-downs',
      name: 'SettingsDropDowns',
      meta: { crumbTitle: 'Drop Down Lists'},
      beforeEnter: onlyAllowAdmin,
      props: true,
      components: {
        default: Settings,
        settings: DropDowns
      }
    },
    {
      path: 'users',
      name: 'SettingsUserIndex',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: UserIndex
      }
    },
    {
        path: 'users/detail/:id?',
        name: 'SettingsUserDetail',
        beforeEnter: onlyAllowAdmin,
        props: true,
        components: {
          default: Settings,
          settings: UserForm
        }
    },
    {
      path: 'account-details',
      name: 'SettingsAccountDetails',
      beforeEnter: onlyAllowAdmin,
      props: true,
      components: {
        default: Settings,
        settings: AccountDetails
      }
    },
    {
      path: 'invoices',
      name: 'SettingsBilling',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: InvoiceIndex
      }
    },
    {
      path: 'hera-ai',
      name: 'SettingsHeraAIIndex',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: HeraAIIndex
      }
    },
    {
      path: 'daily-roster',
      name: 'SettingsDailyRoster',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: DailyRoster
      }
    },
    {
      path: 'custom-lists',
      name: 'SettingsCustomLists',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: CustomLists
      }
    },
    {
      path: 'vehicle-photo-logs',
      name: 'SettingsVehiclePhotoLog',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: VehiclePhotoLogs
      }
    },
    {
      path: 'roster-checklists',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: RosterChecklistsTableCard,
        settings: RosterChecklistIndex
      },
      children: [
        {
          path: '/',
          name: 'SettingsRosterChecklistsIndex',
          beforeEnter: onlyAllowAdmin,
          component: RosterChecklistsTableCard
        },
        {
          path: ':rosterChecklistId',
          name: 'SettingsRosterChecklistsEditor',
          props: true,
          beforeEnter: onlyAllowAdmin,
          component: RosterChecklistEditor
        },
      ]
    },
    {
      path: 'error',
      name: 'SettingsError',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: Error
      }
    },
    {
      path: 'alert',
      name: 'SettingsAlert',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: Alert
    },
      path: 'labels',
      name: 'SettingsLabels',
      beforeEnter: onlyAllowAdmin,
      components: {
        default: Settings,
        settings: Labels
      }
    }
  ]


  async function onlyAllowSystemAdmin(to, from, next){
    window.scrollTo(0, 0);
    const groups = await getGroups()
    if(!groups.includes("system_admin")){
      next({
        path: from.path,
        query: { error: "You Don't Have Access To This Area" }
      })
    }else{
      next()
    }
  }

  async function onlyAllowAdmin(to, from, next){
    window.scrollTo(0, 0);
    const groups = await getGroups()
    if(!USER_PERMISSIONS && (!groups.includes("system_admin") && !groups.includes("admin") && !store.state.userInfo.role.includes("admin"))){
      next({
        path: from.path,
        query: { error: "You Don't Have Access To This Area" }
      })
    }else if(USER_PERMISSIONS) {
      try {

        let isAllowed = await isAllowedByRoute(store.state.userInfo.id, store.state.userInfo.group, to.path)

        if(!isAllowed) {
          next({
            path: '/settings/alert'
          })
        } else {
          next()
        }
      } catch(e) {

        await Auth.signOut()
        next({
          path: '/',
          query: { error: "Please contact your administrator for access to these settings" }
        })
      }
    }else{
      next()
    }
  }

  function getGroups () {
    return new Promise((resolve, reject) => {
      if (store.getters.groups === undefined) {
        const unwatch = store.watch(
          () => store.getters.groups,
          (value) => {
            unwatch()
            resolve(value)
          }
        )
      } else {
        resolve(store.getters.groups)
      }
    })
  }

import { mapState, mapGetters } from 'vuex';
import { createLabel, createLabelTypeList, deleteLabelTypeList, updateLabel } from '@/graphql/mutations';
import { deleteLabelSubscription } from "@/graphql/mutations";
import { getLabel, 
  labelSubscriptionByLabelId, labelTypeByNameAndGroup, 
  labelSubscriptionByVehicleLabel, labelsByGroupAndName,
  labelSubscriptionByStaffLabel, createLabelSubscription } from './labelsQueries'
// subscriptions
import { updateElement } from '@/store/subscriptionModule/helper'
import { getVehicle, getStaff } from '@/store/queries'


export default {
  data() {
    return {
      LM_createLabelTypeList: [],
      LM_deleteLabelTypeList: [],
    }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapGetters([
      'getLabelTypes',
    ])
  },
  methods: {
    /**
     * list Labels by group
     */
    async labelsModule_loadLabels(){
      try{
        const input = {
            group: this.userInfo.tenant.group,
            limit: 25
        }
        // return in labels alph order
        let labels = await this.gLoadListAll( labelsByGroupAndName, input, 'labelsByGroupAndName')

        // order by labelType list name
        for ( const label of labels ) {
          label.typeList.items.sort((a, b) => {
            const nameA = a.type.name.toLowerCase();
            const nameB = b.type.name.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
        }
        return labels
      }catch(e){
          console.error(e)
          this.displayUserError(e)
      }
    },
    /**
     * filter a list of labels to active
     */
    async filterOnlyActiveLabels(labelList){
      let activeList = []
      if (labelList.length > 0) {

        activeList = labelList.filter(labelItem => labelItem.label.status === true);
      }
      return activeList
    },
    /**
     * check if a label name exist
     * return active labels per each labeltype: vehicle or assocaite
     */
    async labelsModule_loadActiveLabelsByLabelType(name){
      const input = {
        group: this.userInfo.tenant.group,
        name: {
          eq: name,
        }
      }
      try {
        const result = await this.gLoadListAll(labelTypeByNameAndGroup, input, "labelTypeByNameAndGroup")
        if (!result || !result.length)
          return []
        let labelsByType = result[0].labelList.items ? result[0].labelList.items : []
        let activeLabels = await this.filterOnlyActiveLabels(labelsByType)
        return activeLabels
      } catch(e) {
        console.error("error:", e)
      }
    },
    /**
     * get a label by name
     */
    async labelsModule_getLabel(labelID){
      try{
        const result = await this.api( getLabel, {id: labelID})
        return result.data.getLabel
      }catch(e){
          console.error(e)
          this.displayUserError(e)
      }
    },
    /**
     * check if a label name exist
     */
    async labelsModule_labelNameExist(name){
      const lowerCaseSearchName = name.toLowerCase()
      try{
        const input = {
            group: this.userInfo.tenant.group,
            limit: 25
        }
        const labels = await this.gLoadListAll( labelsByGroupAndName, input, 'labelsByGroupAndName')
        for ( const label of labels ) {
          if (label.name.toLowerCase() == lowerCaseSearchName) return true
        }
        return false
      }catch(e){
          console.error(e)
          this.displayUserError(e)
      }
    },
    /**
     * create label type list
     */
    async labelsModule_createLabelTypeList( labelID, newLabelType ){
      try{
          for( const option of newLabelType ) {
              const typeID = this.getLabelTypes.find( type => option === type.name).id

              const input = { 
                  labelTypeListLabelId : labelID,
                  labelTypeListTypeId : typeID,
              }
              await this.api(createLabelTypeList, {input})
          }
      }catch(e){
          console.error("labelsModule_createLabelTypeList failed")
          console.error(e)
          this.displayUserError(e)
      }
    },
    formatTextAppliedTo(subsLength, entity){
      let entity2 = entity
      if (subsLength == 1 && entity === "Associates") entity2 = "associate"
      else if (subsLength == 1 && entity === "Vehicles") entity2 = "vehicle"
      const message = `${subsLength.toString()} ${entity2}`
      return message
    },
    /**
     * check if the type list exist
     */
    async labelsModule_typeListExist(labelID, labelTypeAssigned){

      let deleteDialog = {
        visible: false,
        message: "",
      }
      // check subscriptions
      
      let entity = labelTypeAssigned
      let subs = await this.labelsModule_labelSubscriptionByLabelId(labelID, labelTypeAssigned)
      let subsLength = subs.length
      if ( subsLength > 0) {
        const message2 = this.formatTextAppliedTo(subsLength, entity)
        const message = `Warning: this Label as already been applied to ${message2}. Continuing will remove this Label from these ${entity}. Are you sure you want to continue?`
        deleteDialog = {
          visible: true,
          message,
        }
      }
      return deleteDialog
    },
    /**
     * delete label type list
     * connection between label and labelist
     * THIS FUNCTION ONLY DELETE, NEED A VALIDATOR BEFORE
     */
    async labelsModule_deleteLabelTypeList( labelID, deleteLabelType ){
      try{
          const label = await this.labelsModule_getLabel(labelID)
          const labelTypeAssigned = label.typeList.items
          const labelSubscriptions = label.items.items
          for( const item of labelTypeAssigned ) {
            for ( const option of deleteLabelType) {
              if (item.type.name === option) {
                let input = {
                  id: item.id
                }
                if (item.id) await this.api(deleteLabelTypeList, {input})
              }
            }
          }
      }catch(e){
        console.error(e)
        this.displayUserError(e)
      }
    },
    /**
     * 
     * @param {Object} labelData 
     * @param {[labelType]} newLabelType 
     * @returns Dialog on if the validation is false and fill the create or delete labelType
     */
    async labelsModule_validateBeforeDeleteLabelType( labelData, newLabelType ){
      // first part: get labeltype to see what exist
      const labelId = labelData.id
      const actualLabelType = labelData.usedFor
      let createLabelTypeAssignation = []
      let deleteLabelTypeAssignation = []
      let deleteDialog = {visible: false}

      // check what need to exist and the gap and create the gap
      for( const option of newLabelType ) {
        if (!actualLabelType.includes(option)) createLabelTypeAssignation.push(option)
        this.LM_createLabelTypeList = createLabelTypeAssignation
      }
      
      // check what exist and need to erase
      for( const type of actualLabelType ) {
        if (!newLabelType.includes(type)) deleteLabelTypeAssignation.push(type)
        this.LM_deleteLabelTypeList = deleteLabelTypeAssignation
      }

      // validate if any deleteSubscriptions have any subscriptions
      if (deleteLabelTypeAssignation) {
        const label = await this.labelsModule_getLabel(labelData.id)
        const labelTypeAssigned = label.typeList.items
        const labelSubscriptions = label.items.items
        let subscriptionsFiltered = []
        for( const item of labelTypeAssigned ) {
          for ( const option of deleteLabelTypeAssignation) {
            if (item.type.name === option) {
              deleteDialog = await this.labelsModule_typeListExist(labelId, option)
              if (option == "Associates") subscriptionsFiltered = labelSubscriptions.filter(obj => obj.staffId !== null)
              else if (option == "Vehicles") subscriptionsFiltered = labelSubscriptions.filter(obj => obj.vehicleId !== null)
              deleteDialog.subscriptions = subscriptionsFiltered
              return deleteDialog
            }
          }
        }
      }
      return deleteDialog
    },

    /**
     * update label type list
     * has a dependance of validations 
     */
    async labelsModule_updateLabelTypeList( labelData, newLabelType ){
      // first part: get labeltype to see what exist
      let createLabelTypeAssignation = this.LM_createLabelTypeList
      let deleteLabelTypeAssignation = this.LM_deleteLabelTypeList    
      
      if (deleteLabelTypeAssignation) await this.labelsModule_deleteLabelTypeList(labelData.id, deleteLabelTypeAssignation)
      if (createLabelTypeAssignation) await this.labelsModule_createLabelTypeList(labelData.id, createLabelTypeAssignation)
      return true
    },
    
    /**
     * create label
     */
    async labelsModule_createLabel(name, newLabelType){
      // first part: check if label exist
      name = name.trimStart()
      name = name.trimEnd()
      let labelExist = await this.labelsModule_labelNameExist(name)
      if (labelExist === true) {
        this.displayUserError("This Label already exists")
        return false
      }

      // second part: create label
      try {
        const input = {
          name: name,
          group: this.userInfo.tenant.group,
          status: true
        }
        let newLabel = await this.api(createLabel, {input})
        newLabel = newLabel.data.createLabel
        // third part: create newLabelType
        await this.labelsModule_createLabelTypeList(newLabel.id, newLabelType)
        await this.displayUserNotification({
          type: "success",
          message: "Label created"
        })
        return newLabel.id

      } catch( e ){
        this.displayUserError(e)
      }
    },
    /**
     * update a label
     */
    async labelsModule_updateLabel(newLabelName, newLabelType, labelData, updateStatus){
      // first part: check if label exist\
      let labelExist = false
      let result = true
      if (newLabelName.toLowerCase() !== labelData.labelName.toLowerCase()) labelExist = await this.labelsModule_labelNameExist(newLabelName)
      if (labelExist === true){
        this.displayUserError("This Label already exists")
        return false
      }

      // second part: update labelType: check if it exist, delete, create
      const deleteTypeMessage = await this.labelsModule_validateBeforeDeleteLabelType(labelData, newLabelType)

      if (deleteTypeMessage.visible == true) {
        await this.$confirm(deleteTypeMessage.message, 'Warning', {
              confirmButtonText: 'Yes, continue',
              cancelButtonText: 'No, cancel',
              type: 'warning'
            }).then(() => {
              // first eliminate all the subscriptions
              for(const subs of deleteTypeMessage.subscriptions){
                this.labelsModule_deleteLabelSubscription(subs.id)
              }
              // continue with the workflow of update
              result = true
            }).catch(() => {
              result = false
            });
      }
      // if the user want to continue edit
      if (result == false) return result

      // third part: update labelType
      await this.labelsModule_updateLabelTypeList(labelData, newLabelType)

      // final part: update label
      try {
        let input = {
          id: labelData.id,
          name: newLabelName,
          group: this.userInfo.tenant.group
        }
        if (updateStatus) input.status = updateStatus
        await this.api(updateLabel, {input})

      // display notification
      await this.displayUserNotification({
        type: "success",
        message: "Label Updated"
      })
      return true
      } catch( e ){
        this.displayUserError(e)
      }
    },
    /**
     * update label - update status of label
     */
    async labelsModule_changeStatus( labelID, newStatus ){
      try{
          const input = { 
              id: labelId,
              status: newStatus
          }
          await this.api(updateLabel, {input})
      }catch(e){
          console.error(e)
          this.displayUserError(e)
      }
    },
    /**
     * getLabelSubscription
     */
    async labelsModule_getLabelSubscription(labelType, profileId, onlyActive = false) {
      let labelsSubs = [];
      try {
        if (labelType === "Associates") {
          const input = {
            staffId: profileId,
          };
          labelsSubs = await this.gLoadListAll(labelSubscriptionByStaffLabel, input, 'labelSubscriptionByStaffLabel');
        } else if (labelType === "Vehicles") {
          const input = {
            vehicleId: profileId,
          };
          labelsSubs = await this.gLoadListAll(labelSubscriptionByVehicleLabel, input, 'labelSubscriptionByVehicleLabel');
        }
      } catch (e) {
        console.error(e);
      }
      if (onlyActive) labelsSubs = await this.filterOnlyActiveLabels(labelsSubs)
      return labelsSubs
    },
    /**
     * check if subscription exists before record
     */
    async checkIfSubscriptionExists(labelId, labelType, profileDataId) {
      let profileSubscriptions = await this.labelsModule_getLabelSubscription(labelType, profileDataId)
      const labelSubscriptionItem = profileSubscriptions.find(labelSub => labelSub.id === labelId)
      if (labelSubscriptionItem) {
        return true
      }
      return false
    },
    /**
     * create a subscription between label and vehicle
     */
    async labelsModule_createLabelSubscription(labelId, labelType, profileDataId, validation = true, profileData = {}){
      try {
        let input = {
          labelId: labelId,
          group: this.userInfo.tenant.group,
        };
        let oldItem = profileData
        // check if the subscription exist
        let updateElements = []
        let subscriptionExist = false
        if (validation === true ) subscriptionExist = await this.checkIfSubscriptionExists(labelId)
        if (!subscriptionExist) {
          
          if (labelType === "Associates") {
            input.staffId = profileDataId;
            await this.api(createLabelSubscription, { input }).then (async () => {
              await this.api(getStaff, { id: profileDataId })
                .then(associateResult => {
                  const updatedAssociate = associateResult.data.getStaff;
                  const newItem = {
                    labels: {...updatedAssociate.labels},
                  }
                  updateElement(oldItem, newItem, true)
                  updateElements.push(updateElement)
                })
                .catch(error => console.error('Error fetching vehicle:', error));
            })

          } else if (labelType === "Vehicles") {
            input.vehicleId = profileDataId;
            await this.api(createLabelSubscription, { input }).then (async () => {
              await this.api(getVehicle, { id: profileDataId })
                .then(vehicleResult => {
                  const updatedVehicle = vehicleResult.data.getVehicle;
                  const newItem = {
                    labels: {...updatedVehicle.labels},
                  }
                  updateElement(oldItem, newItem, true)
                  updateElements.push(updateElement)
                })
                .catch(error => console.error('Error fetching vehicle:', error));
            })
          }
          
        } else {
          throw "Record was updated by another user. Please copy any changes and refresh the page."
        }
        return await Promise.all(updateElements);
      } catch (e) {
        console.error("error", e)
        this.displayUserError(e)
      }
    },

    async updateVehicleData(profileData) {
      try {
        let oldItem = profileData
        await this.api(getVehicle, { id: profileData.id })
          .then(vehicleResult => {
            const newItem = vehicleResult.data.getVehicle;
            updateElement(oldItem, newItem, true)
          })
          .catch(error => console.error('Error fetching vehicle:', error));
      } catch (error) {
        console.error('Error fetching vehicle:', error);
      }
    },
    /**
     * delete a subscription between label and vehicle
     */
    async labelsModule_deleteLabelSubscription(labelSubscriptionId, profileData = {} ){
      try {
        const input = {
          id: labelSubscriptionId,
        };
        await this.api(deleteLabelSubscription, { input });
        if(profileData && profileData.id) {
          let oldItem = profileData
          await this.api(getVehicle, { id: profileData.id })
            .then(vehicleResult => {
              const newItem = vehicleResult.data.getVehicle;
              updateElement(oldItem, newItem, true)
            })
            .catch(error => console.error('Error fetching vehicle:', error));  
        }
      } catch (e) {
        console.error("error", e)
        this.displayUserError(e)
      }
    },
    /**
   * get a list of all descriptions depending of labelId
   */
  async labelsModule_labelSubscriptionByLabelId(labelId, typeName){
    let input = {
      labelId: labelId,
    };
    let dataLabel = await this.gLoadListAll(
      labelSubscriptionByLabelId,
      input,
      "labelSubscriptionByLabelId"
    );
    if (typeName === "Associates") dataLabel = dataLabel.filter(obj => obj.staff !== null)
    else if (typeName === "Vehicles") dataLabel = dataLabel.filter(obj => obj.vehicle !== null)
    return dataLabel
  },
}}
import { API, graphqlOperation } from 'aws-amplify'
import { getUser } from '@/api/queries'

export async function isAllowedByRoute(userId, group, route) {

    if(!route) {
        throw "route variable cannot be empty"
    }

    let userPermissions = await getUserPermissions(userId, group)
    let isAllowed = Object.keys(userPermissions)

    if(!userPermissions.permissionLogin) {
        throw "permissionLogin is false"
    }

    // array of tuples
    let mapUrlToPermissions = [
        ['/settings/account-details', 'permissionFullAccess'],
        ['/settings/invoices', 'permissionFullAccess'],
        ['/settings/company', 'permissionFullAccess'],
        ['/settings/users', 'permissionFullAccess'],
        ['/settings/hera-ai', 'permissionFullAccess'],
        ['/settings/driver-feedback', 'permissionFullAccess'],
        ['/settings/messages', 'permissionFullAccess'],
        ['/settings/messenger', 'permissionMessenger'],
        ['/settings/counselings', 'permissionCounselings'],
        ['/settings/drop-downs', 'permissionCustomLists'],
        ['/settings/custom-lists', 'permissionCustomLists'],
        ['/settings/vehicle-photo-logs', 'permissionVehiclePhotoLogs'],
        ['/settings/labels', 'permissionManageLabels'],
        ['/settings/roster-checklists', 'permissionCompanySettingsRosterChecklistTemplates']
    ]

    let isRouteProtectedByPermission = mapUrlToPermissions.some(permission => permission[0] == route)

    if(isRouteProtectedByPermission) {
        if(isAllowed.includes('permissionFullAccess')) {
            return true
        }

        let permissionRequiredByRoute = mapUrlToPermissions
        .filter(permission => permission[0] == route)
        .map(userPermission => userPermission[1])

        return isAllowed.includes(permissionRequiredByRoute[0])
    }

    return true

}

export async function getTestingAandB(group, epic){
    //get table -> testingAB
    console.log('group', group)
    // openai
    if (epic == 'openAi') {
        return false
    // return user.info.tenant.hasopenAi
    } else return true
}

export async function getUserPermissions(userId, group) {

    let user = await API.graphql(graphqlOperation(getUser, {id: userId, group: group}));

    let userData = user.data.getUser

    // array of tuples
    let userPermissions = [
        ['permissionLogin', userData.permissionLogin],
        ['permissionFullAccess', userData.permissionFullAccess],
        ['permissionDocuments', userData.permissionDocuments],
        ['permissionCounselings', userData.permissionCounselings],
        ['permissionManageCounselings', userData.permissionManageCounselings],
        ['permissionAccidents', userData.permissionAccidents],
        ['permissionInjuries', userData.permissionInjuries],
        ['permissionDrugTests', userData.permissionDrugTests],
        ['permissionMessenger', userData.permissionMessenger],
        ['permissionPerformanceCoaching', userData.permissionPerformanceCoaching],
        ['permissionDAManagement', userData.permissionDAManagement],
        ['permissionCustomLists', userData.permissionCustomLists],
        ['permissionVehiclePhotoLogs', userData.permissionVehiclePhotoLogs],
        ['permissionManageLabels', userData.permissionManageLabels],
        ['permissionVehicleManagement', userData.permissionVehicleManagement],
        ['permissionTasksReports', userData.permissionTasksReports],
        ['permissionCompanySettingsRosterChecklistTemplates', userData.permissionCompanySettingsRosterChecklistTemplates],
        ['permissionMessageTemplate', userData.permissionMessageTemplate]
    ]

    let isAllowed = {}

    if(userData.permissionFullAccess) {
        for (const key of userPermissions) {
            isAllowed[key[0]] = true;
        }
        isAllowed.permissionLogin = userData.permissionLogin
        return isAllowed
    }

    let userPer = userPermissions
    .filter(userPermission => userPermission[1] == true)
    for (const key of userPer) {
        isAllowed[key[0]] = true;
    }

    return isAllowed
}

export async function getRoute(userId, group){
    /**
     * Make Component SettingsNoSelection the default view
     */
    let route = 'SettingsNoSelection'

    return route
}
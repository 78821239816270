<template>
    <div class="settings-counselings" data-cy="counseling-templates">
        <div class="flex justify-between">
            <h1>Counselings</h1>
        </div>
        <div class="child-view">
            <div class="mt-4 grid grid-cols-8 grid-flow-row gap-4">
                <div class="col-span-12 relative">
                    <div class="text-gray-600 pb-2">
                        You can use Counseling Templates to quickly pre-fill answers to questions when creating a new Counseling in Hera.
                    </div>
                  
                    <table-card tablecard-class="rounded-lg">
                        <template v-slot:table-filters>
                            <container-filter inTable>
                                <template v-slot:title>
                                    <div class="text-center">
                                        <span>Counseling Templates</span>
                                    </div>
                                </template>
                            </container-filter>
                        </template>
                        <template v-slot:table-searchbox>
                            <search-box inTable @search-data="searchData"/>
                        </template>
                        <custom-table
                        inTable
                        v-loading="loading"
                        :columns="cols"
                        :data="filteredData"
                        emptyText="No Counseling Templates"
                        footerTable="Total Templates"
                        :totalRecords="totalTemplates"
                        withTableMenu
                        @click-menu="handleCommand"
                        :optionsRows="optionsContextRow"
                        :optionsHeader="optionsContextHeader"
                        data-cy="three_dotted_menu"
                        :defaultSort = "{prop: 'templateName', order: 'ascending'}" 
                        >
                            <template #createdAt="row">
                                {{$moment(row.createdAt).format('MM/DD/YYYY HH:mm')}}
                            </template>
                            <template #updatedAt="row">
                                {{$moment(row.updatedAt).format('MM/DD/YYYY HH:mm')}}
                            </template>
                            
                            <template #custom-dropdown="row">
                                <el-dropdown @command="handleCommand">
                                    <span class="context-menu"><i class="uil uil-ellipsis-h"></i></span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="{action: 'edit', row}">Edit</el-dropdown-item>
                                        <el-dropdown-item :command="{action: 'delete', row}">Delete</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </custom-table>  
                    </table-card>
                </div>
            </div>
        </div>

        <Drawer
        v-if="counselingTemplateDrawer.visible"
        :title="counselingTemplateDrawer.title" 
        :subtitle="counselingTemplateDrawer.subtitle"
        :visibility="counselingTemplateDrawer.visible" 
        @close-drawer="counselingTemplateDrawer.visible = false" 
        >
            <template v-slot:drawerContent>
                <div class="p-5">
                    <el-form
                    :model="counselingTemplateDrawer.form"
                    size="small"
                    :rules="templateRules"
                    ref="templateForm"
                        >
                        <el-form-item label="Template Name" prop="templateName">
                            <el-input v-model="counselingTemplateDrawer.form.templateName" :rows="2" type="textarea" placeholder="Aa" data-cy="template-name-in"/>
                        </el-form-item>
                        <el-form-item label="Statement of The Problem" prop="counselingNotes">
                            <el-input v-model="counselingTemplateDrawer.form.counselingNotes" :rows="2" type="textarea" placeholder="Aa" data-cy="counseling-notes-in"/>
                        </el-form-item>
                        <el-form-item label="Prior Discussion Summary" prop="priorDiscussionSummary">
                            <el-input v-model="counselingTemplateDrawer.form.priorDiscussionSummary" :rows="2" type="textarea" placeholder="Aa" data-cy="prior-discussion-summary-in"/>
                        </el-form-item>
                        <el-form-item label="Summary of Corrective Action Discussed" prop="correctiveActionSummary">
                            <el-input v-model="counselingTemplateDrawer.form.correctiveActionSummary" :rows="2" type="textarea" placeholder="Aa" data-cy="corrective-action-summary-in"/>
                        </el-form-item>
                        <el-form-item prop="consequencesOfFailure">
                            <template #label>
                                <div class="leading-4 text-left pb-2">Consequences of Failure to Improve Performance<br/>Or Corrective Behavior</div>
                            </template>
                                
                            <el-input v-model="counselingTemplateDrawer.form.consequencesOfFailure" :rows="2" type="textarea" placeholder="Aa" data-cy="consequences-of-failure-in"/>
                        </el-form-item>
                    </el-form>
                </div>
            </template>
            <template v-slot:drawerfooter-end>
                <ButtonGroupWrapper>
                    <template slot-scope="{disabled, setDisabled}">
                        <!-- span to trigger reactiity inside slot scope -->
                        <span hidden>{{ templateNameExists }}</span>
                        <el-button
                            @click="counselingTemplateDrawer.visible = false"
                            data-cy="drawer-cancel-btn"
                            :disabled="disabled"
                        >
                            Cancel
                        </el-button>
                        <ButtonWrapper
                            type="primary"
                            data-cy="drawer-save-btn"
                            :clickHandler="handleSave"
                            :disabled="disabled || templateNameExists"
                            @cb-start="setDisabled(true)"
                            @cb-end="setDisabled(false)"
                        >
                            <template v-if="counselingTemplateDrawer.type === 'create'">Create</template>
                            <template v-else-if="counselingTemplateDrawer.type === 'edit'">Update</template>
                        </ButtonWrapper>
                    </template>
                </ButtonGroupWrapper>
            </template>
        </Drawer>

        <!--DELETE DIALOG-->
        <el-dialog
        v-if="deleteDialog"
        title="Are you sure?"
        :visible.sync="deleteDialog.visible"
        :close-on-click-modal="false"
        width="30%"
        data-cy="modal_to_delete_da"
        >
            <div class="text-center">
                <div class="warning-message">Are you sure you want to delete this template?</div>
                <div class="text-red-500 font-bold">There is no undo for this action.</div>
            </div>
            <div slot="footer">
                <ButtonGroupWrapper class="flex justify-end">
                    <template slot-scope="{disabled, setDisabled}">
                        <el-button
                            type="default"
                            class="cancel-button"
                            @click="deleteDialog.visible = false"
                            :disabled="disabled"
                        >
                            Cancel
                        </el-button>
                        <ButtonWrapper
                            type="primary"
                            data-cy="dialog-delete-btn"
                            :clickHandler="handleDelete"
                            :disabled="disabled"
                            @cb-start="setDisabled(true)"
                            @cb-end="setDisabled(false)"
                        >
                            Confirm
                        </ButtonWrapper>
                    </template>
                </ButtonGroupWrapper>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import ContextMenuHeaderIcon from '@/components/ContextMenuHeaderIcon'
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import SearchBox from "@/components/TableCard/SearchBox";
import Drawer from '@/components/shared/Drawer/Drawer'
import { createCounselingTemplate, updateCounselingTemplate, deleteCounselingTemplate } from '@/graphql/mutations'
import { counselingTemplatesByGroup } from '@/graphql/queries'
import ButtonWrapper from '@/components/shared/ButtonWrapper/ButtonWrapper';
import ButtonGroupWrapper from '@/components/shared/ButtonGroupWrapper/ButtonGroupWrapper';

export default {
    components:{
        ContextMenuHeaderIcon,
        TableCard,
        CustomTable,
        ContainerFilter,
        SearchBox,
        Drawer,
        ButtonWrapper,
        ButtonGroupWrapper,
    },
    data(){
        return {
            loading: false,
            tableData:[],
            counselingTemplateDrawer: {
                visible: false,
                form: {}
            },
            deleteDialog: {
                visible: false,
                form: {}
            },
            totalTemplates: 0,
            templateRules: {
                templateName: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (!value) return callback(new Error('Please provide a Template Name'));
                            if (this.templateNameExists)
                                callback(new Error('Template name already exists'))
                            else
                                callback()
                        },
                        trigger: 'change'
                    }
                ],
                
            },
            cols: [
                { name: "Template Name", col: "templateName", fixed: false, width: "150", sortable: true, sortMethod: this.sortStringMethod},
                { name: "Date Created", col: "createdAt", fixed: false, width: "150", sortable: false, sortMethod: undefined},
                { name: "Date Updated", col: "updatedAt", fixed: false, width: "150", sortable: false, sortMethod: undefined },
            ],
            optionsContextRow: [],
            optionsContextHeader: [
                { label: "Create new Counseling Template", action: "create", divided: false },
            ],
            search: ''
        }
    },
    computed:{
        filteredData() {
            return this.filterArray(this.tableData, this.search);
        },
        templateNameExists(){
            const id = this.counselingTemplateDrawer.form?.id
            const templateName = this.counselingTemplateDrawer.form?.templateName
            if(!templateName)
                return false
            let predicate
            if(this.counselingTemplateDrawer.type === 'create')
                predicate = (row) => row.templateName === templateName
            if(this.counselingTemplateDrawer.type === 'edit')
                predicate = (row) => row.templateName === templateName && row.id !== id
            return this.tableData.some(predicate)
        }
    },
    mounted(){
        this.loadData()
    },
    methods:{
        async loadData(){
            this.loading = true
            let input = {
                group: this.$store.state.userInfo.tenant.group
            }
            this.tableData = await this.gLoadListAll(counselingTemplatesByGroup, input, 'counselingTemplatesByGroup')
            this.totalTemplates = this.tableData.length
            this.loading = false
        },
        async handleCommand({action, row}){
            switch(action){
                case 'create':
                    this.counselingTemplateDrawer = {
                        visible: true,
                        type: "create",
                        title:'Create a new Counseling Template',
                        subtitle:'Please fill in the form for the new counseling template',
                        form: {}
                    }
                    
                    break;
                case 'edit':
                    this.counselingTemplateDrawer = {
                        visible: true,
                        type: "edit",
                        form: {...row},
                        title:'Edit Counseling Template',
                        subtitle:'Fill in the form to update this counseling template',
                    }
                    break;
                case 'delete':
                    this.deleteDialog = {
                        visible: true,
                        form: {...row}
                    }
                    break;
            }
        },
        async handleSave(){
            try {
                try {
                    await this.$refs.templateForm.validate();
                } catch (error) {
                    throw new Error('Please fill out all required fields.');
                }
                const input = {
                    ...this.counselingTemplateDrawer.form,
                }
                switch(this.counselingTemplateDrawer.type){
                    case 'create':
                        await this.api(createCounselingTemplate, {input})
                        this.$message({
                            type: 'success',
                            message: 'Counseling Template Created'
                        })
                        break;
                    case 'edit':
                        await this.api(updateCounselingTemplate, {input})
                        this.$message({
                            type: 'success',
                            message: 'Counseling Template Updated'
                        })
                        break;
                }
                this.counselingTemplateDrawer.visible = false
                this.loadData()
            }
            catch (error) {
                this.printUserError(error);
                this.displayUserError(error);
            }
        },
        async handleDelete(){
            this.deleteDialog.visible = false
            const input = {
                id: this.deleteDialog.form.id
            }
            await this.api(deleteCounselingTemplate, {input})
            this.$message({
                type: 'success',
                message: 'Counseling Template Deleted'
            })
            this.loadData()
        },

        searchData(e){
            this.search = e
        },
        sortStringMethod(a,b) {
            return a.templateName.localeCompare(b.templateName);
        }
    }
}
</script>
<style lang="scss">
    .settings-counselings{
        .el-drawer__header {
            @apply bg-brand p-4 mb-0 ;
            color: #fff;
        }
    }
    .intercom-lightweight-app, .intercom-launcher-frame{
        z-index: 2002 !important;
    }
</style>
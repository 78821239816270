<template>
    <el-select v-model="formFields[prop]" class="w-full rcl-select" :class="{'rcl-show-hidden-option': fallbackOption}">
        <el-option
            v-if="fallbackOption"
            v-show="false"
            :label="fallbackOption.option"
            :value="fallbackOption.id"
            disabled
        />
        <el-option
        v-for="item in optionList"
        :key="item.id"
        :label="item.option"
        :value="item.id">
    </el-option>
</el-select>
</template>
<script>
export default{
    props: ['prop', 'formFields', 'optionList', 'optionListFallback'],
    computed:{
        fallbackOption(){
            const selectedTypeId = this.formFields[this.prop]
            const item = this.optionList.find(i=>i.id === selectedTypeId)
            if(!item){
                return this.optionListFallback.find(i=>i.id === selectedTypeId)
            }
        }
    }
}

</script>
<style lang="scss">
.rcl-select.rcl-show-hidden-option .el-input .el-input__inner{
    @apply text-red-500 #{!important};
}
</style>
<template>
  <div>
    <!--DELETE DIALOG-->
    <el-dialog
    title="Are you sure?"
    :visible.sync="deleteAccountModalOpen"
    width="30%"
    >
      <DeleteDialog 
      :deletionType="deletionType" 
      :deletionMessage="deletionMessage"
      :group="tenantData.group"
      :tenantID="tenantData.id"
      :stripeCustomerId="tenantData.stripeCustomerId"
      :zohoAccountID="tenantData.zohoCrmAccountRecordId"
      @close-deletion-modal="closeModal"
      @show-loading-screen="startLoading"
      @stop-loading="stopLoading"
      @finish-deletion="finishDeletion"/>
    </el-dialog>

    <!-- DEACTIVATE MODAL -->
    <DeactivateTenantModal 
      @close="deactivateModalOpen=false"
      :deactivateModalOpen="deactivateModalOpen" :deactivateTenantLoading="deactivateTenantLoading" :deactivateTenantMessage="deactivateTenantMessage">
    </DeactivateTenantModal>
    
    <div class="flex items-center justify-between">
      <div>
        <el-breadcrumb separator-class="uil uil-angle-right-b m-breadcrum">
          <el-breadcrumb-item :to="{ name: 'Dashboard' }"><i class="uil uil-home"></i></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'SystemNoSelection'}">System Admin</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'SystemTenantIndex'}">Tenants</el-breadcrumb-item>
          <el-breadcrumb-item >{{tenantData.companyName}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div>
        <el-dropdown @command="handleCommand">
          <ContextMenuHeaderIcon class="context-menu-header-hover"/>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item command="login">
               <i class="uil uil-users-alt text-base"></i>Log into this DSP as Support Administrator
            </el-dropdown-item>
            <el-dropdown-item data-cy="edit-tenant-modal" command="openEditTenantModal">
              <i class="uil uil-pen text-base"></i>Edit Tenant
            </el-dropdown-item>
            <el-dropdown-item v-if="accountTypeProps.menuOption" divided command="accountTypeMenuAction">
              <i class="uil uil-sitemap text-base"></i>{{ accountTypeProps.menuOption.label }}
            </el-dropdown-item>
            <el-dropdown-item v-if="!showCanceledNotes" :divided="!accountTypeProps.menuOption" command="changePlanToNone">
              <i class="uil uil-bill text-base"></i>Change Plan to None
            </el-dropdown-item>
            <el-dropdown-item command="deleteAccount">
              <i class="uil uil-trash text-base"></i>Delete Entire Account
            </el-dropdown-item>            
          </el-dropdown-menu>
        </el-dropdown>
        <el-dialog
          id="edit-tenant"
          title="Edit Tenant"
          :visible.sync="modalOpen"
          width="50%"
          :close-on-click-modal="false"
          >

          <TenantForm :formFields="editTenantData" :servicesMessage="servicesMessage" ref="tenantForm"/>

          <span slot="footer" class="dialog-footer">
            <el-button @click="modalOpen = false">Cancel</el-button>
            <el-button data-cy="update-tenant-action-btn" type="primary" @click="updateTenant">Update</el-button>
          </span>
        </el-dialog>
      </div>
    </div>


    <div class="mt-4">            
      
      <div class="flex">
        <h1>{{tenantData.companyName}}</h1>  
        <el-tooltip class="item flex" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
          <div slot="content" class="custom-tooltip-content-overflow">
            <p class="font-bold mb-1">Company Name Change Log</p>
            <p v-html="tenantData.nameLog"></p>
          </div>
          <p class="text-gray-600"><i class="uil uil-question-circle text-base"></i></p>
        </el-tooltip>

        <span class="self-center px-3 ml-5 font-bold rounded-full bg-opacity-10 px-3 text-xs  max-w-full flex-initial"
                  :class="setClassStatus(tenantData.customerStatus)">
           {{tenantData.customerStatus}}
        </span>

        <span
          style="padding-top: 1px; padding-bottom: 1px"
          class="self-center px-3 ml-5 font-bold rounded-full bg-opacity-10 px-3 text-xs  max-w-full flex-initial" :class="accountTypeProps.pill.class">
            {{ accountTypeProps.pill.label }}
        </span>
      </div>
 
      <el-form label-position="top" size="small" >
        <div class="flex justify-between">
          <el-form-item label="Short Code" prop="shortCode" class="non-edit-field" :class="{'placeholder': !tenantData.shortCode}">
            {{ tenantData.shortCode | text }}
          </el-form-item>
          <el-form-item label="Origination #" prop="originationNumber" class="non-edit-field" :class="{'placeholder': !tenantData.originationNumber}">
            {{ tenantData.originationNumber | text }}
          </el-form-item>
          <el-form-item label="Message Service Provider" prop="messageServiceProvider" class="non-edit-field" :class="{'placeholder': !tenantData.messageServiceProvider}">
            {{ tenantData.messageServiceProvider | text }}
          </el-form-item>
          <el-form-item v-if="tenantData.accountPremiumStatus && !!tenantData.accountPremiumStatus.includes('None')" label="Trial Expiration" prop="trialExpDate" class="non-edit-field" :class="{'placeholder': !tenantData.trialExpDate}">
            Expired
          </el-form-item>
          <el-form-item v-else label="Trial Expiration" prop="trialExpDate" class="non-edit-field" :class="{'placeholder': !tenantData.trialExpDate}">
            {{ tenantData.trialExpDate | date }}
          </el-form-item>
          <el-form-item :label="`Average Monthly Active ${$t('label.associates')}`" class="non-edit-field" :class="{'placeholder': !averageActiveStaffTotal}">
            {{ averageActiveStaffTotal | text }}
          </el-form-item>
        </div>
        <div class="flex justify-between">
          <el-form-item label="First Interest" prop="firstInterestDateTime" class="non-edit-field" :class="{'placeholder': !tenantData.firstInterestDateTime}">
            {{ tenantData.firstInterestDateTime | date }}
          </el-form-item>
          <el-form-item label="First Started Trial" prop="firstStartedTrialDateTime" class="non-edit-field" :class="{'placeholder': !tenantData.firstStartedTrialDateTime}">
            {{ tenantData.firstStartedTrialDateTime | date }}
          </el-form-item>
          <el-form-item label="First Converted" prop="firstConvertedToPaidDateTime" class="non-edit-field" :class="{'placeholder': !tenantData.firstConvertedToPaidDateTime}">
            {{ tenantData.firstConvertedToPaidDateTime | date }}
          </el-form-item>
          <el-form-item v-if="tenantData.accountPremiumStatus && !!tenantData.accountPremiumStatus.includes('None')" label="First Churned" prop="firstChurnedDateTime" class="non-edit-field" :class="{'placeholder': !tenantData.firstChurnedDateTime}">
            {{ tenantData.firstChurnedDateTime | date }}
          </el-form-item>
          <el-form-item label="Customer Type" prop="customerType" class="non-edit-field" :class="{'placeholder': !tenantData.customerType}">
            {{ tenantData.customerType | text }}
          </el-form-item>
          <el-form-item label="Customer Sub-Type" prop="customerSubType" class="non-edit-field" :class="{'placeholder': !tenantData.customerSubType}">
            {{ tenantData.customerSubType | text }}
          </el-form-item>
          <el-form-item label="Account Country" prop="accountCountry" class="non-edit-field" :class="{'placeholder': !tenantData.accountCountry}">
            {{ tenantData.accountCountry | text }}
          </el-form-item>
        </div>
        <div class="flex justify-between">
          <el-form-item label="Last Paid Line Item" prop="lastPaidPositiveInvoiceLineItemDateTime" class="non-edit-field" :class="{'placeholder': !tenantData.lastPaidPositiveInvoiceLineItemDateTime}">
            {{ tenantData.lastPaidPositiveInvoiceLineItemDateTime | date }}
          </el-form-item>
          <el-form-item label="Total Months Paid" prop="totalNumberOfMonthsPaidByTenant" class="non-edit-field" :class="{'placeholder': !tenantData.totalNumberOfMonthsPaidByTenant}">
            {{ tenantData.totalNumberOfMonthsPaidByTenant | text }}
          </el-form-item>
          <el-form-item label="Lifetime Payment Total" prop="lifetimePaymentTotal" class="non-edit-field" :class="{'placeholder': !tenantData.lifetimePaymentTotal}">
            {{ tenantData.lifetimePaymentTotal ? '$' + tenantData.lifetimePaymentTotal.toFixed(2).toString() : '—' | text }}
          </el-form-item>
          <el-form-item label="Average Monthly Invoice Total" prop="averageMonthlyInvoiceTotal" class="non-edit-field" :class="{'placeholder': !tenantData.averageMonthlyInvoiceTotal}">
            {{ tenantData.averageMonthlyInvoiceTotal ? '$' + tenantData.averageMonthlyInvoiceTotal.toFixed(2).toString() : '—' | text }}
          </el-form-item>
          <el-form-item label="Temporary Account" prop="isTemporaryAccount" class="non-edit-field" :class="{'placeholder': !tenantData.isTemporaryAccount}">
            <el-checkbox
              disabled
              v-if="tenantData.isTemporaryAccount"
              :checked="tenantData.isTemporaryAccount">
            </el-checkbox>
            <template v-else>—</template>
          </el-form-item>
          <el-form-item label="Testing Account" prop="isTestingAccount" class="non-edit-field" :class="{'placeholder': !tenantData.isTestingAccount}">
            <el-checkbox
              disabled
              v-if="tenantData.isTestingAccount"
              :checked="tenantData.isTestingAccount">
            </el-checkbox>
            <template v-else>—</template>
          </el-form-item>
        </div>
        <div class="flex justify-between">
          <el-form-item label="Discount %" prop="discountPercent" class="non-edit-field" :class="{'placeholder': !tenantData.discountPercent}">
            {{ tenantData.discountPercent | condensedPercent }}
          </el-form-item>
          <el-form-item label="Discount % Label" prop="discountPercentLabel" class="non-edit-field" :class="{'placeholder': !tenantData.discountPercentLabel}">
            {{ tenantData.discountPercentLabel | text }}
          </el-form-item>
          <el-form-item label="Discount $" prop="discountFixed" class="non-edit-field" :class="{'placeholder': !tenantData.discountFixed}">
            {{ tenantData.discountFixed | text }}
          </el-form-item>
          <el-form-item label="Discount $ Label" prop="discountFixedLabel" class="non-edit-field" :class="{'placeholder': !tenantData.discountFixedLabel}">
            {{ tenantData.discountFixedLabel | text }}
          </el-form-item>      
        </div>
        <div class="flex justify-between">
          <el-form-item label="GrowSurf Participant ID" prop="growSurfParticipantId" class="non-edit-field" :class="{'placeholder': !tenantData.growSurfParticipantId}">
            {{ tenantData.growSurfParticipantId | text }}
          </el-form-item>   
          <el-form-item label="GrowSurf Participant Email" prop="growSurfParticipantEmail" class="non-edit-field" :class="{'placeholder': !tenantData.growSurfParticipantEmail}">
            {{ tenantData.growSurfParticipantEmail | text }}
          </el-form-item>
          <el-form-item label="GrowSurf Participant Full Name" prop="growSurfParticipantFullName" class="non-edit-field" :class="{'placeholder': !tenantData.growSurfParticipantFullName}">
            {{ tenantData.growSurfParticipantFullName | text }}
          </el-form-item>
          <el-form-item label="GrowSurf Referred By Email" prop="growSurfReferredByEmailBackup" class="non-edit-field" :class="{'placeholder': !tenantData.growSurfReferredByEmailBackup}">
            {{ tenantData.growSurfReferredByEmailBackup | text }}
          </el-form-item>
          <el-form-item label="GrowSurf Referred By Full Name" prop="growSurfReferredByFullNameBackup" class="non-edit-field" :class="{'placeholder': !tenantData.growSurfReferredByFullNameBackup}">
            {{ tenantData.growSurfReferredByFullNameBackup | text }}
          </el-form-item>
          <el-form-item label="GrowSurf Participant Referral Complete" prop="growSurfParticipantReferralComplete" class="non-edit-field" :class="{'placeholder': !tenantData.growSurfParticipantReferralComplete}">
            <el-checkbox
              disabled
              v-if="tenantData.growSurfParticipantReferralComplete"
              :checked="tenantData.growSurfParticipantReferralComplete">
            </el-checkbox>
            <template v-else>—</template>
          </el-form-item>                
        </div>
        <div class="grid grid-cols-4 gap-4">
          <div>
            <el-form-item label="Cancelation Reason" prop="accountCanceledReason" class="non-edit-field" :class="{'placeholder': !tenantData.accountCanceledReason}">
              {{ tenantData.accountCanceledReason | text }}
            </el-form-item>   
          </div>
          <div>
            <el-form-item label="Features" prop="accountCanceledReason" class="non-edit-field" :class="{'placeholder': !this.features }">
              {{ this.features | formatArray }}
            </el-form-item>   
          </div>    
          <div class="col-span-2"> 
            <el-form-item v-if="showCanceledNotes" label="Cancelation Notes" prop="accountCanceledNotes" class="non-edit-field" :class="{'placeholder': !tenantData.accountCanceledNotes}">
              {{ tenantData.accountCanceledNotes | text }}
            </el-form-item>    
          </div>     
        </div>
        <div class="flex flex-wrap">
          <div class="mr-7">
            <el-form-item  v-if="isParentAccount" label="Allow Parent Data" class="non-edit-field" :class="{'placeholder': !accountTypeProps.allowParentDataValue}">
              <el-checkbox
                disabled
                v-if="accountTypeProps.allowParentDataValue"
                :checked="accountTypeProps.allowParentDataValue">
              </el-checkbox>
              <template v-else>—</template>
            </el-form-item>

            <el-form-item v-else-if="isRegularAccount" label="Parent Account" class="non-edit-field placeholder">
              {{ accountTypeProps.parentAccountValue }}
            </el-form-item>

            <el-form-item v-else-if="isChildAccount" label="Parent Account" class="non-edit-field">
              <router-link
                :to="`/system/tenants/${accountTypeProps.parentAccountId}`">
                  {{ accountTypeProps.parentAccountValue }}
                  <i title="View Tenant Parent Account" class="uil uil-external-link-alt" />
              </router-link>
            </el-form-item>
          </div>
          <div>
            <el-form-item v-if="!$store.getters.isZohoCrmDisabled" label="Zoho CRM Account ID" prop="zohoCrmAccountRecordId" class="non-edit-field overflow-ellipsis" :class="{'placeholder': !tenantData.zohoCrmAccountRecordId}">
              {{ tenantData.zohoCrmAccountRecordId | text }}
            </el-form-item>
          </div>
        </div>
      </el-form>
      <!-- TAB CONTROL -->
      <div class="flex justify-end">

        <el-radio-group v-model="tenantDetailsTab" size="small" class="flex-none border-gray-600" >
          <el-radio-button class="w-35" label="Users" ></el-radio-button>
          <el-radio-button class="w-35" label="Messages"></el-radio-button>
          <el-radio-button class="w-35" label="Invoices"></el-radio-button>
        </el-radio-group>
      </div>
      <UserList v-if="tenantData.users && tenantDetailsTab === 'Users'" class="mt-4 p-4 border rounded-md shadow"  :users="tenantData.users.items" :tenantId="tenantData.id" :group="tenantData.group" @loadData="loadData"/>
      
      <div v-if="tenantData.group && tenantDetailsTab === 'Messages'" class="mt-4 p-4 border rounded-md shadow">
        <h1>Message logs</h1>
        <Message :group="tenantData.group"/>
      </div>

      <ActiveStaff v-if="tenantData.invoices && tenantDetailsTab === 'Invoices'" class="mt-4 p-4 border rounded-md shadow" :tenantId="tenantData.id" :group="tenantData.group" :invoices="tenantData.invoices.items" />

    </div>

    <!-- CHANGE PLAN MODAL -->
    <ChangePlanModal :title="titleChangePlan" :tenant="tenantChangePlan" :show="showChangePlan" @close="showChangePlan = false" @resultChangePlan="resultChangePlan"/>

  </div>
</template>

<script>
//import ContextMenuSelect from "@/components/TableCard/ContextMenuSelect";
import ContextMenuHeaderIcon from '@/components/ContextMenuHeaderIcon';
import TenantForm from './components/TenantForm'
import DeactivateTenantModal from './components/DeactivateTenantModal'
import Message from '../../Settings/Messages/Messages'
import ActiveStaff from './components/ActiveStaff'
import { updateZohoAccountTenant } from '../../../components/Zoho/zoho';
// import UserList from '../Users/components/UserList'
import { getTenant, getTenantAccountData, tenantsByAccountType, updateTenantAccountType } from './queries'
import UserList from '@/views/Settings/Users/components/UserList'
import { updateTenant, createMessageServiceProvider } from '@/graphql/mutations'
import { getTenantUpdatedTimestamp } from '@/utilities/timestampQueries'
import DeleteDialog from '@/components/DeleteDialog'
import { updateUser } from '@/graphql/mutations'
import { telnyxAddNumber, telnyxRemoveNumber} from './telnyx'
import { Auth, API } from 'aws-amplify';
import { setGroup, changePlanToNone } from './tenant'
import ChangePlanModal from "./components/ChangePlanModal"
import { CUSTOMER_SUB_TYPE_XL } from '@/views/System/Tenants/constants'
import { getTenantData } from '@/utilities/getTenantData';

import {
  REGULAR_ACCOUNT,
  PARENT_ACCOUNT,
  CHILD_ACCOUNT
} from './constants';

  export default {
    name: 'TenantDetail',
    components: { TenantForm, DeactivateTenantModal, UserList, ActiveStaff, DeleteDialog, Message, ContextMenuHeaderIcon, ChangePlanModal },
    props: ['id'],
    data() {
      return {
        modalOpen: false,
        deactivateModalOpen: false,
        deactivateTenantLoading: false,
        deactivateTenantMessage: '',
        search: '',
        allUsers: [],
        tenantData:{},
        premiumStatusHistory: [],
        editTenantData:{},
        servicesMessage: [
          'None',
          'Telnyx'
        ],
        features:[],
        averageActiveStaffTotal: null,
        deletionType: '',
        deletionMessage: '',
        deleteAccountModalOpen: false,
        showChangePlan: false,
        titleChangePlan: '',
        tenantChangePlan: {},
        tenantDetailsTab: 'Users' //default Users Tab will be open
      }
    },
    mounted(){
      (async () => {
        await this.loadData();
        this.updateOrfanChildToRegularAccount();
      })();
    },
    computed:{
        showCanceledNotes(){            
            return this.tenantData.accountPremiumStatus?.includes('None') ||  this.tenantData.customerStatus === 'Churned'
        },
        isRegularAccount() { // By default the accounts are treated as REGULAR
          return (
            !this.tenantData.accountType ||
            this.tenantData.accountType === REGULAR_ACCOUNT);
        },
        isParentAccount() {
          return this.tenantData.accountType === PARENT_ACCOUNT;
        },
        isChildAccount() {
          return this.tenantData.accountType === CHILD_ACCOUNT;
        },
        accountTypeProps() {
          if (this.isRegularAccount) {
            return this.getRegularAccountProps();
          }
          if (this.isParentAccount) {
            return this.getParentAccountProps();
          }
          if (this.isChildAccount) {
            return this.getChildAccountProps();
          }

          return this.getEmptyAccountProps();
        },
    },
    methods: {

      /**
       * Close modal and clear out deletionConfirmation
       */
      closeModal(){
        this.deleteAccountModalOpen = false;
      },

      setClassStatus(status) {
       return {
        'text-yellow-600 bg-yellow-500': status && (status.toLowerCase() == 'trial' || status.toLowerCase() == 'trial (plan selected)'),
        'text-red-600 bg-red-500': status && status.toLowerCase() == 'lapsed trial' || status && status.toLowerCase() == 'churned',
        'text-orange-600 bg-orange-500': status && status.toLowerCase() == 'unknown',
        'text-green-600 bg-green-500': status && status.toLowerCase().includes('active'),
       }
      },
      /**
       * Return the the tenant list after deletion complete
       */
      finishDeletion(){
        this.$router.push({name: 'SystemTenantIndex'})
      },


      /**
       * Handles commands passed from the dropdown
       * @param {string} command - The name of the route you want to go to
       */
      async handleCommand(command){
        if(command == "deleteAccount"){
          this.openDeletionModal('Account', `Are you sure you want to delete this tenant's account and all associated data?`)
          return
        }else if(command == 'changePlanToNone'){
          let message = `Would you like to continue?`;
          if(!!this.tenantData.bundleDeactivationAllowedAt) {
            let deactivationDate = this.$moment(this.tenantData.bundleDeactivationAllowedAt).format('YYYY-MM-DD');
            message = `The tenant's bundle expires on ${deactivationDate}; would you like to continue?`
          } 
          this.titleChangePlan = message
          this.tenantChangePlan = { ...this.tenantData }            
          this.showChangePlan = true
        }else if(command == 'openEditTenantModal'){
          this.openEditTenantModal()
        }else if(command == 'login'){
          let tenantWithAllUsers = this.tenantData 
          tenantWithAllUsers.users = {items: this.allUsers }
          console.log('tenant with all usres', tenantWithAllUsers)
          await setGroup(tenantWithAllUsers, this.api)
        } else if (command === 'accountTypeMenuAction') {
          this.accountTypeProps.menuOption.action();
        }
      },

      async resultChangePlan(result){
        if(result) this.deactivateTenantMessage = result.message
        this.deactivateTenantLoading = false
        if(result.status=='Success'){
          this.loadData()
        }
      },

      /**
       * Load data using the getTenant custom GraphQL query
       */
      async loadData(){
        try{
          let tenant = await this.api(getTenant, { id: this.id })
          this.allUsers = [...tenant.data.getTenant.users.items];
          
          tenant.data.getTenant.users.items = tenant.data.getTenant.users.items.filter(user => !(user.email.startsWith('sysadmin-') && user.email.endsWith('@herasolutions.info')))
          console.log('tenants no sysadmin',tenant)
          let hasCreator = tenant.data.getTenant.users.items.find(user => user.isCreator)
          let hasOwner = tenant.data.getTenant.users.items.find(user => user.isOwner)
          if(tenant.data.getTenant.users.items.length > 0) {
            if (!hasCreator || !hasOwner) {
              let dates = tenant.data.getTenant.users.items.map((user) => {
                return new Date(user.createdAt)
              })
              let oldDate = new Date(Math.min(...dates))
              let firstUser = tenant.data.getTenant.users.items.find((user) => oldDate.getTime() === new Date(user.createdAt).getTime())
              firstUser.isCreator = hasCreator ? false : true
              firstUser.isOwner = hasOwner ? false : true
              await this.api(updateUser, {input: firstUser})
            }
          }

          let tenantGroup = tenant.data.getTenant.group

          for (const user of tenant.data.getTenant.users.items) {
            let newUserLastLogin = user.lastLogin
            if (!user.lastLogin) {
              const cognitoUser = await this.getCognitoUser(user.email)
              if (cognitoUser.UserStatus === 'FORCE_CHANGE_PASSWORD') {
                newUserLastLogin = 'NOT_YET_LOGGED'
              } else {
                newUserLastLogin = new Date('1970/01/01').toISOString()
              }
              await this.api(updateUser, {input: { id: user.id, group: tenantGroup, lastLogin: newUserLastLogin }})
              user.lastLogin = newUserLastLogin
            }
          }
          
          this.tenantData = tenant.data.getTenant
          if(this.tenantData.featureAccessVehiclePhotoLogsHeraAi) this.features.push('Hera AI')
          //if(this.tenantData.hasFeatureInventoryManagement) this.features.push('Inventory Management')
          this.premiumStatusHistory = [...this.tenantData.premiumStatusHistory.items]
            .sort((a,b)=>a.createdAt > b.createdAt ? -1 : 1)
          
          
          // this.tenantData.users.items
          var invoices = this.tenantData.invoices.items;
          var invoiceCount = invoices.length;
          var activeStaffTotal = 0;
          invoices.forEach(invoice =>{
            activeStaffTotal = activeStaffTotal + invoice.averageActiveDriverCount;
          });

          var averageActiveStaff = activeStaffTotal / invoiceCount;
          console.log(Number.isNaN(averageActiveStaff))
          if(Number.isNaN(averageActiveStaff) == true){
            this.averageActiveStaffTotal = ""
          }
          else{
            this.averageActiveStaffTotal = averageActiveStaff.toFixed(2);
          }
        }catch(e){
          console.error(e)
          this.displayUserError(e)
        }
      },

      /** Copy tenant data to editTenantData and open modal */
      openEditTenantModal(){
        this.modalOpen = true        
        let tenant = {...this.tenantData}
        delete tenant.users
        this.editTenantData = tenant       
      },

      /**
       * Opens deletion dialog and sets parameters up for deletion functions
       */
      openDeletionModal: function(type, message){
        this.deletionType = type;
        this.deletionMessage = message;
        this.deleteAccountModalOpen = true;
      },

      async updateTenantNameInCognitoUserAttributes(group, tenantName){
        let apiName = 'AdminQueries';
        let path = '/updateTenantName';
        let post = {
          body: {
            "group": group,
            "tenantName": tenantName 
          }, 
          headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          } 
        }
        safeFunction(API.post)(apiName, path, post);
      },

      /** Update the tenant in the DB */
      async updateTenant(){
        try {

          //validate form
          await this.$refs.tenantForm.$refs.form.validate();
          
          let tenantUpdatedAt = this.editTenantData.updatedAt

          if (this.tenantData.messageServiceProvider != this.editTenantData.messageServiceProvider){
            if (this.tenantData.messageServiceProvider == 'None' && this.editTenantData.messageServiceProvider == 'Telnyx') {
              const updatedTenant = await telnyxAddNumber(this.tenantData)
              tenantUpdatedAt = updatedTenant.data.updateTenant.updatedAt
            }

            if (this.tenantData.messageServiceProvider == 'Telnyx' && this.editTenantData.messageServiceProvider == 'None') {
              const updatedTenant = await telnyxRemoveNumber(this.tenantData)
              tenantUpdatedAt = updatedTenant.data.updateTenant.updatedAt
            }

            if (this.tenantData.messageServiceProvider == 'Bandwidth' && this.editTenantData.messageServiceProvider == 'Telnyx') {
              const updatedTenant = await telnyxAddNumber(this.tenantData)
              tenantUpdatedAt = updatedTenant.data.updateTenant.updatedAt
            }

            if (this.tenantData.messageServiceProvider == 'Bandwidth' && this.editTenantData.messageServiceProvider == 'None') {
              const updatedTenant = await telnyxRemoveNumber(this.tenantData)
              tenantUpdatedAt = updatedTenant.data.updateTenant.updatedAt
            }
          }

          //Get Data By GrowSurf
          const growSurfData = await this.getDataByApiGrowSurf(this.editTenantData?.growSurfParticipantId)
          const parseGrowSurfData = this.getParsingDataGrowSurf(growSurfData)

          let input = {
            ...parseGrowSurfData,
            id: this.editTenantData.id,
            companyName: this.editTenantData.companyName,
            shortCode: this.editTenantData.shortCode,
            accountCanceledReason: this.editTenantData.accountCanceledReason,
            accountCanceledNotes: this.editTenantData.accountCanceledNotes,
            growSurfParticipantId: this.editTenantData.growSurfParticipantId,
            trialExpDate: this.editTenantData.trialExpDate,
            firstInterestDateTime: this.editTenantData.firstInterestDateTime,
            group: this.editTenantData.group,
            nameLog: this.tenantData.nameLog,
            notes: this.editTenantData.notes,
            discountPercent: this.editTenantData.discountPercent ?? 0,
            discountFixed: this.editTenantData.discountFixed ?? 0,
            discountPercentLabel: this.editTenantData.discountPercentLabel,
            discountFixedLabel: this.editTenantData.discountFixedLabel,
            customerSubType: this.editTenantData.customerSubType,
            updatedAt: tenantUpdatedAt,
            timeZone: this.editTenantData.timeZone,
            // Account TYPE fields
            allowParentData: this.editTenantData.allowParentData,
            parentAccountId: this.editTenantData.parentAccountId,
            accountType: this.editTenantData.accountType,
            customerType: this.editTenantData.customerType,
            customerSubType: this.editTenantData.customerSubType,
            accountCountry: this.editTenantData.accountCountry,
            isTemporaryAccount: this.editTenantData.isTemporaryAccount,
            isTestingAccount: this.editTenantData.isTestingAccount,
            //ZOHO
            zohoCrmAccountRecordId: this.editTenantData.zohoCrmAccountRecordId,
          }

          if (this.editTenantData.parentAccountId) { // Setup account to be CHILD ACCOUNT
            input = {
              ...input,
              ...this.getChildAccountValues(),
            };
          } else if (this.isChildAccount) { // User wants to pass from CHILD to REGULAR account
            try {
              await this.confirmUpgradeFromChildToRegularAccount();
              
              input = {
                ...input,
                ...this.getRegularAccountValues(),
              };
            } catch { // On cancel
              return;
            }
          }

          if (this.tenantData.companyName != this.editTenantData.companyName){
            //name changed
            var timestamp = this.getTenantTimeCustomFormat();
            var oldLog = this.tenantData.nameLog ? this.tenantData.nameLog : ''
            input.nameLog = oldLog + 'Name changed from "' + this.tenantData.companyName + '" to "' + this.editTenantData.companyName + '" at ' + timestamp + '<br/>'
            //Update Tenant Name in Cognito user attributes
            await this.updateTenantNameInCognitoUserAttributes(this.editTenantData.group, this.editTenantData.companyName)
          }

          const validateCompanyForm =  this.validateForm()
          if(validateCompanyForm){
            // set customer status      
            input.customerStatus = this.calculateCustomerStatus(this.editTenantData.accountPremiumStatus, this.editTenantData.trialExpDate)
        
            let updatedTenant = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
            updatedTenant = updatedTenant.data.updateTenant
            if(updatedTenant.stripeCustomerId) await this.updateStripeCustomer(updatedTenant)
            this.modalOpen = false
            this.loadData()
            this.$message({
              type: 'success',
              message: 'Tenant Updated'
            });
            
            // update intercom, only if there is at least one user. 
            // if(updatedTenant.users.items.length > 0){                     
            //   await updateIntercom(updatedTenant, this.$store)
            // }
            // update zoho account tenant
            let _payload = {
                      id: updatedTenant.zohoCrmAccountRecordId,
                      Tenant_ID: updatedTenant.id,
                      Account_Name: updatedTenant.companyName,
                      Short_Code:updatedTenant.shortCode,
                      Uses_XL_Coaching:updatedTenant.customerSubType === CUSTOMER_SUB_TYPE_XL ? "Yes" : "No",
                      Origination_Number:updatedTenant.originationNumber,
                      Customer_Status:updatedTenant.customerStatus,
                      Cancellation_Reason:updatedTenant.accountCanceledReason,
                      Cancellation_Notes:updatedTenant.accountCanceledNotes,
                      Discount_Percent:updatedTenant.discountPercent ? updatedTenant.discountPercent.toString() : "",
                      Discount_Percent_Label:updatedTenant.discountPercentLabel,
                      Discount_Fixed:updatedTenant.discountFixed ? updatedTenant.discountFixed.toString() : "",
                      Discount_Fixed_Labeld:updatedTenant.discountFixedLabel,
                      Trial_Expiration_Date1: updatedTenant.trialExpDate.split('T')[0],
                      Timezone:updatedTenant.timeZone,
                      Group: updatedTenant.group
                    }
            if(updatedTenant.parentAccount && updatedTenant.parentAccount.zohoCrmAccountRecordId){
              _payload.Parent_Account = {id: updatedTenant.parentAccount.zohoCrmAccountRecordId}
            }
            try{
              if(!this.$store.getters.isZohoCrmDisabled){
                const resp = await updateZohoAccountTenant('update', _payload, this.displayUserError)
              }
              
            }catch(e){
              this.displayUserNotification({
                  title: "Error",
                  type: "error",
                  message: e
              })
            }
          }
        } 
        catch(e) {
          console.error(e)
          this.displayUserError(e)       
        } finally {
          this.loading = false
        }
      },

      validateForm(){
      if (!this.editTenantData.companyName) throw 'Please fill company name'
      return true
      },

      async updateStripeCustomer(tenant){
      try{
          let apiName = 'stripeSetup'
          let path = '/customer/' + tenant.stripeCustomerId
          let post = {
            body: {
              name: tenant.companyName,
              email: tenant.stripeBillingEmail,
              address:{
                city: tenant.adddressCity,
                line1: tenant.addressLine1,
                line2: tenant.addressLine2,
                postal_code: tenant.addressZip,
                state: tenant.addressState
              }
            }
          }
          var result = await safeFunction(API.put)(apiName, path, post)
          return result.customer
      }catch(e){
          console.error(e)
          this.displayUserError(e)
      }
    },
      /**
       * Set loading to true
       */
      startLoading(){
        this.loading = true;
      },


      /**
       * Set loading to false
       */
      stopLoading(){
        this.loading = false;
      },

      /** 
     * Gets a single cognito user
     * @param {string} username 
     * */
      async getCognitoUser(username){
        let apiName = 'AdminQueries';
        let path = '/getUser';
        let myInit = {
          queryStringParameters: {
            "username": username,
          }, 
          headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          } 
        }
        var result = await API.get(apiName, path, myInit);
        return result
      },

      /** ACCOUNT TYPE HELPERS */
      getRegularAccountProps() {
        return {
          pill: {
            label: 'Regular Account',
            class: 'text-green-600 bg-green-500',
          },
          parentAccountValue: '—',
          menuOption: {
            icon: 'sitemap',
            label: 'Upgrade to Parent Account',
            action: this.upgradeToParentAccount,
          }
        };
      },
      getParentAccountProps() {
        return {
          pill: {
            label: 'Parent Account',
            class: 'text-red-600 bg-red-500',
          },
          allowParentDataValue: this.tenantData.allowParentData,
          menuOption: {
            icon: 'sitemap',
            label: 'Downgrade to Regular Account',
            action: this.confirmAndDowngradeToRegularAccount,
          }
        };
      },
      getChildAccountProps() {
        return {
          pill: {
            label: 'Child Account',
            class: 'text-yellow-600 bg-yellow-500',
          },
          parentAccountId: this.tenantData.parentAccountId,
          parentAccountValue: this.tenantData.parentAccount?.companyName || '—',
          menuOption: false
        };
      },

      getEmptyAccountProps() {
        return {
          pill: { label: '', class: '', },
          parentAccountValue: '—',
          menuOption: false
        };
      },

      async updateTenantAccountFields(message, variables) {
        try {
          await this.api(updateTenantAccountType, variables);
          await this.loadData();

          if (message) {
            this.displayUserNotification({
              title: 'Success',
              type: "success",
              message,
            });
          }
        } catch(err) {
          this.displayUserError(err);
        }
      },

      async upgradeToParentAccount() {
        const input = {
          id: this.tenantData.id,
          group: this.tenantData.group,
          allowParentData: true,
          parentAccountId: null,
          accountType: PARENT_ACCOUNT,
        };

        const today = new Date();
        today.setHours(0,0,0,0);
        const exDate = new Date(this.tenantData.trialExpDate);

        if(exDate>=today){
          this.tenantData.customerStatus = 'Trial (Plan Selected)'
          input.customerStatus = 'Trial (Plan Selected)'
        }

        const msg = 'Successfully upgraded to Parent Account';
        await this.updateTenantAccountFields(msg, { input });
      },

      async downgradeToRegularAccount() {
        const input = {
          id: this.tenantData.id,
          group: this.tenantData.group,
          allowParentData: false,
          parentAccountId: null,
          accountType: REGULAR_ACCOUNT,
        };

        const today = new Date();
        today.setHours(0,0,0,0);
        const exDate = new Date(this.tenantData.trialExpDate);

        if(exDate>=today){
          this.tenantData.customerStatus = 'Trial (Plan Selected)'
          input.customerStatus = 'Trial (Plan Selected)'
        }

        const msg = 'Successfully downgraded to Regular Account';
        await this.updateTenantAccountFields(msg, { input });

        await this.updateOrfanChildrenToRegularAccount(this.tenantData.id);
      },

      async updateOrfanChildrenToRegularAccount(parentAccountId) {
        try {
          const accountType = CHILD_ACCOUNT;
          const filter = {
            parentAccountId: { eq: parentAccountId },
          };
          const {data:{tenantsByAccountType:{items:orfanChildren}}} = await this.api(tenantsByAccountType, {accountType, filter}, 'tenantsByAccountType');

          const input = {
            id: undefined,
            allowParentData: false,
            parentAccountId: null,
            accountType: REGULAR_ACCOUNT,
          };

          const inProgress = orfanChildren.map(async (orfan) => {
            const update = { ...input, id: orfan.id };
            await this.api(updateTenantAccountType, { input: update });
          });

          await Promise.all(inProgress);
        } catch (err) {
          this.displayUserError(err);
        }
      },
      
      confirmAndDowngradeToRegularAccount() {
        const msg = "Warning: Downgrading will cause this account to no longer show any of its child accounts' data, and any parent data will no longer appear on its child accounts. All of this account's child accounts will be converted to regular accounts. Are you sure you want to downgrade?";

        this.$confirm(msg, 'Warning', {
          confirmButtonText: 'Downgrade',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        .then(this.downgradeToRegularAccount)
        .catch(() => {});
      },

      async confirmUpgradeFromChildToRegularAccount() {
        const msg = "Warning: Removing this parent account will also remove any of its data from this tenant, and data from this tenant will no longer appear on the parent account. Are you sure you want to continue?";

        await this.$confirm(msg, 'Warning', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        });
      },

      getChildAccountValues() {
        return {
          allowParentData: false,
          parentAccountId: this.editTenantData.parentAccountId,
          accountType: CHILD_ACCOUNT,
        }
      },

      getRegularAccountValues() {
        return {
          allowParentData: false,
          parentAccountId: null,
          accountType: REGULAR_ACCOUNT,
        };
      },

      async updateTenantToRegularAccount() {
        const input = {
          id: this.tenantData.id,
          group: this.tenantData.group,
          allowParentData: false,
          parentAccountId: null,
          accountType: REGULAR_ACCOUNT,
        };

        try {
          let message = 'We noticed that this child account has left orfan, ' +
            'and need to be upgraded to REGULAR account, ' +
            'please wait until this process ends.';
          this.displayUserNotification({
            title: 'Success',
            type: "info",
            message,
          });

          await this.api(updateTenantAccountType, { input });
          await this.loadData();

          message = 'Successfully upgraded to Regular account';
          this.displayUserNotification({
            title: 'Success',
            type: "success",
            message,
          });
        } catch(err) {
          this.displayUserError(err);
        }
      },

      async updateOrfanChildToRegularAccount() {
        if (!this.isChildAccount) return;
        if (!this.tenantData.parentAccountId) {
          await this.updateTenantToRegularAccount();
          return;
        }

        const variables = { id: this.tenantData.parentAccountId };
        const {data:{getTenant:parentAccount}} = await this.api(getTenantAccountData, variables);
        if (!parentAccount) {
          await this.updateTenantToRegularAccount();
          return;
        }

        if (parentAccount.accountType === PARENT_ACCOUNT) return;

        await this.updateTenantToRegularAccount();
      },

      getParsingDataGrowSurf(data){
        if(!data) return {}
        const { firstName, lastName, email, referrer } = data
        const parseData = {
          growSurfReferredByFullNameBackup: (referrer) ? `${referrer.firstName} ${referrer.lastName}` : null,
          growSurfReferredByEmailBackup: (referrer) ? `${referrer.email}` : null,
          growSurfParticipantFullName: (firstName) ? `${firstName} ${lastName}` : null,
          growSurfParticipantEmail: email ?? null
        }
        return parseData
      },

      async getDataByApiGrowSurf(participantId){
        if(!participantId) return 
        try{
          const apiName = 'tenant'
          const path = '/growSurf/' + participantId
          const {dataGrowSurf} = await API.get(apiName, path)
          return dataGrowSurf
        }catch(e){
            console.error(e)
        }
      }
    },
  }
</script>


import {
  vehicleFragment,
  associateFragment,
  dailyRosterFragment,
  dailyLogFragment,
  messageReadStatusFragment
} from './fragments';


export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      group
      owner
      title
      createdAt
      description
      payload
      clickAction
      isReadS
      updatedAt
    }
  }
`;

export const createStaff = associateFragment + /* GraphQL */ `
  mutation CreateStaff(
    $input: CreateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    createStaff(input: $input, condition: $condition) {
      ...associateFragment
    }
  }
`;

export const updateStaff = associateFragment + /* GraphQL */ `
  mutation UpdateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
      ...associateFragment
    }
  }
`;

export const deleteStaff = associateFragment + /* GraphQL */ `
  mutation DeleteStaff(
    $input: DeleteStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    deleteStaff(input: $input, condition: $condition) {
      ...associateFragment
    }
  }
`;

export const createDailyRoster = dailyRosterFragment + /* GraphQL */ `
  mutation CreateDailyRoster(
    $input: CreateDailyRosterInput!
    $condition: ModelDailyRosterConditionInput
  ) {
    createDailyRoster(input: $input, condition: $condition) {
      ...dailyRosterFragment
    }
  }
`;

export const updateDailyRoster = dailyRosterFragment + /* GraphQL */ `
  mutation UpdateDailyRoster(
    $input: UpdateDailyRosterInput!
    $condition: ModelDailyRosterConditionInput
  ) {
    updateDailyRoster(input: $input, condition: $condition) {
      ...dailyRosterFragment
    }
  }
`;

export const createDailyLog = dailyLogFragment + /* GraphQL */ `
  mutation CreateDailyLog(
    $input: CreateDailyLogInput!
    $condition: ModelDailyLogConditionInput
  ) {
    createDailyLog(input: $input, condition: $condition) {
      ...dailyLogFragment
    }
  }
`;

export const updateDailyLog = dailyLogFragment + /* GraphQL */ `
  mutation UpdateDailyLog(
    $input: UpdateDailyLogInput!
    $condition: ModelDailyLogConditionInput
  ) {
    updateDailyLog(input: $input, condition: $condition) {
      ...dailyLogFragment
    }
  }
`;

export const deleteDailyLog = dailyLogFragment + /* GraphQL */ `
  mutation DeleteDailyLog(
    $input: DeleteDailyLogInput!
    $condition: ModelDailyLogConditionInput
  ) {
    deleteDailyLog(input: $input, condition: $condition) {
      ...dailyLogFragment
    }
  }
`;

export const updateVehicle = vehicleFragment + /* GraphQL */ `
  mutation UpdateVehicle(
    $input: UpdateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    updateVehicle(input: $input, condition: $condition) {
      ...vehicleFragment
    }
  }
`;
export const createMessageReadStatus = messageReadStatusFragment + /* GraphQL */ `
  mutation CreateMessageReadStatus(
    $input: CreateMessageReadStatusInput!
    $condition: ModelMessageReadStatusConditionInput
  ) {
    createMessageReadStatus(input: $input, condition: $condition) {
      ...messageReadStatusFragment
    }
  }
`;
export const updateMessageReadStatus = messageReadStatusFragment + /* GraphQL */ `
  mutation UpdateMessageReadStatus(
    $input: UpdateMessageReadStatusInput!
    $condition: ModelMessageReadStatusConditionInput
  ) {
    updateMessageReadStatus(input: $input, condition: $condition) {
      ...messageReadStatusFragment
    }
  }
`;
<template>
  <div :class="inTab ? ['table__card--tab', `${tablecardClass}`] : ['table__card', `${tablecardClass}`]" class="rounded-lg">
    <div id="filters">
      <slot name="table-filters"></slot>
    </div>
    
    <div id="searchbox">
      <slot name="table-searchbox"></slot>
    </div>

    <div id="table-container">
      <slot></slot>
    </div>

    <div v-if="showPagination" id="pagination" class="p-2">
      <CustomPagination @index-pagination="indexPagination" :records="quantityRecords"/>
    </div>
  </div>

</template>

<script>
import CustomPagination from "@/components/TableCard/CustomPagination"

export default {
  name: "TableCard",
  components: {
    CustomPagination
  },
  props: {
    inTab: {
      type: Boolean,
      required: false,
      default: false
    },
    tablecardClass: {
      type: String,
      required: false,
      default: ""
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: false
    },
    quantityRecords: {
      type: Number,
      required: false,
      default: 0,
    }
  },
  methods: {
    indexPagination(index){
      this.$emit("index-pagination-parent", index);
    }
  }

}
</script>

<style lang="scss" scoped>
.table__card {
  border: 1px solid rgba(226, 232, 240, 1);
}

.table__card--tab {
  border: 1px solid rgba(226, 232, 240, 1);
  border-top: 0;
}

</style>
import Vue from 'vue'
import { screens } from 'tailwindcss/defaultTheme'

const objScreens = Object.entries(screens).map(([breakpoint, width])=>{
    return {
        breakpoint: breakpoint.toLowerCase(),
        width: parseInt(width)
    }
}).sort((a, b) => a - b)

let mounted = false

const getClientWidth = function(){
    const body = document.body
    return body.clientWidth
}

const getScreen = function(){
    return objScreens.reduce((map, curr )=>{
        if(curr.width <= $screen?.width){
            map.returns = curr.breakpoint
        }
        map.prev = curr
        return map
    }, { returns: 'xs', prev: {breakpoint: 'xs', width: 0}}).returns

}

const updateValues = function($screen){
    $screen.width = getClientWidth()
    $screen.breakpoint = getScreen()
    $screen.xs = getScreen() === 'xs'
    $screen.sm = getScreen() === 'sm'
    $screen.md = getScreen() === 'md'
    $screen.lg = getScreen() === 'lg'
    $screen.xl = getScreen() === 'xl'
}

export const $screen = Vue.observable({ width: 0, breakpoint: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 })

const onResizeEvent = function(evt){
    updateValues($screen)
}

export const breakpointsPlugin = {
    // eslint-disable-next-line no-unused-vars
    install(vue, options) {
        vue.mixin({
            created(){
                if(!vue.prototype.$screen){
                    vue.prototype.$screen = $screen
                }
            },
            mounted() {
                if (!mounted){
                    updateValues($screen)
                    window.addEventListener('resize', onResizeEvent)
                    mounted = true
                }
            },

            beforeDestroy(){
                if(mounted){
                    window.removeEventListener('resize', onResizeEvent)
                    mounted = false
                }
            }
        })
    }
}

export default { breakpointsPlugin, $screen }
import store from '@/store/index.js'
import { getValueListByTenantId } from './queries/queries'
import { vehiclesByGroup } from '@/views/Vehicles/queries'
import { getValueList } from '@/api/queries'
import { createValueList, createValueListItem } from '@/graphql/mutations'


const seeds = {
    'accident-types': { isDeletable: true, values: ['Vehicle Crash', 'Injury'] },
    'amazon-profile': { isDeletable: false, values: ['Active', 'Inactive'] },
    'device-carrier': { isDeletable: true, values: ['AT&T', 'Boost', 'Cricket', 'Google Fi', 'H20', 'Metro by T-Mobile', 'Spectrum Mobile', 'Sprint', 'T-Mobile', 'US Cellular', 'Verizon', 'Xfinity Mobile'] },
    'device-status': { isDeletable: false, values: ['Active', 'Inactive', 'Inactive - Missing', 'Inactive - Being Repaired', 'Inactive - Damaged'] },
    'document-type': { isDeletable: false, values: ['Accident Record', 'Change in Relationship', 'Evidence of Quit/Fire', 'Injury Record', 'Misc', 'Offer Letter', 'Write Up/Infraction', 'ORCAS', 'Roadside Inspection', 'Others'] },
    'drug-test-results': { isDeletable: true, values: ['Pass', 'Fail'] },
    'genders': { isDeletable: true, values: ['Male', 'Female', 'Other'] },
    'hourly-status': { isDeletable: false, values: ['Full Time', 'Part Time', 'Seasonal'] },
    'physical-results': { isDeletable: true, values: ['Pass', 'Fail'] },
    'pronouns': { isDeletable: true, values: ['He/Him', 'She/Her', 'They/Them'] },
    'roster-status': { isDeletable: false, values: ['On Time', 'Late, With Call', 'Late, No Call', 'Called Out', 'No Show, No Call', 'VTO'] },
    'scorecard-tiers': { isDeletable: false, values: ['Fantastic','Great','Fair','Poor']},
    // #####
    // IF YOU CHANGE THE ROSTER STATUS "ON TIME" TO ANOTHER VALUE YOU WILL HAVE TO UPDATE ReplaceByForm.vue AND Routes.vue AS WELL.
    // ##### 
    'staff-status': { isDeletable: false, values: ['Active', 'Inactive - Misc', 'Inactive - Medical Leave', 'Onboarding', 'Inactive - Terminated', 'Inactive - Personal Time/Vacation','Inactive - Failed Onboarding'] },
    'uniform-type': { isDeletable: true, values: ['Mens Shirt', 'Womens Shirt', 'Mens Pants', 'Hat'] },
    'uniform-size': { isDeletable: true, values: ["Short", "Regular", "Long", "XS", "XS/S", "S", "S/M", "M", "M/L", "L", "XL", "XXL", "2XL/3X" , "XXXL", "XXXXL", "4XL/5XL" , "XXXXXL"] },
    'vehicle-status': { isDeletable: false, values: ['Active', 'Inactive', 'Inactive - Maintenance', 'Inactive - Grounded'] },
    'vehicle-company': { isDeletable: true, values: ['Alamo', 'Avis', 'Budget', 'Dollar', 'Element', 'Enterprise', 'Fluid Truck', 'Hertz', 'Lease Plan', 'Merchants Fleet', 'National', 'Thrifty', 'Zeeba'] },
    'vehicle-ownership': { isDeletable: false, values: ['Lease', 'Own', 'Rent'] },
   
    // 'infraction-description-violation': {isDeletable: false, values: [
    //    'Public urination, public defecation, or public indecency',
    //     'Physical violence or threat of violence',
    //     'Gross negligence or professional misconduct',
    //     'Unauthorized entry of a customer’s home, car, garage or business (full entry of property)',
    //     'Mis-use of customer information',
    //     'Theft',
    //     `Dumping or abandoning multiple customers' package in an outside unsecured location (side of road, dumpster, outside building visible from the street, etc.)`,
    //     `Not following trailer dock release (TDR) process`,
    //     `Road side inspection (RSI) - high severity issue`,
    //     `High Severity property damage resulting from Hit & Run or gross negligence (unsafe driving leading to major damage)`,
    //     `Vehicle Rollaway`
    // ]},
    // 'infraction-description-defects': {isDeletable: false, values:[
    //     `Offensive behavior or inappropriate conduct`,
    //     `Requesting for delivery fee/tips for package delivery`,
    //     `Unsecured vehicle`,
    //     `Tailgating other vehicles to enter into a delivery location`,
    //     `Attempted/partial entry of a customer’s home, car, place of business, or within the entryway (foyer, garage, etc.)`,
    //     `Delivering in and/or on a United State Postal Service (USPS) mail box`,
    //     `Leaving packages unattended in an unsecure location within the bounds of customer property`,
    //     `Not Following Delivery/customer Instructions`,
    //     `Using Incorrect Scan during Delivery/Delivery Attempt`,
    //     `Speeding or Unsafe Driving on the road/station`,
    //     `Not Wearing Seat Belt while driving`,
    //     `Public Health`,
    //     `Roadside inspection (RSI) - low severity issue (5 or lower DOT severity weight)`,
    //     `Leaving packages unattended in front of a cab/vehicle`,
    //     `Parking van unattended in a non-parking zone or area`,
    //     `Vehicle idling during load out or under the roof`,
    //     `Failure to record vehicle fault on DVIC`,
    //     `Throwing packages`,
    //     `Delivered to Incorrect Address`,
    //     `Driver Mishandled Delivery`,
    //     `Low Severity Damage: lawn, driveway, mailbox, parked vehicle, house, garage, or other non-essential item`,
    //     `Not walking/driving in marked lanes at station`,

    // ]},
    'vehicle-maintenance-services': {isDeletable: false, values:[
        'Air Filter Replacement',
        'Antifreeze/engine coolant flush (radiator flush)',
        'Battery Replacement',
        'Brake Replacement',
        'Camera',
        'Check Engine Light Service',
        'Door Jam/Hinge',
        'Door check strap',
        'Door lock',
        'Driver’s seat repair/replacement',
        'Engine cooling fan',
        'Floor replacement',
        'Fuel Cap Repair or Replacement',
        'Fuel door replacement',
        'Headlamp bulb change',
        'Ignition Switch Replacement',
        'Key fob replacement',
        'Key replacement',
        'Oil Change',
        'Rear Step repair/replacement',
        'Rearview Screen',
        'Seat Belt Sensor Replacement',
        'Seat frame/cushion',
        'Seatbelt Buckle',
        'Side Mirror repair/replacement',
        'Side Step repair/replacement',
        'Signal bulb change',
        'Sliding door repair',
        'Spark Plug Replacement',
        'Taillight bulb change',
        'Tire Repair/Patch',
        'Tire Replacement',
        'Washer fluid',
        'Wheel Alignment/balancing',
        'Windshield Wipers Replacement',
        'Windshield repair/replacement'
    ]},
    'damage-severity': {isDeletable: false, values:[
        'Minor',
        'Major'
    ]},
    'service-location': {isDeletable: false, values:[]},
    'maintenance-status': {isDeletable: false, values:[
        'In Progress',
        'Waiting for Pickup',
        'Complete'
    ]},
    'odometer-type': {isDeletable: false, values:[
        'Start of route',
        'End of route',
        'Other'
    ]},

    // 'infraction-descriptions-tier-1': {isDeletable: false, values:[
    //     'DA reputation – Behavioral – Public urination, public defecation, or public indecency while completing deliveries or while wearing an Amazon-branded uniform',
    //     'DA reputation - Behavioral - Theft of property while completing deliveries or while wearing an Amazon-branded uniform',
    //     'DA reputation - Behavioral - Violation of Amazon policy - Standards of professional conduct',
    //     'DA reputation - Behavioral – Violence, altercation, or threat',
    //     'DA reputation - Fraud - Mis-use of customer information',
    //     'DA reputation - Unsafe driving behavior - Property damage - Fleeing the scene of a crime',
    //     'DA reputation - Behavioral - Other gross negligence or misconduct',
    //     'DA reputation – Fraud – Dumping or abandoning customer packages with malicious intent (side of road, dumpster)',
    //     'DA reputation – Behavioral – Unauthorized entry of a customer’s home, car, or place of business, beyond the entryway (fully entered the home)',
    // ]},
    // 'infraction-descriptions-tier-2':{isDeletable: false, values: [
    //     'DA reputation - Behavioral - Insulting, unprofessional, or offensive behavior', 
    //     'DA reputation - Behavioral - Tailgating other vehicles to enter into a delivery location',
    //     'DA reputation – Behavioral – Requesting delivery fee/cash on delivery',
    //     'DA reputation - Behavioral - Attempted or an Actual unauthorized entry of a customer’s home, car, or place of business, within the entryway (foyer, garage, etc.)',
    //     'DA reputation - Behavioral - Dumping package(s) outside a locker or packages dumped in a single locker',
    //     'DA reputation - Behavioral – Dumping packages inside lobby/front entrance (not left outside a building)',
    //     'DA reputation - DA reputation – Behavioral – Leaving packages unattended inside mailroom/leasing office',
    //     'DA reputation - Package Mishandling - Delivering in and/or on a United State Postal Service (USPS) mail box',
    //     'DA reputation - Package Mishandling - Package(s) left on lawn',
    //     'DA reputation - Package Mishandling - Package(s) left in driveway',
    //     'DA reputation - Package Mishandling - Package(s) left in road or sidewalk',
    //     'DA reputation - Security - Leaving keys in an unlocked delivery van containing Amazon packages resulting/not resulting in theft/loss of AMZL packages and/or van.',
    //     'DA reputation – Unsafe driving behavior',
    //     'DA reputation - Unsafe driving behavior - Property damage',
    //     'DA Reputation - Behavioral - Property Damage',
    //     'DA reputation - Behavioral - Insulting, unprofessional, or offensive behavior towards a AMZL station employee or Driver Support',
    //     'DA reputation - Package Mishandling - Not following Customer Delivery Instructions (either posted on property or permanent notes in Amazon’s systems).',
    //     'DA Reputation – Behavioral – Using Incorrect Scan during Delivery/Delivery Attempt',
    //     'DA reputation - Behavioral - Scanned delivered to receiver/receptionist but left package(s) unattended',
    //     'DA reputation – Behavioral – Scanned Unable to Access (UTA) when there is no access barrier',
    //     'DA reputation - Behavioral - Scanned Business Closed (BC)',
    //     'DA reputation – Behavioral – Scanned no safe location (NSL) with no delivery barriers',
    //     'DA reputation – Safety – Public Health',
    //     'DA Safety – Station – Not Wearing Seat Belt While Driving',
    //     'DA Safety – Station – Speeding or Unsafe Driving at Station',
    //     'DA Safety – Station – Vehicle parked or left unattended in non-parking zone or area',
    //     'DA Safety – Station – Vehicle idling during load-out or under the roof',
    //     'DA Safety – Station – Unsecure packages in front/cab of vehicle'        
    // ]}

    // 'vehicle-provider': { isDeletable: true, values: ['Amazon'] },
    // 'vehicle-providers': { isDeletable: true, values: ['Self', 'Other'] },
}

/**
 * Download the value lists, there items and will create new valuelist records if needed.
 * @param {object} vm - This is the entire reference to this so we have access to api to make queries easier.
 * @param {string} tenantId - the tenant id to query
 */
async function loadValuelists(vm, tenantId){
    const results = await vm.api(getValueListByTenantId, {id:  tenantId })
    var valuelists = results.data.getTenant.valueLists.items
    await ensureAllValueListsExists(vm, tenantId, valuelists)
}

/**
 * Loop through all the keys and make sure there is a value list for each
 * @param {object} vm - This is the entire reference to this so we have access to api to make queries easier. 
 * @param {array} valueLists
 */
async function ensureAllValueListsExists(vm, tenantId, valuelists){
    var valueListKeys = Object.keys(seeds);
    valueListKeys.forEach(async(key)=>{
        var exists = valuelists.find((vl)=> vl.key == key)
        var valuelistId = null

        if(exists){
            // query for value list items
            let valueList = await vm.api(getValueList, {id: exists.id})
            valueList = valueList.data.getValueList
            let valueListItems = valueList.items.items
            let currentValues = valueListItems.map(item => {
                return item.value
            })

            // check that all defaults exists
            let notCreated = seeds[key].values.filter(el =>{
                el = el.replace(/hidden-/g, '')
                return currentValues.indexOf(el) < 0
            })
            if(notCreated.length){
                notCreated.forEach(async item => {
                    let hidden = false
                    if(item.includes('hidden-')){
                        hidden = true
                        item = item.replace(/hidden-/g, '')
                    }
                    // set default coaching slider values for issues and kudos
                    var driverReportSliderDefaultValue = 0

                    let input = {
                        value: item, 
                        hidden: hidden,
                        valueListItemValueListId: valueList.id, 
                        custom: seeds[key].isDeletable ? true : false,
                        group: store.state.userInfo.tenant.group,
                        driverReportSetting: driverReportSliderDefaultValue // set default driver report when creating
                    }
                    if(!(store.getters.isTrialExpired && store.getters.isTrialAccount)){
                        await vm.api(createValueListItem, {input})
                    }
                })
            }

            if(key == 'parking-space') {

                if(valueListItems.length === 0) {
                    addParkingSpace(valueList.id, vm);
                }

            }
        }
        
        //If it doesn't exist, create valuelist and valuelistitems
        if(!exists){
            let input = {
                key: key, 
                valueListTenantId: tenantId,
                group: store.state.userInfo.tenant.group
            }
            let newValuelist = await vm.api(createValueList, {input})
            var newValueListId = newValuelist.data.createValueList.id
            valuelistId = newValueListId

            //Seed dropdowns with some sensible defaults
            var options = seeds[key]
            
            for(const option of options.values){
                let hidden = false
                if(option.includes('hidden-')){
                    hidden = true
                    option = option.replace(/hidden-/g, '')
                }
                // set default coaching slider values for issues and kudos
                var driverReportSliderDefaultValue = 0
                let input = {
                    value: option, 
                    hidden: hidden,
                    valueListItemValueListId: newValueListId, 
                    custom: seeds[key].isDeletable ? true : false,
                    group: store.state.userInfo.tenant.group,
                    driverReportSetting: driverReportSliderDefaultValue // set default driver report when creating
                }
                if(!(store.getters.isTrialExpired && store.getters.isTrialAccount)){
                    await vm.api(createValueListItem, {input})
                }
            }
            
            if(key == 'parking-space') {
                addParkingSpace(valuelistId, vm);
            }

            await loadValueListItems(vm, valuelistId )
        }
        else{
            valuelistId = exists.id
            await loadValueListItems(vm, valuelistId )

        }
    })
}

async function addParkingSpace(valuelistId, vm) {

    const map1 = new Map();

    let input = {
        group: store.state.userInfo.tenant.group
    }
    let vehicles = await vm.gLoadListAll(vehiclesByGroup, input, 'vehiclesByGroup');

    vehicles.map(async (item) => {

      if (item.parkingSpace && !map1.has(`${item.parkingSpace}-${item.group}`)) {

        let input = {
            value: item.parkingSpace, 
            valueListItemValueListId: valuelistId, 
            custom: true,
            group: store.state.userInfo.tenant.group
        }
        if(!(store.getters.isTrialExpired && store.getters.isTrialAccount)){
            await vm.api(createValueListItem, {input});
        }
        map1.set(`${item.parkingSpace}-${item.group}`, true)

      }
    })
  }

/**
 * Loads the valuelist items and puts them in the store
 * @param {object} vm 
 * @param {string} valuelistId 
 */
async function loadValueListItems(vm, valuelistId){
    let result = await vm.api(getValueList, { id: valuelistId })
    let key = result.data.getValueList.key
    let valueListItems = result.data.getValueList.items.items.sort(sortByValueKey)
    //let activeValueListsItems = valueListItems.filter((item) => !item.deleted )
    let payload = {key: key, id: valuelistId, items: valueListItems}
    if (key === 'scorecard-tiers') {
        payload.items = priorityBasedSorting(seeds['scorecard-tiers'].values,result.data.getValueList.items.items)
    }
    store.commit('setValuelists', payload)
}
function priorityBasedSorting(order, valueListItems){
    return valueListItems.sort(
        (x, y) => order.indexOf(x.value) - order.indexOf(y.value)
    );
}

/**
 * Compares a.value vs b.value for sort function
 * @param {object} a 
 * @param {object} b 
 */
export function sortByValueKey(a, b) {
    // Use toUpperCase() to ignore character casing
    const valueA = a.value.toUpperCase();
    const valueB = b.value.toUpperCase();
    
    let comparison = 0;
    if (valueA > valueB) {
        comparison = 1;
    } else if (valueA < valueB) {
        comparison = -1;
    }
    return comparison;
}

export default loadValuelists;
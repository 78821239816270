<template>
    <div>
        <el-breadcrumb separator-class="uil uil-angle-right-b m-breadcrum">
            <el-breadcrumb-item :to="{ name: 'Dashboard' }"><i class="uil uil-home"></i></el-breadcrumb-item>
            <el-breadcrumb-item to="/settings">Company Settings</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'SettingsRosterChecklistsIndex' }">Roster Checklists</el-breadcrumb-item>
            <el-breadcrumb-item v-if="breadcrumbChild">
                {{breadcrumbChild}}
                <el-tooltip  class="item" popper-class="w-64" effect="dark" content="This import is used to add daily Mentor and Netradyne data to Hera. During each import, either or both files may be selected. If both files are selected, the dates must match on each file." placement="bottom">
                    <i class="uil uil-question-circle"/>
                </el-tooltip>
            </el-breadcrumb-item>
        </el-breadcrumb>

        <router-view></router-view>
    </div>
</template>
<script>
import EventBus from '@/eventBus'
export default{
    components:{
    },
    data(){
        return {
            breadcrumbChild: null
        }
    },
    created(){
        EventBus.$on('change-roster-checklist-breadcrumb-child', (breadcrumbChild) => this.breadcrumbChild = breadcrumbChild)
    },
    beforeDestroy(){
        EventBus.$off('change-roster-checklist-breadcrumb-child')
    },
}
</script>
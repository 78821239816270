import {mixinMethods} from '@/main';
import {getOptionsCustomLists} from './queries';

/**
 * Verifies if a OptionCustomList has linked records
 * @param {String} type - Type of the OptionCustomList
 * @param {Object} option - OptionCustomList that the user wants to delete
 * @returns {Boolean} 'true' if the OptionCustomList is linked to other records or the fetch fails, 'false' otherwise
 */
export async function isOptionCustomListLinkedToOtherRecords(type, option) {
  try {
    //"option.tempAdded" is used for newly created options
    if(option.tempAdded) return false;
    const input = { id: option.id };
    const { data } = await mixinMethods.api(getOptionsCustomLists, input);
    if(!data.getOptionsCustomLists) return false;
    const optionCustomList = data.getOptionsCustomLists;
    return ( // Check for relations with other records
      !!optionCustomList.accidents?.items.length ||
      !!optionCustomList.vehicles?.items.length ||
      !!optionCustomList.parkingSpace?.items.length ||
      !!optionCustomList.associates?.items.length ||
      !!optionCustomList.routeParkingSpace?.items.length ||
      !!optionCustomList.replaceByRouteParkingSpace?.items.length ||
      !!optionCustomList.counselings?.items.length ||
      !!optionCustomList.issues?.items.length ||
      !!optionCustomList.kudos?.items.length ||
      !!optionCustomList.companies?.items.length ||
      !!optionCustomList.routes?.items.length ||
      !!optionCustomList.routeHelperStatus?.items.length ||
      !!optionCustomList.replaceByRoutes?.items.length
    );    
  }
  catch (error) {
    mixinMethods.printUserError(error);
    return true; // To avoid deletion on error
  }
}

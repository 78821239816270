<template>
  <div>
    
    <el-button class="settings-toggle relative z-10 flex mb-3" @click="navActive=!navActive" size="mini">
      <ContextMenuHeaderIcon type="info" plain class="inline-block context-menu-header-hover text-xs"/>
      <span class="inline-block self-center">SETTINGS</span>
    </el-button>
    
    <div class="settings-wrap block md:flex md:space-x-6 relative">
      
    <div class="settings-menu-wrap z-10 self-baseline" :class="navActive ? 'active':''">
      <div class="absolute right-0 top-1 z-10 text-gray-500 md:hidden" @click="navActive=!navActive">
        <i class="uil uil-multiply text-2xl"></i>
      </div>
      <h5 class="text-xs p-3 md:hidden">COMPANY SETTINGS MENU</h5>
      <el-menu
        data-cy="setting-ops"
        active-text-color="#000000"
        class="h-full rounded-tl-lg rounded-bl-lg settings-menu w-48"
        :default-active="activePath"
        :router="true"
        >
        <el-menu-item index="/settings/account-details" v-if="permissions['permissionFullAccess']">
          <i class="uil uil-diary-alt text-xl"></i>
          <span>Account Details</span>
        </el-menu-item>
        <el-menu-item index="/settings/invoices" v-if="permissions['permissionFullAccess']">
          <i class="uil uil-file-alt text-xl"></i>
          <span>Invoices</span>
        </el-menu-item>
        <el-menu-item index="/settings/company" v-if="permissions['permissionFullAccess']">
          <i class="uil uil-sliders-v-alt text-xl"></i>
          <span>Company Details</span>
        </el-menu-item>
        <el-menu-item index="/settings/users" v-if="permissions['permissionFullAccess']" data-cy="user-tab">
          <i class="uil uil-users-alt text-xl"></i>
          <span>Users</span>
        </el-menu-item>
        <el-menu-item index="/settings/hera-ai" v-if="permissions['permissionFullAccess']">
          <i class="uil uil-atom text-xl"></i>
          <span>Hera AI</span>
        </el-menu-item>
        <el-menu-item index="/settings/driver-feedback" v-if="permissions['permissionFullAccess']">
          <i class="uil uil-comment-alt-dots text-xl"></i>
          <span>Coaching</span>
        </el-menu-item>
        <el-menu-item index="/settings/counselings" v-if="permissions['permissionCounselings'] || isAdmin">
          <i class="uil uil-file-edit-alt text-xl"></i>
          <span>Counselings</span>
        </el-menu-item>
        <el-menu-item index="/settings/messenger" v-if="permissions['permissionMessenger'] || permissions['permissionMessageTemplate']" data-cy="messenger-tab">
          <i class="uil uil-comment-alt-dots text-xl"></i>
          <span>Messenger</span>
        </el-menu-item>
        <el-menu-item index="/settings/drop-downs" v-if="permissions['permissionCustomLists']">
          <i class="uil uil-angle-down text-xl"></i>
          <span>Drop-Down Lists</span>
        </el-menu-item>
        <el-menu-item index="/settings/custom-lists" v-if="permissions['permissionCustomLists']" data-cy="custom-lists-tab">
          <i class="uil uil-list-ul text-xl"></i>
          <span>Custom Lists</span>
        </el-menu-item>
        <el-menu-item index="/settings/vehicle-photo-logs" v-if="permissions['permissionVehiclePhotoLogs'] || permissions['permissionFullAccess']" data-cy="vehicle-photo-logs">
          <i class="uil uil-image text-xl"></i>
          <span>Vehicle Photo Logs</span>
        </el-menu-item>
        <el-menu-item index="/settings/labels" v-if="(permissions['permissionManageLabels'] && (hasStaffManagement || hasVehicleManagement))">
          <i class="uil uil-label text-xl"></i>
          <span>Labels</span>
        </el-menu-item>
        <el-menu-item index="/settings/roster-checklists" v-if="permissions['permissionCompanySettingsRosterChecklistTemplates']">
          <i class="uil uil-clipboard-notes text-xl"></i>
          <span>Roster Checklist Templates</span>
        </el-menu-item>
      </el-menu>
    </div>

      <div class="flex-1 grid grid-cols-1">
        <transition name="fade" mode="out-in">   
            <router-view name='settings' :key="$route.path"/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import { getUserPermissions } from '@/middlewares/permissions'
import ContextMenuHeaderIcon from '@/components/ContextMenuHeaderIcon';

export default {
  components:{ ContextMenuHeaderIcon },
  data() {
    return {
      permissions: {},
      navActive: false
    }
  },
  computed:{
    ...mapGetters([
      'hasStaffManagement',
      'hasVehicleManagement',
      'isAdmin'
    ]),
    activePath(){
      var pathArray = this.$route.path.split("/")
      var basePath = `/${pathArray[1]}/${pathArray[2]}`
      return basePath
    },
  },

  beforeMount() {
    getUserPermissions(this.$store.state.userInfo.id, this.$store.state.userInfo.tenant.group)
    .then(userPermissions => {
      this.permissions = userPermissions
    })
  },
}

</script>

<style lang="scss" scoped>
  .router-link-active{
    @apply text-brand;
  }
  .el-menu-item.is-active{
    @apply bg-gray-200;
  }
</style>

<style>
.settings-menu .el-menu-item{
    @apply p-3 mx-2 rounded-md h-auto my-2 leading-4 align-middle !important;
} 
.settings-menu .el-menu-item.el-menu-item.is-active{
    @apply bg-gray-200 !important;
} 
.settings-menu .el-menu-item:hover, .el-menu-item:focus {
	@apply bg-gray-200 text-black !important;
}
.settings-menu .el-menu-item:hover i{
	@apply  text-black !important;
}

.settings-toggle{
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .settings-wrap h1{
    @apply relative;
  }
  .settings-toggle{
    display: block !important;
    color: #aaa;
    margin-bottom: 1em !important;
  }
  .settings-toggle > span{ 
    display: flex;
  }
  .settings-menu-wrap{
    position: absolute;
    transition: transform .2s ease, opacity .2s ease;
    top: -3.5em;
    height: auto;
    background: #fff;
    width: auto;
    transform: translate(-15rem, 0);
    opacity: 0;
    padding: 2em 2em 2em 4.5em;
    box-shadow: 1px 3px 10px rgb(0, 0, 0, .2);
  }
  .settings-menu-wrap.active{
    transform: translate(-4.5rem, 0);
    opacity: 1;
  }
  .settings-menu{
    border:none !important
  }
}
</style>

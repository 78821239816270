<template>
    <div v-if="$store.getters.isLoggedIn">
        <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable=no"/>
        <!-- Main menu -->
        <div class="top-0 h-auto">
            <div class="fixed z-40 md:z-50">
                <ProductFruitsComponent/>
                <!-- Banner -->
                <div class="w-full z-50 top-0">
                    <!-- Banner Show Trial -->
                    <div v-if="showTrial || $store.getters.isPlanNone"> 
                        <div v-if="((trialDaysRemaining < 0 && showTrial) || $store.getters.isPlanNone) && !$store.getters.hasLogguedSupportAdministrator" class="bg-red-600 text-white px-2 py-1" role="alert" >
                            <div class="flex">
                                <div class="flex-1 text-center" name="hera-banner">
                                    <p class="font-bold">Trial expired.</p>
                                    <!-- <p class="text-sm">Please <el-button type="text" size="mini" class="danger-text-button" @click="goToAccountDetails">select your plan</el-button> to regain access to Hera</p> -->
                                    <p class="text-sm">Please <el-button type="text" size="mini" class="danger-text-button" @click="goToAccountDetails">select "Hera Subscription"</el-button> to regain access to Hera</p>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="trialDaysRemaining < 8 && showTrial && !$store.getters.isTrialExpired" class="bg-yellow-500 text-yellow-900 px-2 py-1" role="alert" >
                            <div class="flex">
                                <div class="flex-1 text-center" name="hera-banner">
                                    <p class="font-bold">Your free trial will expire on {{$store.state.userInfo.tenant.trialExpDate | humanDate}}.</p>
                                    <!-- <p class="text-sm">Please <el-button type="text" size="mini" class="warning-text-button" @click="goToAccountDetails">select your plan</el-button> to maintain access to Hera.</p> -->
                                    <p class="text-sm">Please <el-button type="text" size="mini" class="warning-text-button" @click="goToAccountDetails">select "Hera Subscription"</el-button> to maintain access to Hera.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Banner Invoice Payment -->
                    <div v-if="showUnpaid">
                        <div v-if="outstandingDaysRemaining > 0"  class="bg-red-600 text-white px-2 py-1" role="alert" >
                            <div class="flex">
                                <div class="flex-1 text-center" name="hera-banner">
                                    <p class="font-bold">Your invoice payment is overdue.</p>
                                    <p class="text-sm">Please update your active payment method through your <el-button type="text" size="mini" class="danger-text-button" @click="goToAccountDetails">account settings</el-button>. To maintain access to Hera, please pay your outstanding balance by {{$store.state.userInfo.tenant.payOutstandingByDate|date}}.</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="outstandingDaysRemaining < 0 && !$store.getters.hasLogguedSupportAdministrator"  class="bg-red-600 text-white px-2 py-1" role="alert" >
                            <div class="flex">
                                <div class="flex-1 text-center" name="hera-banner">
                                    <p class="font-bold">Your invoice payment is overdue and you have lost access to Hera.</p>
                                    <p class="text-sm">To regain acces to Hera, please update your active payment method through your <el-button type="text" size="mini" class="danger-text-button" @click="goToAccountDetails">account settings</el-button>. You may still download and view any data stored in Hera.</p>
                                </div>
                            </div>               
                        </div>
                    </div>
                    <!-- Banner Clock Sync -->
                    <ClockSyncMessage v-if="!isTimeSynchronized" :isBanner="true"></ClockSyncMessage>
                    <!-- Banner support administrator -->
                    <div v-if="hasLogguedSupportAdministrator">  
                        <div class="bg-red-600 text-white px-2 py-1" role="alert" >
                            <div class="flex">
                                <div class="flex-1 text-center" name="hera-banner">
                                    <p class="font-bold">You are currently logged into this DSP as Support Administrator.
                                    <br>
                                    <button class="font-bold underline underline-offset-1" @click="handleCommand('Logout');">Logout</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Banner administrator -->
                    <div v-if="isAdmin && !hasTimezone"> 
                        <div class="bg-red-600 text-white px-2 py-1" role="alert" >
                            <div class="flex">
                                <div class="flex-1 text-center" name="hera-banner">
                                    <p class="font-bold">Hera doesn't yet know your DSP's timezone! We've defaulted to Pacific Time. Please head to company settings to choose a timezone now. 
                                        <router-link to="/settings/company" tag="button" class="font-bold underline">Click here to set it now!</router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Banner no admin -->
                    <div v-if="!isAdmin && !hasTimezone">  
                        <div class="bg-red-600 text-white px-2 py-1" role="alert" >
                            <div class="flex">
                                <div class="flex-1 text-center" name="hera-banner">
                                    <p class="font-bold">Hera doesn't yet know your DSP's timezone! We've defaulted to Pacific Time. Please speak to your DSP administrator about setting your timezone.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Header-->
                <div data-cy="top-header" id="top" class="block justify-between border-b items-center w-full bg-white z-50 pt-4 p-3 md:p-2">
                    <div class="flex justify-between w-full">
                        <div class="flex items-center company-wrap">
                            <img class="logo border-r pr-4 h-8 mt-1" src="@/assets/images/logo.png"/>
                            <h3 class="pl-4 relative mt-2 hidden md:flex" v-if="userInfo">{{ userInfo.tenant.companyName }}</h3>
                        </div>
                        <div v-if="hasDAManagement || hasVehicleManagementPermission" class="flex ml-auto mr-5 w-80">
                            <Search class="w-full search" :user="$store.state.userInfo" :isMobile="isMobile"/> 
                        </div>
                        <div class="space-x-4 items-center hidden md:flex">
                            
                            <el-dropdown data-cy="create-dropdown" @command=openImportModal>
                                <span class="el-dropdown-link" v-if="userInfo">
                                    Create<i class="uil uil-angle-down"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-option-group label="">
                                        <el-dropdown-item v-if="hasCounselingManagement" :command="{type: 'nav', title: 'addCounseling'}">Counseling</el-dropdown-item>
                                        <el-dropdown-item :command="{type: 'nav', title: 'addDriverIssue'}">{{$t("label.associate")}} Issue</el-dropdown-item>
                                        <el-dropdown-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && hasDAManagement)" :command="{type: 'nav', title: 'addDriverIssueInfractionType'}"><i class="uil uil-corner-down-right"></i>Violation/Defect</el-dropdown-item>
                                        <el-dropdown-item :command="{type: 'nav', title: 'addDriverKudo'}">{{$t("label.associate")}} Kudo</el-dropdown-item>
                                        <el-dropdown-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionAccidents)" :command="{type: 'nav', title: 'addIncident', param: 'incident'}">Incident Record</el-dropdown-item>
                                        <el-dropdown-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionAccidents)" :command="{type: 'nav', title: 'addIncident', param: 'accident'}"><i class="uil uil-corner-down-right"></i>Accident</el-dropdown-item>
                                        <el-dropdown-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionInjuries)" :command="{type: 'nav', title: 'addInjury'}">Injury Record</el-dropdown-item>
                                        <el-dropdown-item :command="{type: 'nav', title: 'Task'}">Task</el-dropdown-item>
                                        

                                    </el-option-group>
                                    
                                </el-dropdown-menu>
                            </el-dropdown> 

                            <el-dropdown data-cy="import-dropdown" @command=openImportModal>
                                <span class="el-dropdown-link" v-if="userInfo">
                                    Import<i class="uil uil-angle-down"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-option-group label="">
                                        <el-dropdown-item :command="{type: 'nav', title: 'DailyAmazonImport'}">Daily Roster From Amazon Roster File</el-dropdown-item>
                                        <el-dropdown-item :command="{type: 'nav', title: 'CustomCSVImport'}">Daily Roster From Custom Spreadsheet</el-dropdown-item>
                                        <el-dropdown-item :command="{type: 'nav', title: 'DailyDataImport'}">Daily Performance Data</el-dropdown-item>
                                        <el-dropdown-item v-if="!WEEKLY_IMPORT" :command="{type: 'performanceData'}" data-cy="import-weekly-performance-data">Weekly Performance Data</el-dropdown-item>
                                        <el-dropdown-item v-else :command="{type: 'nav', title: 'WeeklyDataImport'}" data-cy="import-weekly-performance-data">Weekly Performance Data</el-dropdown-item>
                                    </el-option-group>
                                </el-dropdown-menu>
                            </el-dropdown> 

                            <!-- <el-dropdown @command=handleCommand>
                                <span class="el-dropdown-link" v-if="userInfo">
                                    Reports<i class="uil uil-angle-down"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="ReportDashboard">Driver Licenses</el-dropdown-item>  
                                    <el-dropdown-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionAccidents)" command="AccidentIndex">Accidents</el-dropdown-item>
                                    <el-dropdown-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionInjuries)" command="InjuryIndex">Injuries</el-dropdown-item>
                                    <el-dropdown-item command="netradyneAlert">Netradyne Alerts</el-dropdown-item>
                                    <el-dropdown-item command="StaffOptedOut">DAs Opted Out</el-dropdown-item>
                                    <el-dropdown-item command="DaPerformanceReport">{{$t("label.associate")}} Performance</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>  -->

                            <!-- <MessengerIndicator data-cy="messenger-indicator-btn" v-if="hasMessengerManagement" />   -->
                            <Notifications data-cy="notifications-dropdown" /> 


                            <el-dropdown data-cy="user-info-dropdown" @command="handleCommand" class="border-l pl-4">
                                <span class="el-dropdown-link items-center flex" v-if="userInfo">
                                    <i class="uil uil-user text-2xl"></i>
                                    <span v-if="userInfo" class=" font-medium"> {{ userInfo.firstName }}</span>
                                    <i class="uil uil-angle-down"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" >
                                    <div class="user-title font-semibold">{{userInfo.firstName}} {{userInfo.lastName}}</div>
                                    <div class="user-title">{{userInfo.email}}</div>
                                    <div class="user-title"><company-time></company-time></div>
                                    <el-dropdown-item divided v-if="!$store.getters.isSystemAdmin || hasLogguedSupportAdministrator"></el-dropdown-item>
                                    <el-dropdown-item divided v-if="$store.getters.isSystemAdmin && !hasLogguedSupportAdministrator" command="SystemTenantIndex">System Admin</el-dropdown-item>
                                    <el-dropdown-item :command="route" data-cy="company-settings-option">Company Settings</el-dropdown-item>
                                    <el-dropdown-item command="Devices">Manage Devices</el-dropdown-item>
                                    <el-dropdown-item command="HelpCenter" target="_blank" divided>Help Center</el-dropdown-item>
                                    <el-dropdown-item command="referLink" target="_blank" divided>Refer Hera & Earn $1,000</el-dropdown-item>
                                    <el-dropdown-item command="ChangePassword" divided>Change Password</el-dropdown-item>
                                    <el-dropdown-item command="Logout" data-cy="logout-option">Logout</el-dropdown-item>  
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>   
                        <!-- Mobile Menu -->
                        <div class="mobile space-x-4 items-center flex md:hidden">
                            <!-- <MessengerIndicator @close-mobile-menu="hamburgerActive = false" v-if="hasMessengerManagement"/>    -->
                            <span  @click="triggerMobileMenu" v-show="!hamburgerActive"><i class="icon-button uil uil-bars text-2xl cursor-pointer hover:text-brand"></i></span>
                            
                        </div>                
                    </div>
                </div>

                <!--Main Nav-->
                <div class="w-full main-nav z-50 bg-brand">
                    <div data-cy="main-nav" id="nav-container" class="container md:mx-auto flex justify-between items-center py-0 ">
                        <NavButton title="Performance &<br>Coaching" icon="uil-chart-line" :to="{name: 'Dashboard'}"/>
                        <NavButton title="Rostering &<br>Checklists" icon="uil-calender" :to="{name: 'DailyRosterIndex'}" data-cy="daily-roster-nav"/>
                        <NavButton :title="$t('label.associate') + '<br>Management'" icon="uil-user" :to="{name: 'StaffIndex'}" data-cy="da-management-nav"/>
                        <!-- <NavButton title="Coaching" icon="uil-trophy" :to="{name: 'CoachingIndex'}"/>                     -->
                        <NavButton data-cy="vehicle-management-nav" title="Vehicle<br>Management" icon="uil-truck" :to="{name: 'VehicleIndex'}"/>
                        <NavButton title="Tasks &<br>Reports" icon="uil-file-alt" :to="{name: 'ReportsIndex'}"/>
                        <MessengerIndicatorModule class="main-nav-messenger text-white" data-cy="messenger-indicator-btn" :to="{name: 'Messenger'}">
                            Hera <br/>Messenger
                        </MessengerIndicatorModule>    
                    </div>
                </div>
            </div>
        </div>
        
        <NavMobile 
            @click-mobile-nav="mobileHandleCommand"
            @click-mobile-nav-import="mobileOpenImportModal"
            :user="$store.state.userInfo"
            :USER_PERMISSIONS="USER_PERMISSIONS"
            :WEEKLY_IMPORT="WEEKLY_IMPORT"
            :hamburgerActive="hamburgerActive"
            :hasDAManagement="hasDAManagement"
            />
        <!--Main Content-->
        <div class="main-content w-full h-full bg-white md:bg-gray-100 pb-12" :style="mainContentStyle">
            <div :class="[$route.path.includes('/m/') ? 'messenger': containerClass,'relative mx-auto']">
                <div class="router-wrap bg-white md:py-3 shadow-none rounded-none md:shadow md:rounded-lg md:p-6 relative">
                    <div class="bg-white hover:bg-gray-200 w-8 custom-shadow rounded-r-lg absolute -right-8"
                    :class="[ {'mt-3' : $route.path.includes('/messenger'), 'hidden': isMobile}]"> 
                        <!--Icons should only appear for screens larger than 1500px (show-expand-button)-->
                        <el-tooltip
                            effect="dark"
                            placement="right"
                            :content="isExpanded ? 'Click to shrink this page' : 'Click to expand this page'">
                            <button 
                                v-show="showExpandCollapseButton"
                                class="block  text-brand custom-font-size" 
                                :class="[isExpanded ? ' uil-arrows-merge' : 'uil-arrows-shrink-h']" 
                                @click="toggleExpandCollapseTable"/>
                        </el-tooltip>
                    </div> 
                    <transition name="fade"  mode="out-in">                           
                        <router-view v-if="$route.meta.disableRouteKey" @openImportModal="openImportModal"/>                           
                        <router-view v-else :key="$route.path" @openImportModal="openImportModal"/>
                    </transition>
                </div>
            </div>            
        </div> 

        <JobStatusCard v-if="!WEEKLY_IMPORT"/>

        <!--WARNING DIALOG-->
        <el-dialog
            title="Premium Required"
            :show-close="false"
            :center="true"
            :visible.sync="showUpgradeDialog"
            :close-on-click-modal="false"
            width="300px">
            <PremiumWarning
                :action="warningMessage"
                :premiumFeature="warningType"
                :text="'to purchase'"
                :modal="true"
                @go-to-account-details="goToAccountDetails"
                @close-dialog="showUpgradeDialog = false">
            </PremiumWarning>
        </el-dialog>


        <!--NO DA WARNING DIALOG-->
        <el-dialog
            :title="`Active ${$t('label.associates')} Required`"
            :show-close="false"
            :center="true"
            :visible.sync="performanceWarningOpen"
            :close-on-click-modal="false"
            width="300px">
            <div class="text-center">
                <div class="flex justify-center">
                    <img src="@/assets/images/square-icon.png" class="w-32 h-32"/>
                </div>
                <div class="mt-4 break-normal">
                    <p>Woof, it seems you haven't imported all your active {{$t('label.associates')}}. Please import your {{$t('label.associates')}}.</p>
                </div>
                <div class="mt-4">
                    <el-button @click="goToGuide" class="rounded-lg shadow" type="primary">Learn How</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- ADD DA KUDO MODAL -->   
        <el-dialog
            :title="`Create ${$t('label.associate')} Kudo`"
            :visible.sync="showAddKudoForm">
            <DaKudoForm 
                v-if="showAddKudoForm" 
                @close="showAddKudoForm = false"
                @close-add-kudo="showAddKudoForm = false"
                @mutate-add-kudo="showAddKudoForm = false"/>
        </el-dialog>



        <!-- IMPORT MODAL -->   
        <el-dialog
            title="Import Weekly Performance Data"
            :visible.sync="importModalOpen"
            :close-on-click-modal="false">
            <DocumentImporter 
                v-if="importModalOpen" 
                :key="importModalId"
                @close="importModalOpen = false"
                @show-warning-dialog="showWarningDialog('performance and coaching', ' import POD quality feedback and customer feedback documents')"/>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="importModalOpen = false">Done</el-button>
            </span>
        </el-dialog>

        <Banner 
            bg="bg-brand" icon="uil-usd-circle" class="display-banner"
            :copy="referralBannerText"
        />

        <!--Footer--> 
        <footer id="app-footer" class="bg-gray-300  pt-3 pb-4 text-sm mt-0 md:-mt-4 ">
            <div class="text-center mb-4 md:mb-0 md:float-right md:mr-12">
                <a href="http://aws.amazon.com" target="_blank" rel="noopener noreferrer">
                    <img class="w-20 mt-2 mx-auto" src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing">
                </a>
            </div>
            <div class="container mx-auto text-center px-6">
                <div class="space-x-2 text-sm">
                    <span><a href="mailto:support@herasolutionsinc.zohodesk.com" class="cursor-pointer">Contact Us</a></span>
                    <span>|</span>
                    <span><a :href="helpLink" target="_blank" class="cursor-pointer">Help</a></span>
                    <span>|</span>
                    <span><a href="https://app.termly.io/document/terms-of-use-for-saas/968db6eb-0700-41c4-a39c-d0ab4592474e" target="_blank" class="cursor-pointer">Terms of Use</a></span>
                    <span>|</span>
                    <span><a href="https://app.termly.io/document/privacy-policy/7a725f15-65ec-4acf-a70f-98ad254c7a2b" target="_blank" class="cursor-pointer">Privacy Policy</a></span>
                    <span>|</span>
                    <span class="termly-cookie-preference-button cursor-pointer" @click="manageCookiePreferences">Manage Cookie Preferences</span>

                    <div class="text-xxs mt-1">&copy; {{new Date().getFullYear()}} | All Rights Reserved | Hera Solutions, Inc. 
                    Hera is not affiliated with Amazon or its subsidiaries.
                    Version {{$store.state.version}}.</div>
                </div>
            </div>
        </footer>    

    </div>      
</template>

<script>
import { isEmailAllowed } from '@/utilities/isEmailAllowed'
import { Auth } from 'aws-amplify'
import { mapMutations, mapState, mapGetters } from 'vuex'
import DocumentImporter from '@/components/parse/DocumentImporter'
import Notifications from '@/components/Notifications'
import MessengerIndicator from '@/views/Messenger/MessengerIndicator'
import MessengerIndicatorModule from '@/views/Messenger/MessengerIndicatorModule'
import NavMobile from '@/components/NavMobile'
import NavButton from '@/components/NavButton'
import JobStatusCard from '@/components/parse/JobStatusCard'
import {subscriptionSetup} from '@/store/subscriptionModule'
import PremiumWarning from '@/components/PremiumWarning'
import { staffsCounterByGroupStatus } from '@/api/queries'
import DaKudoForm from '@/components/DaKudoForm'
import {getUser} from "@/api/queries";
import { invoicesByGroup } from './queries'
import EventBus from '@/eventBus'
import LogRocket from 'logrocket'
import Banner from '@/components/shared/Banner'
import { getRoute } from '@/middlewares/permissions'
import { date } from '@/utilities/getDate'
import momentTz from 'moment-timezone'
import { INCIDENT_ROUTE_NEW } from '@/utilities/constants/routeNames';
import CompanyTime from '@/components/Profile/CompanyTime';
import ClockSyncMessage  from '@/views/Auth/ClockSyncMessage';
import { checkClockSync } from '@/utilities/clockSync';
import Search from '@/components/Search';
import { referralBannerText } from '@/utilities/constants/referralBannerText.js';
import ProductFruitsComponent from '@/components/ProductFruitsComponent.vue';

export default {
    components: {
        DocumentImporter,
        NavButton,
        NavMobile,
        JobStatusCard,
        Notifications,
        MessengerIndicator,
        MessengerIndicatorModule,
        PremiumWarning,
        DaKudoForm,
        Banner,
        CompanyTime,
        ClockSyncMessage,
        Search,
        ProductFruitsComponent
    },
    data(){
        return{
            isMobile: null,
            WEEKLY_IMPORT: process.env.VUE_APP_WEEKLY_IMPORT === "1",
            USER_PERMISSIONS: process.env.VUE_APP_USER_PERMISSIONS === "1",
            showAddKudoForm: false,
            importModalOpen: false,
            importModalId: null,
            counselingModalOpen: false,
            formData:{},
            showUpgradeDialog: false,
            warningTitle: '',
            warningMessage: '',
            warningType: '',
            countOfActiveStaff: 0,
            performanceWarningOpen: false,
            injuryFormFields: {
                injuryDate: "",
                injuryTime:"",
                timeStaffStartedWork: "",
                completedBy: "",
                physicianName: "",
                physicianFacility: "",
                physicianAddress: "",
                physicianCity: "",
                physicianState: "",
                physicianZip: "",
                wasTreatedInER: "",
                wasHospitalizedOvernight: "",
                descriptionBeforeAccident: "",
                descriptionInjury: "",
                descriptionIncident: "",
                descriptionDirectHarmCause: "",
                injuryType: "",
                notes: "",
                dateOfDeath: "",
                staff: {id: null}
            },
            isExpanded: true,
            containerClass: 'container-fluid',
            showExpandCollapseButton: false,
            hamburgerActive: false,
            // intercomMessageCount: 0,
            hasTimezone: true,
            isAdmin: false,
            route: '',
            heraBannerCount: 0,
            helpLink: 'https://herasolutionsinc.zohodesk.com/portal/en/kb/',
            isTimeSynchronized: true
        }
    },
    beforeMount() {
        getRoute(this.$store.state.userInfo.id, this.$store.state.userInfo.tenant.group).then(route => { 
            this.route = route
        })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    async mounted(){
        const browserTime = new Date()
        this.isTimeSynchronized = await checkClockSync(browserTime)
        this.isMobile = window.screen.width <= 1300 || this.isMobileDevice();
        subscriptionSetup.subscribe()
        // this.$store.dispatch('monitorJobsStatus')

        // get number of Intercom messgages
        // window.Intercom('onUnreadCountChange', (unreadCount) => {
        //     this.intercomMessageCount = unreadCount
        // });
        this.hasTimezone = localStorage.getItem('HasTimezone') === "1"
        this.isAdmin = localStorage.getItem('IsAdmin') === "1"

        var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
        console.log('Login subdomain', subdomain)
        if (subdomain == 'dsp' && this.$store.getters.isLoggedIn && isEmailAllowed(this.$store.state.userInfo.email)) {
            this.initLogRocket()
            this.setUserLogRocketSessionUrlInZohoSalesIQ()
        }

        EventBus.$on('tenant-has-timezone', () => this.handleTenantHasTimeZone())

        let hasHeraBanner = document.getElementsByName("hera-banner").length
        this.heraBannerCount = hasHeraBanner

        this.$nextTick(()=> {          
            let hasBanner = !!document.querySelectorAll('[name="intercom-banner-frame"]').length
                || !!document.querySelectorAll('[role="alert"]').length;
            if(hasBanner){
                document.body.classList.add('alert')
            }
        })
        this.loadExpandedContainer()
        this.$nextTick(() => {
            window.addEventListener('resize', this.handleResize);
            // Check for initial window size, in case it's already above the threshold
            this.handleResize();
        });

        EventBus.$on('load-daily-import-results', (payload)=>{
            performanceImportNotificationManager(payload);
        });

        const performanceImportNotificationManager = (payload) => {
            const { importId, notification } = payload;
            const { title, description: message } = notification;

            const isRegistered = this.getPerformanceImportsNotification.find(item => item.id === importId)
            if(!isRegistered) return;

            if(isRegistered.notificationInstance.visible){
                isRegistered.notificationInstance.close();
            }
            
            const notificationRef = this.$notify({
                title,
                message,
                type: 'success',
                duration: 3000,
                customClass: 'cursor-pointer',
                onClick: () => {
                    notificationRef.close();
                    if (this.$route.name !== JSON.parse(notification.payload).name) this.$router.push(JSON.parse(notification.payload));
                }
            });
        }

        EventBus.$on('load-import-results', (payload)=>{
            performanceImportNotificationManager(payload);
        });

    },
    beforeDestroy(){
        subscriptionSetup.unsubscribe()
        EventBus.$off('load-daily-import-results');
        EventBus.$off('load-import-results');
    },
    
    // ensure mobile nav closes on route change
    watch:{
        $route (to, from){
            this.hamburgerActive = false
            this.loadExpandedContainer()
        }
    },
    computed:{
        ...mapState(['userInfo', 'companyLogo']),

        ...mapGetters([
            'hasDAManagement',
            'hasVehicleManagementPermission',
            'hasMessengerManagement',
            'hasLogguedSupportAdministrator',
            'hasIncidentAccess',
            'hasCounselingManagement',
            'getPerformanceImportsNotification',
            'getContainerData',
        ]),

        trialDaysRemaining(){
            if(!this.$store.state.userInfo.tenant.trialExpDate) return ''
            else{
                var daysRemaining = Math.ceil( ( new Date(this.$store.state.userInfo.tenant.trialExpDate).getTime() -  new Date().getTime()) / (1000 * 3600 * 24) )
                return daysRemaining
            }
        },
        
        showTrial(){
            return this.$store.getters.isTrialAccount
        },
        outstandingDaysRemaining(){
            if(!this.$store.state.userInfo.tenant.payOutstandingByDate) return ''
            else{
                var daysRemaining = Math.ceil( ( new Date(this.$store.state.userInfo.tenant.payOutstandingByDate).getTime() -  new Date().getTime()) / (1000 * 3600 * 24) )
                return daysRemaining
            }
        },
        showUnpaid(){
            return this.$store.getters.isUnpaidAccount
        },

        mainContentStyle(){
            let pathMessenger = (this.$route.path == "/settings/messenger" || 
                                this.$route.path == "/settings/messenger/") ||
                                this.$route.path == "/reports/missing-phone-number" ? false : this.$route.path.includes('/messenger/') || 
                                this.$route.path.includes('/m/') || 
                                this.$route.path.includes('/messenger') || 
                                (this.$route.path.includes('/m') && !this.$route.path.includes('/milestones'))
            
            if(pathMessenger){
                if(window.innerWidth > 768) return (this.heraBannerCount == 0) ? { paddingTop: "128px !important" } : { paddingTop: `${ 128 + (60 * this.heraBannerCount)}px !important` }
                if(window.innerWidth < 768) return (this.heraBannerCount == 0) ? { paddingTop: "70px !important" } : { paddingTop: `${ 70 + (105 * this.heraBannerCount)}px !important` }
            }else{
                if(window.innerWidth > 768) return (this.heraBannerCount == 0) ? { paddingTop: "200px !important" } : { paddingTop: `${ 200 + (60 * this.heraBannerCount)}px !important` }
                if(window.innerWidth < 768) return (this.heraBannerCount == 0) ? { paddingTop: "90px !important" } : { paddingTop: `${ 90 + (90 * this.heraBannerCount)}px !important` }
            }
        },

        referralBannerText(){
            return referralBannerText
        },
        
    },

    provide() {
        return {
            showWarningDialog: this.showWarningDialog,
        };
    },

    methods:{   
        handleResize() {      
            this.isMobile = window.screen.width <= 1300 || this.isMobileDevice();
            this.showExpandCollapseButton = window.innerWidth > 1536 ? true : false
        }, 
        ...mapMutations([
            'setSessionInfo', 'setUserInfo','setContainerData'
        ]),

        async initLogRocket() {

                console.log('using logrocket')
                LogRocket.init('kdsnjf/hera-solutions', {
                    release: '3.2.9'
                });

                // months invoiced
                let invoicesInput = {
                    group: this.$store.state.userInfo.tenant.group,
                    limit: 500,
                }
                let invoicesResponse = await this.api(invoicesByGroup, invoicesInput)

                const userFullName = `${this.userInfo.firstName} ${this.userInfo.lastName}`
                const tenantName = this.userInfo.tenant.companyName

                let logRocketIdentify = {
                    email: this.$store.state.userInfo.email,
                    name: `${userFullName} (${tenantName})`,
                    role: this.$store.state.userInfo.role,
                    phone: this.$store.state.userInfo.phone,
                    company_id: this.$store.state.userInfo.tenant.id,
                    company: tenantName,
                    customerStatus: this.userInfo.tenant.customerStatus,
                    trial_expiration_date: this.$store.state.userInfo.tenant.trialExpDate.toString().split('T')[0],
                    dsp_short_code: this.$store.state.userInfo.tenant.shortCode,
                    months_invoiced: invoicesResponse.data.invoicesByGroup.items.length,
                    total_invoiced: this.calculateTotalInvoiced(invoicesResponse.data.invoicesByGroup.items),
                    plan: ''
                }

                if (this.$store.state.userInfo.tenant.accountPremiumStatus) {
                    logRocketIdentify.plan = this.formatPlan(
                    this.$store.state.userInfo.tenant
                    )
                }

                console.log('logRocketIdentify', JSON.stringify(logRocketIdentify))
                console.log('logRocketIdentify User ID', JSON.stringify(this.$store.state.userInfo.id))

                //use logrocket
                LogRocket.identify(this.$store.state.userInfo.id, logRocketIdentify);
        },

        setUserLogRocketSessionUrlInZohoSalesIQ(){
            let env = localStorage.getItem("VUE_APP_HERA_ENV")
            if(env === 'development') {
                const url = window.location.href;
                env = url.includes("-rc") ? 'development' : url.includes("-mqa") ? 'test' : 'development';
            }
            LogRocket.getSessionURL(sessionURL => {
                const zohoInfo = {
                    "User's Tenant Name": `${this.userInfo?.tenant?.companyName ?? ''}`, 
                    "User's Tenant ID": `${this.userInfo?.tenant?.id ?? ''}`,
                    "User's App Environment": `${env}`,
                    "logrocket-sessionUrl": `${sessionURL}` }
                if(!this.userInfo?.tenant?.id) return
                window.zoho.salesiq.visitor.info(zohoInfo);
            });
        },
        
        calculateTotalInvoiced: function (invoices) {
            let total = 0
            invoices.forEach((invoice) => {
                total += invoice.invoiceTotal
            })

            return total.toFixed(2)
        },

        formatPlan: function (tenant) {
            let accountPremiumStatus = tenant.accountPremiumStatus

            if (
                accountPremiumStatus.length > 1 &&
                accountPremiumStatus.includes('standard')
            ) {
                let filtered = accountPremiumStatus.filter(
                (status) => status != 'standard'
                )

                let abbreviations = {
                staff: this.$t('label.associate'),
                performance: 'Performance',
                rostering: 'Roster',
                vehicles: 'Vehicles',
                }
                const abbreviated = filtered.map((item) => abbreviations[item])

                return `Standard+${abbreviated.join('-')}`
            }

            if (
                accountPremiumStatus.includes('trial') &&
                tenant.trialExpDate < this.currentDateFormatted
            ) {
                return 'Expired'
            }

            return this.toTitleCase(accountPremiumStatus[0])
        },
        
        handleTenantHasTimeZone() {
            console.log('handleTenantHasTimeZone')
            localStorage.setItem('HasTimezone', "1")
            this.hasTimezone = true
        },

        triggerMobileMenu(){
            this.hamburgerActive = !this.hamburgerActive
        },

        mobileNavNotifications(){
            this.triggerMobileMenu()
            this.$router.push({name: 'NotificationIndex'})
        },

        mobileHandleCommand(command){
            if(!command) return this.triggerMobileMenu()
            this.triggerMobileMenu()
            this.handleCommand(command)
        },
        mobileOpenImportModal(command){
            this.triggerMobileMenu()
            this.openImportModal(command)
        },
        mobileContactUs(){
            this.triggerMobileMenu()
            this.contactUs()
        },
        /**
         * Logs out of the app and takes you back to the login screen
         */
        async logout(){
            this.$nextTick(()=> this.$router.push({name: 'Login', params: {logout: true}}))
            this.displayUserNotification({
                title: "Success",
                message: 'Logged out successfully',
                type: 'success'
            })
        },

        /**
         * Handles commands passed from the dropdown
         * @param {string} command - The name of the route you want to go to
         */
        handleCommand(command){
            if(command === "return-as-admin"){
                this.setGroup(localStorage.getItem('superadminId'))
                this.hasLogguedSupportAdministrator = false
                localStorage.removeItem('superadminId')
                localStorage.removeItem('supportAdministrator')
                window.location.reload()
                return
            }else if(command == "Logout"){
                this.logout()
                return
            }else if (command == "Task" && !this.$store.getters.hasStaffManagement){
                //staff management required to get to menu
                this.showWarningDialog(`${this.$t("label.associate")} management`, 'assign and manage tasks for users')
                return
            }
            else if(command == "netradyneAlert" && !this.$store.getters.hasPerformanceCoaching){
                this.showWarningDialog('performance and coaching', 'view Netradyne alerts')
                return
            }
            else if(command == "HelpCenter"){
                window.open(this.helpLink)
                return
            }
            else if(command == "referLink"){
                window.open('https://grow.surf/csff71')
                return
            }
            else if(command == "StaffOptedOut" && (!this.$store.getters.hasPerformanceCoaching && !this.$store.getters.hasDailyRostering && !this.$store.getters.hasStaffManagement)){
                this.showWarningDialog(`performance and coaching or daily rostering or ${this.$t("label.associate")} management`, 'view staff opted out of communications')
                return
            }
            else if(command == "netradyneAlert"){
                var currentDate = this.$moment()
                var aMonthAgo = this.$moment(currentDate).subtract(1, 'months');
                this.$router.push({name: 'NetradyneIndex', params:{dashboardStartDate: aMonthAgo, dashboardEndDate: currentDate}})
                return
            }

            if (this.$route.name !== command) this.$router.push({ name: command })
        },

        
        async openImportModal(payload){
            if(payload.title == "DailyDataImport" && !this.$store.getters.hasPerformanceCoaching){
                this.showWarningDialog('performance and coaching', 'import daily performance data')
                return
            }
            if(payload.title == "DailyRosterImport" && !this.$store.getters.hasDailyRostering){
                this.showWarningDialog('daily rostering', 'import your daily roster')
                return
            }
            if(payload.title == "addCounseling"){
                if(!this.$store.getters.hasPerformanceCoaching){
                    this.showWarningDialog('performance and coaching', 'create counselings')
                    return
                }
                this.$router.push({name:"CounselingNew", params:{userId: this.userInfo.id, redirect : (payload.redirect && payload.redirect === 'New') ? payload.redirect : 'Details' }})
                return
            }
            if(payload.title == "addDriverIssue"){
                if(!this.$store.getters.hasStaffManagement){
                    this.showWarningDialog(`${this.$t("label.associate")} management`, `create ${this.$t("label.associate")} Issues`)
                    return
                }
                this.$router.push({name:"DaIssueNew"});
                return
            }
            if(payload.title == "addDriverIssueInfractionType"){
                if(!this.$store.getters.hasStaffManagement){
                    this.showWarningDialog('DA management', 'create DA Issues')
                    return
                }
                this.$router.push({name:"DaIssueEditInfractionType", params:{infractionType: 'Amazon Violation/Defect'}});
                return
            }
            if(payload.title == "addDriverKudo"){
                if(!this.$store.getters.hasStaffManagement){
                    this.showWarningDialog(`${this.$t("label.associate")} management`, `create ${this.$t("label.associate")} Kudos`)
                    return
                }
                // open kudo dialog
                this.showAddKudoForm = true
                return
            }
            if(payload.title == "addIncident"){
                if(!this.hasIncidentAccess) {
                    this.showWarningDialog('Vehicle Management or Associate Management', 'create Indicent Records')
                    return
                }

                const loadAsAccidentType = (payload.param === 'accident');

                this.$router.push({
                    name: INCIDENT_ROUTE_NEW,
                    params: {
                        loadAsAccidentType,
                    },
                });
                return;
            }
            if(payload.title == "addInjury"){
                if(!this.$store.getters.hasStaffManagement){
                    this.showWarningDialog(`${this.$t("label.associate")} management`, 'create Injury Records')
                    return
                }
                this.$router.push({name:"InjuryNew", params:{formFields: this.injuryFormFields}});
                return
            }
            else if(payload.title == "CustomCSVImport" || payload.title == "DailyAmazonImport") {
                const dateId = momentTz.tz(this.getTenantTimeZone()).format('YYYY-MM-DD')
                if (this.$route.name !== payload.title) this.$router.push({name: payload.title, params: { dateId }})
                return
            }
            else if(payload.type == "nav" && payload.title == "Task") {
                if (this.$route.name !== payload.title) this.$router.push({name: payload.title, params:{addTask: true}})
                return
            }
            else if(payload.type == "nav" && payload.title != "addCounseling") {
                if (this.$route.name !== payload.title) this.$router.push({name: payload.title})
                return
            }

            this.countOfActiveStaff = await this.getCountOfActiveStaff()
            if(this.countOfActiveStaff < 5){
                this.performanceWarningOpen = true
                return
            }
            this.importModalOpen = true
            return
        },

        contactUs(){
            Intercom('show');
        },

        showWarningDialog(warningType, warningMesage){
            this.warningType = warningType;
            this.warningMessage = warningMesage;
            this.importModalOpen = false;
            this.showUpgradeDialog = true;
        },

        goToAccountDetails(){
            this.showUpgradeDialog = false;
            this.$router.push({name: 'SettingsAccountDetails'})
        },

        async getCountOfActiveStaff(){
            let totalActivesAssociates = 0
            var input = {
                group: this.$store.state.userInfo.tenant.group,
                status: {eq:"Active"}
            }
            try {
                totalActivesAssociates = await this.recordCounter(staffsCounterByGroupStatus, input, 'staffsByGroupStatus')
            } catch (error) {
                this.printUserError(error)
            } finally {
                return totalActivesAssociates
            }
        },

        goToGuide(){
            this.performanceWarningOpen = false
            window.open('https://intercom.help/hera-solutions/en/articles/4527148-importing-staff', '_blank')
        },
      
        async setGroup(userId) {
          const user = await this.api(getUser, {id: userId})
  
          // Persistence in localStorage
          localStorage.removeItem('superadminId')
          localStorage.setItem('supportAdministrator', userId)
  
          this.$store.commit('setUserInfo', user.data.getUser)
  
          this.$router.push({name: 'Dashboard'})
          window.location.reload()
        },

        loadExpandedContainer(){
            const newData = {
                routeName: this.$route.name,
                containerClass: this.isExpanded ? 'container-fluid' : 'container',
                isExpanded: this.isExpanded,
            }
            const breakError = {}
            
            try {
                const scrollWidth = document.documentElement.scrollWidth
                if(scrollWidth > 1536){
                    const containerData = this.getSessionStorageJsonParse('containerData')
                    let expandedData = []
            
                    if(containerData.length !== 0){
                        expandedData = containerData
                    }else{
                        this.setSessionStorage('containerData', JSON.stringify([newData]))
                    }
                    let existData = false
                    expandedData.forEach(data => {
                        if(data.routeName === newData.routeName){
                            existData = true
                            this.isExpanded = data.isExpanded
                            this.containerClass = data.containerClass 
                            throw breakError
                        }else{
                            this.isExpanded = newData.isExpanded
                            this.containerClass = newData.containerClass
                        }
                    })
                    if(!existData){
                        expandedData.push(newData)
                        this.setSessionStorage('containerData', JSON.stringify(expandedData))
                    }
                }
            } catch (e) {
                if (e !== breakError) throw e;
            }
        },

        toggleExpandCollapseTable() {
            this.isExpanded = !this.isExpanded
            this.containerClass = this.isExpanded ? 'container-fluid' : 'container'
            this.saveExpandOrShrinkData(this.containerClass, this.isExpanded)
        },

        saveExpandOrShrinkData(containerClass, isExpanded){
            const containerData = this.getSessionStorageJsonParse('containerData')
            containerData.forEach(data => {
                if(data.routeName === this.$route.name){
                    data.containerClass = containerClass
                    data.isExpanded = isExpanded
                }
            })
            this.setSessionStorage('containerData', JSON.stringify(containerData))
            this.setContainerData(containerData)
        },

        getSessionStorageJsonParse(key){
            const containerData = JSON.parse(sessionStorage.getItem(key))
            if(!containerData) return []
            return containerData
        },
  
        setSessionStorage(key, value){
            sessionStorage.setItem(key, value)
        },
        manageCookiePreferences() {
            this.loadTermlyScript().then(() => {
                if (typeof displayPreferenceModal === 'function') {
                displayPreferenceModal();
                } else {
                console.error('displayPreferenceModal function is not defined');
                }
            }).catch(error => {
                console.error('Failed to load the termly script:', error);
            });
        },
        loadTermlyScript() {
            //COOKIE BANNER
            const scriptUrl = 'https://app.termly.io/resource-blocker/6cee0ae5-76db-4e82-af22-79efb0715644?autoBlock=on';
            return new Promise((resolve, reject) => {
                let locationPathName = window.location.pathname;
                let excludeCookieBanner = [
                '/cs/', '/counseling-signature/complete', '/f/', '/notification-preferences/', '/u/'
                ];

                if (!excludeCookieBanner.find(item => locationPathName.includes(item))) {
                var existingScript = document.querySelector(`script[src="${scriptUrl}"]`);
                if (existingScript) {
                    // Script is already loaded
                    resolve();
                } else {
                    var s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = scriptUrl;
                    s.setAttribute("data-name", "termly-embed-banner");
                    s.onload = resolve; // Resolve the promise when the script is loaded
                    s.onerror = reject; // Reject the promise if there's an error loading the script
                    var x = document.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                }
                } else {
                resolve(); // Resolve the promise if script loading is skipped
                }
            });
        }   

    }
}

/**
 * Scroll event listener used to make nav sticky
 */
document.addEventListener('scroll', (evt) => {
  stickyCheck()
}, {
  capture: true,
  passive: true
})


/**
 * Checks page scroll position and adds sticky classes or removes them
 */
function stickyCheck(){
    var body = document.querySelector('body');
    var navContainer = document.getElementById("nav-container")
    let totalHeight = 0
    let intercomBannerHeight = body.style.maxHeight.replace('calc(100% - ', '').replace('px)', '');
    if(intercomBannerHeight){
      totalHeight+= parseInt(intercomBannerHeight)
    }
    let ribbons = document.querySelectorAll('.z-50.sticky.top-0');
    if(ribbons){
      ribbons.forEach((ribbon, index) => {
        totalHeight+= parseInt(ribbon.clientHeight);
      })
    }
    if(navContainer){
        if(window.pageYOffset > parseInt(totalHeight)){
            navContainer.classList.add("sticky-header")
            if(document.getElementById('top').offsetTop > 0){
                navContainer.setAttribute('style', 'top:'+totalHeight+'px !important;')
                navContainer.classList.add("sticky-header-lower")
            }else{
            navContainer.setAttribute('style','top:0px !important;')
            }
        }else{
            navContainer.classList.remove("sticky-header")
        }
    }
}

</script>


<style lang="scss">
    @media (min-width: 768px) { // md 
        .main-nav{
            top: auto;
        }
        #nav-container > .router-link-active::after{
            content: "";
            position: relative;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid rgb(247, 250, 252);
            display: block;
            text-align: center;
            transition: all .1s ease-in-out;
            margin: 5px auto;
            opacity: 1;
            z-index: 10;
        }
        a.nav-button{
            @apply w-40;
            transition: height .15s ease-in-out;
        }
        .sticky-header, .short-header {
            z-index: 999 !important;
            // position: fixed;
            top: 0;
            right: 0;
            left: 0;
            .nav-button {
                height: 50px;
                padding-top: 3px;
                transition: height .15s ease-in-out;
                .nav-title{
                    display: none;
                }
            }
            .main-nav-messenger.nav-button .notification-bubble{
                display: block;
            }
        }
        .sticky-header-lower {
            top: 48px !important;
        }

        .sticky-header > .router-link-active > i,
        .short-header  > .router-link-active > i{
            //transition: all 0.9s ease;
            opacity: 1 !important;
        }

        .sticky-header > .router-link-active::after,
        .short-header > .router-link-active::after{
            display: none !important;
        }

        @media only screen and (max-width: 768px) {
            .sticky-header > .nav-button,
            .short-header > .nav-button{
                @apply text-gray-600 h-10 text-2xl text-white w-20;
                transition: all 0.9s ease;
                opacity: .7;
                .nav-title{
                    @apply hidden;
                }
            }
        }

        .sticky-header > .nav-button:hover,
        .short-header > .nav-button:hover{            
            opacity: 1;
        }
    }

    .main-nav-messenger{
        //@apply mt-1;
        button{
            margin:auto;
            padding:0;
        }
        i, span{
            color: #fff;
        }
       margin-right: 5px;
    }
    .nav-button{
        @apply text-white text-center transition duration-300;
    }

    .nav-button:hover{
        background-color: rgba(255, 255, 255, 0.25);
    }

    #top{
        width: 100vw
    }

    #nav-container > .router-link-active{
        @apply bg-white bg-opacity-25;
        opacity: 1;
        border-radius: 0;
    }

    .warning-message{
        word-break:break-word;
    }

    .break-word{
        word-break: break-word;
    }
    
    .user-title{
        color: #606266;
        font-size: 14px;
        padding: 0 20px;
        margin: 0;
    }
    .mobile-menu{
        // top: 36px;
        @apply shadow-lg;
        height: 100vh;
        overflow: scroll;
    }
    .FixedImp{
        position: fixed !important;
    }
    .AbsImp{
        position: absolute !important;
    }
    .slide-out{
        right: 0px;
    }
    .slide-in{
        right: -14rem;
    }
    .w-56{
        width: 18rem !important;
    }
    .-ml-56 {
        margin-left: -18rem !important;
    }
    .pt-21{
        padding-top: 85px;
    }
    .pt-65{
        padding-top: 255px;
    }
    .pt-70{
        padding-top: 320px;
    }
    .cursor-pointer {
        cursor: pointer;
    }
</style>
<style>
    .company-wrap, img.logo{
        min-width: 105px;
    }
    .mobile .el-submenu .el-menu-item, .mobile .el-submenu__title, .mobile .el-menu-item {
        height: 36px !important;
        line-height: 36px !important;
        padding-left: 20px !important;
    }
    .el-submenu .el-menu-item:hover, .el-menu-item:hover {
        color: #3586c9 !important;
    }
    .el-menu-item-group__title{
        padding: 0px !important;
    }
    .notification-count{
        padding: 0 5px;
    }
    .warning-text-button{
        @apply text-sm text-yellow-900 underline !important;
    }

    .danger-text-button{
        @apply text-sm underline text-white !important;
    }
    .scroll-container{
        height: 100%;
        overflow-y: scroll;
    }
    
    @media only screen and (max-width : 901px) {
    .display-banner{
        display: none;
        }
    }
    @media only screen and (max-width: 768px) {
        /* logo */
        .logo {
            border-right: 0 !important
        }
        /* hide desktop module nav */
        #nav-container{
            display: none;
        }

        #top{
            border-bottom: 6px solid #0268BC;
        }

        /* adjust content padding */
        .router-wrap{
            @apply p-4
        }
        .messenger .router-wrap{
            @apply p-0
        }
        .messenger{
            max-width: 100%!important;
        }
    
        /* radio group tabs */
        
        .router-wrap .sm-wrap.justify-between.items-center{
            display: block
        }
        .router-wrap .sm-wrap .el-breadcrumb {
            @apply mb-5 mt-3
            
        }
        .sm-wrap .el-radio-group{
            /* display: block; */
            @apply grid grid-cols-2 gap-2
            
        }
        .sm-wrap .el-radio-group .el-radio-button {
            display: block;
            outline: none;
            /* margin: 8px auto; */
            margin: 0 auto;
            width: 100%;
        }
        .sm-wrap .el-radio-group .el-radio-button .el-radio-button__inner{
            width: 100% !important;
            border-radius: 5px;
            border: 1px solid #ccc;
        }
        .sm-wrap .el-radio-group .el-radio-button.is-active .el-radio-button__inner {
            border: 0;
        }

        /* modal/forms */
        .el-dialog,
        .el-message-box {
            width: 92% !important;
        }
        .el-form .el-form-item__label{
            width: 100% !important;
            text-align: left;
            margin-left: .25em
        }
        .el-form .el-form-item__content, .el-form .el-form-item{
            margin-left: 0 !important;
        }
        .el-form .flex-1{
            margin-left: 0;
        }
        .el-popup-parent--hidden .el-select-dropdown.el-popper {
            width: 90%!important;
            left: 5px !important;
        }
    }
    @media only screen and (max-width: 362px) {
        .router-wrap .sm-wrap .el-breadcrumb {
            @apply mb-3 mt-2 text-xs
        }
    }

    .custom-shadow {
        box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .custom-font-size {
        font-size: 1.5rem;
        line-height: 1.5rem; 
    }

    .container-fluid {
        width: 100%;
        @apply px-3 mx-auto;
    }
    
    @media only screen and (max-width: 1500px) {
        .show-expand-button {
            @apply hidden
        }
    }

    @media only screen and (max-width: 768px) {
        .container-fluid {
            width: 100%;
            @apply px-0 mx-auto;
        } 
    }

    @media only screen and (min-width: 768px) and (max-width:1536px){
        .container{
            @apply min-w-full px-3
        }
        .container-fluid{
            @apply px-3
        }
    }
    @media only screen and (min-width:1537px){
        .container{
            @apply px-10
        }
        .container-fluid{
            @apply px-10
        }
    }
    
</style>
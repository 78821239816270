import moment from 'moment-timezone';

/**
 * If the date is between the start date and the end date, return true, otherwise return false.
 * @param {Date} start - the start date of the range
 * @param {Date} end - the end date of the range
 * @param {Date} date - the date to check
 * @returns A boolean value.
 */
export function isInRange(start, end, date) {
    const isoFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

    const formattedStartDate = moment(start).startOf('day').format(isoFormat);
    const formattedEndDate = moment(end).endOf('day').format(isoFormat);

    return date >= formattedStartDate && date <= formattedEndDate;
}

/**
 * It takes a label, and returns a range of dates based on that label.
 * 
 * @param {string} label - the label of the range you want to calculate (label = current week, last week, last 2 weeks, last 3 weeks, last 30 days, last 60 days, last 90 days, previous month, last month, last 2 months, last 3 months, current year, last year)
 * @returns An array of two dates.
 */
export function calculateRange(label){
    let start = new Date();
    let end = new Date();

    const range = {
      "current week": function () {
        start = moment().startOf('week').day(0).toDate();
        end = moment().endOf('week').day(6).toDate();
      },
      "last week": function () {
        start = moment().subtract(1, 'weeks').startOf('week').day(0).toDate();
        end = moment().subtract(1, 'weeks').endOf('week').day(6).toDate();
      },
      "current & previous week": function () {
        start = moment().subtract(1, 'weeks').startOf('week').day(0).toDate();
        end = moment().endOf('week').day(6).toDate();
      },
      "last 7 days": function () {
        start = moment().startOf('day').subtract(6, 'days').toDate();
        end = moment().endOf('day').toDate();
      },
      "last 14 days": function () {
        start = moment().startOf('day').subtract(13, 'days').toDate();
        end = moment().endOf('day').toDate();
      },
      "last 21 days": function () {
        start = moment().startOf('day').subtract(20, 'days').toDate();
        end = moment().endOf('day').toDate();
      },
      "last 2 weeks": function () {
        start = moment().subtract(1, 'weeks').startOf('week').day(0).toDate();
        end = moment().endOf('week').day(6).toDate();
      },
      "last 3 weeks": function () {
        start = moment().subtract(1, 'weeks').startOf('week').day(0).toDate();
        end = moment().endOf('week').day(6).toDate();
      },
      "last 30 days": function () {
        start = moment().startOf('day').subtract(29, 'days').toDate();
        end = moment().endOf('day').toDate();
      },
      "last 60 days": function () {
        start = moment().startOf('day').subtract(59, 'days').toDate();
        end = moment().endOf('day').toDate();
      },
      "last 90 days": function () {
        start = moment().startOf('day').subtract(89, 'days').toDate();
        end = moment().endOf('day').toDate();
      },
      "current month": function () {
        start = new Date(start.getFullYear(), start.getMonth(), 1);
        const lastDayOfMonth = new Date(end.getFullYear(), end.getMonth(), 0);
        end = new Date(end.getFullYear(), end.getMonth(), lastDayOfMonth.getDate() - 1)
      },
      "previous month": function () {
        start = new Date(start.getFullYear(), start.getMonth() - 1, 1);
        end = new Date(end.getFullYear(), end.getMonth(), 0);
      },
      "current & previous month": function () {
        start = new Date(start.getFullYear(), start.getMonth() - 1, 1);
        const lastDayOfMonth = new Date(end.getFullYear(), end.getMonth(), 0);
        end = new Date(end.getFullYear(), end.getMonth(), lastDayOfMonth.getDate() - 1)
      },
      "current year": function () {
        start = new Date(start.getFullYear(), 0, 1);
        const firstDayOfNextYear = new Date(end.getFullYear() + 1, 0, 1);
        end = new Date(firstDayOfNextYear - 1);
      },
      "last year": function () {
        start = new Date(start.getFullYear() - 1, 0, 1);
        const firstDayOfCurrentYear = new Date(end.getFullYear(), 0, 1);
        end = new Date(firstDayOfCurrentYear - 1);
      },
      "current & previous year": function () {
        start = new Date(start.getFullYear() - 1, 0, 1);
        const firstDayOfNextYear = new Date(end.getFullYear() + 1, 0, 1);
        end = new Date(firstDayOfNextYear - 1);
      },
    };


    if(range[label]===undefined) return
    range[label]();

    return [start, end];
}

import { API, graphqlOperation } from 'aws-amplify'
import { createAuditLog } from '@/graphql/mutations'
import { multipleMutations } from '@/utilities/gqlMutations'
export default class AuditLog {
    /**
     * Create Entry into AuditLog table
     * @param input
     * @param query
     * @param response
     * @param store 
     */
    async create(input, query, response, store) {

        // return void if not a mutation call.
        if(!!!query.match(/\W*(mutation )\W*/g) || !!!response) return;

        if(input.MULTIPLE_MUTATION){
            await this.createMultiple(input, response, store)
        }else{
            await this.createSingle(input, query, response, store)
        }

    }

    async createSingle(input, query, response, store) {
        try{
            const mutation = this.extractMutationName(JSON.stringify(query));
            const mutationNameText = this.getMutationBusinessName(store.getters.getMutationNameMap,mutation);
            const auditLog = {
                tenantID: store.state.userInfo.tenant.id,
                group: store.state.userInfo.tenant.group,
                email: store.state.userInfo.email,
                userID: store.state.userInfo.id,
                cognitoSub: store.state.userInfo.cognitoSub,
                firstName: store.state.userInfo.firstName,
                lastName: store.state.userInfo.lastName,
                tenantName: store.state.userInfo.tenant.companyName,
                mutationName: mutation, 
                mutationNameText: mutationNameText,
                mutatedRecordId: this.getMutatedRecordId(response, input, mutation),
                mutatedData: JSON.stringify(this.getResultData(response, query)),
                ipAddress: window.ip ? window.ip : '127.0.0.1',
                ttl: this.calculateTtlDate(3),
                pageUrl: window.location.href,
                logRocketSessionUrl: '' 
            };
            window._lr_surl_cb(url => { auditLog.logRocketSessionUrl = url; })
            await safeFunction(API.graphql)(graphqlOperation(createAuditLog, {input:auditLog}));
        }
        catch(e){
            if(e.message !== 'No current user'){
                //Not throwing exception for auditlog because it will be executed async, so it will not stop actual API call which was being logged. 
                console.error(e)
            }
        }        
    }

    async createMultiple(input, response, store){

        let logRocketSessionUrl
        window._lr_surl_cb(url => { logRocketSessionUrl = url; })

        const multiQuery = {
            AuditLog: { create: [] }
        }

        Object.entries(input.MULTIPLE_MUTATION).forEach(([mutationName, inputData])=>{
            inputData.forEach(key=>{
                const mutation = mutationName.charAt(0).toUpperCase() + mutationName.slice(1)
                const mutationNameText = this.getMutationBusinessName(store.getters.getMutationNameMap, mutation);
                const data = {
                    tenantID: store.state.userInfo.tenant.id,
                    group: store.state.userInfo.tenant.group,
                    email: store.state.userInfo.email,
                    userID: store.state.userInfo.id,
                    cognitoSub: store.state.userInfo.cognitoSub,
                    firstName: store.state.userInfo.firstName,
                    lastName: store.state.userInfo.lastName,
                    tenantName: store.state.userInfo.tenant.companyName,
                    mutationName: mutation, 
                    mutationNameText: mutationNameText,
                    mutatedRecordId: this.getMutatedRecordId(response, input[`${key}Input`], key),
                    mutatedData: JSON.stringify(response.data[key]),
                    ipAddress: window.ip ? window.ip : '127.0.0.1',
                    ttl: this.calculateTtlDate(3),
                    pageUrl: window.location.href,
                    logRocketSessionUrl
                }
                multiQuery.AuditLog.create.push({
                    input: data
                })
            })
        })

        await multipleMutations(multiQuery, 'createMultipleAuditLog', false)

    }

    extractMutationName(query){
        const match = query.match(/mutation\s([A-Za-z]+)/);
        return match[1];
    }

    getResultData(result, query) {
        const extractMutationName = this.extractMutationName(query)
        const mutationName = extractMutationName.charAt(0).toLowerCase() + extractMutationName.slice(1)
        return result.data[mutationName];
    }

    getMutatedRecordId(response, input, mutation){
        
        if(!!response && mutation.toLowerCase().indexOf('create')>-1){
            const keys = response.hasOwnProperty('data') ? Object.keys(response.data) : Object.keys(response);
            for (const key of keys) {
                if (key.toLowerCase() === mutation.toLowerCase()){
                    if(response.data[key].hasOwnProperty('id'))
                        return response.data[key].id;
                    else
                        return '';
                }
            }
        }
        else{
            if(!!input['input'] && input['input'].hasOwnProperty('id'))
                return input['input'].id;
            else if(!!input && input.hasOwnProperty('id'))
                return input.id;
            else
                return '';
        } 
            
    }

    getMutationBusinessName(map, key){
        if(Object.keys(map).indexOf(key)>-1){
            return map[key];
        }
        else return key;
    }

    calculateTtlDate(months){
        var dt = new Date();
        return Math.round(dt.getTime()/1000)+(24*3600*months*30);
    }
}
import { render, staticRenderFns } from "./AuthLayout.vue?vue&type=template&id=aeb60a06&scoped=true&"
var script = {}
import style0 from "./AuthLayout.vue?vue&type=style&index=0&id=aeb60a06&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeb60a06",
  null
  
)

export default component.exports
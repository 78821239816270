<template v-loading="loading">
    <div>
        <p class="padding-bottom-1 warning-message">{{ deletionMessage }}</p>
        <p class="text-red-500 font-bold">There is no undo for this action.</p>
        <el-form label-position="top" ref="delete" :rules="rules" :model="deleteConfirmation" :hide-required-asterisk="true"> 
            <el-form-item prop="delete">
                <span slot="label">
                    Type the word <b>DELETE</b> to confirm.
                </span>
                <el-input v-model="deleteConfirmation.delete"></el-input>
            </el-form-item>
        </el-form>
        <div class="flex justify-end">
            <el-button type="default" class="cancel-button" @click="closeModal">Cancel</el-button>
            <el-button type="primary" @click="deleteAction">Confirm</el-button>
        </div>
    </div>
</template>

<script>

import Validator from '@/utilities/FormValidator'

export default {
    props: ['deletionMessage'],
    data(){
        return{
            loading: false,
            deleteConfirmation: {
                delete: ''
            },
            
            rules: {
                delete: [
                    { required: true, message: 'Please enter the correct phrase to confirm', validator: Validator.delete },
                ]
            }
        }
    },

    methods:{
      deleteAction() {
          try {

            this.$refs.delete.validate((valid) => {
                if (!valid) {
                    throw {
                        errors: [
                            { message: "Error: Incorrect phrase"}
                        ]
                    }
                }
            })
            
            this.$emit("delete-action")

          } catch(e) {
            this.displayUserError(e)          
            return
            }
      },
      closeModal(){
          this.$emit("close-modal");
      }
    }

}
</script>
<style scoped>

</style>
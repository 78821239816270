
import { mixinMethods } from "@/main"
import { API, graphqlOperation } from 'aws-amplify'

const extractFragments = function(fragment, proxy){
    const matchNestedFragments = fragment?.match(/fragment\s*[\w\d]*\s*on\s*[\w\d]*\s*\{/g)
    if(matchNestedFragments){
        const lastNestedFragmentIndex = matchNestedFragments.length - 1
        matchNestedFragments.forEach((mnf, index) => {
            let nestedFragment
            if(index === lastNestedFragmentIndex){
                nestedFragment = fragment.substring(fragment.indexOf(mnf))
            }else{
                nestedFragment = fragment.substring(fragment.indexOf(mnf), fragment.indexOf(matchNestedFragments[index+1]))
            }
            const { fragmentName, tableName } = /fragment\s*(?<fragmentName>[\w\d]*)\s*on\s*(?<tableName>[\w\d]*)\s*\{/g.exec(nestedFragment)?.groups || {}
            if(fragmentName && tableName && !proxy.fragments[fragmentName+tableName]){
                proxy.fragments[fragmentName+tableName] = nestedFragment.replace(/( )+/g, ' ').trim()
            }
        })
    }
    const { fragmentName } = /fragment\s*(?<fragmentName>[\w\d]*)\s*on\s*[\w\d]*\s*\{/g.exec(fragment)?.groups || {}
    return fragmentName
}

const buildBody = (type, tableName, i, nested, options, proxy) => {
    const queryName = type + tableName
    const alias = queryName + i
    const queryUpper = queryName.charAt(0).toUpperCase() + queryName.substr(1)
    let hasInput = !!nested.input, hasCondition = !!nested.condition
    let fragmentName
    if(hasInput){
        const keyName = `${alias}Input`
        const multipleMutation = proxy.multipleQ.MULTIPLE_MUTATION;
        multipleMutation[queryName]?.push(alias) || (multipleMutation[queryName] = [alias])
        proxy.multipleQ[keyName] = nested.input
        proxy.params += `$${keyName}: ${queryUpper}Input!\n`
    }
    if(hasCondition){
        proxy.multipleQ[`${alias}Condition`] = nested.condition
        proxy.params += `$${alias}Condition: Model${tableName}ConditionInput\n`
    }
    if(hasInput || hasCondition){
        fragmentName = extractFragments(options.fragment, proxy)
        proxy.objResponseNames[alias] = {queryName: queryName, index: i}
    }
    proxy.body += `${alias}: ${queryName}(${hasInput?`input: $${alias}Input`:''}${hasInput && hasCondition?', ':''}${hasCondition?`condition: $${alias}Condition`:''}){ ${fragmentName ? `...${fragmentName}` : ' id ' } }\n`
}

export const multipleMutations = async function(mutations, mutationName = 'CustomMultipleMutation', auditLogAfter = true){

    if(auditLogAfter && mutations.AuditLog){
        delete mutations.AuditLog
    }
    const tables = Object.entries(mutations)
    const proxy = {
        body: '',
        fragments: {},
        params: '',
        multipleQ: {
            MULTIPLE_MUTATION: {},
        },
        objResponseNames: {}
    }

    tables.forEach(([tableName, options]) => {
        ['create', 'update', 'delete'].forEach(mode => {
            if(options[mode]){
                if(Array.isArray(options[mode])){
                    options[mode].forEach((nested, i) => buildBody(mode, tableName, i, nested, options, proxy) )
                }else{
                    buildBody(mode, tableName, 0, options[mode], options, proxy)
                }
            }
        })
    })

    try {
        let multipleQuery = `mutation ${mutationName}(\n${proxy.params}){\n${proxy.body}}`
        Object.entries(proxy.fragments).forEach(([k, fragmentStr]) => (multipleQuery = `${fragmentStr}\n${multipleQuery}`) )
        let response
        const mQuery = {...proxy.multipleQ}
        if(auditLogAfter){
            response = await mixinMethods.api(multipleQuery, mQuery)
        }else{
            response = await safeFunction(API.graphql)(graphqlOperation(multipleQuery, mQuery))
        }
        const data = {}
        Object.entries(proxy.objResponseNames).forEach(([key, obj])=>{
            !data[obj.queryName] && (data[obj.queryName] = [])
            data[obj.queryName][obj.index] = response.data[key]
        })
        return { data }
    } catch (error) {
        error.message = `[${mutationName}] Multiple Query Errors.`
        throw error

    }

}

export default {
    multipleMutations
}
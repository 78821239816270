import {
    listUsers,
    rosterChecklistsByGroup,
    rosterChecklistUsersByUser,
    listRoutes,
    dailyRostersByGroupAndNotesDate,
    staffRosterChecklistByNotesDate
} from './queries'
import { createStaffRosterChecklist} from './mutations'
import store from '@/store'
// import { convertUtcToCompanyLocal } from '@/utilities/getDate'

const itemById = function(list, id, keyOrCallback) {
    if(!list?.length || !id){
        return undefined
    }
    const item = list.find(cf => cf.id === id) || {}
    switch(typeof keyOrCallback){
        case 'string':
            return item[keyOrCallback]
        case 'function':
            return keyOrCallback(item)
        default:
            return item
    }
}

const getRosterChecklists = async function(){
    const input = {
        group: store.state.userInfo.tenant.group
    }
    const response = await this.api(rosterChecklistsByGroup, input)
    return response.data?.rosterChecklistsByGroup?.items || []
}

const getStaffRosterChecklistsByNotesDate = async function(notesDate){
    const input = {
        notesDate
    }
    const response = await this.api(staffRosterChecklistByNotesDate, input)
    return response.data?.staffRosterChecklistByNotesDate?.items || []
}

const findRosterChecklistsByUserId = async function(userId){
    const input = {
        userId,
        group: store.state.userInfo.tenant.group
    }
    const response = await this.api(rosterChecklistUsersByUser, input)
    return response.data.rosterChecklistUsersByUser
}

const getRoutes = async function(){
    const input = {
        group: store.state.userInfo.tenant.group
    }
    const response = await this.api(listRoutes, input)
    return response.data.listRoutes?.items || []
}

const loadRoster = async(dateId)=>{
    const input = {
        notesDate : {
            eq: dateId
        },
        group: store.state.userInfo.tenant.group
    }
    const roster = await this.gLoadListAll(dailyRostersByGroupAndNotesDate, input, 'dailyRostersByGroupAndNotesDate')
    return roster
}

//Exclusions in sending messages
const filteredRoutes = function(routes){
    const excludedStatuses = [
        'Called Out',
        'No Show, No Call',
        'VTO'
    ]
    return routes.filter(route => {
        const replaceByRoute = route.replaceByRoute?.items
        if(replaceByRoute?.length){
            return !excludedStatuses.includes(replaceByRoute[replaceByRoute.length-1].status)
        }
        return !excludedStatuses.includes(route.status)
    })
}

const generateStaffRosterchecklist = async function(notesDate, associateId, rosterChecklists, staffRosterChecklists){
    const associateRosterChecklist = []
    for (const rosterChecklist of rosterChecklists){
        if(rosterChecklist.completionFrequency.key === 'RCHKLST_CF_ONCE_PER_ROSTERED_VEHICLE'){
            // console.log("vehicle")
        }else if(rosterChecklist.completionFrequency.key === 'RCHKLST_CF_ONCE_PER_PRIMARY_ROSTERED_ASSOCIATE'){
            let input = {
                id: associateId+rosterChecklist.id+notesDate,
                group: store.state.userInfo.tenant.group,
                notesDate,
                staffId: associateId,
                rosterChecklistId: rosterChecklist.id
            }
            if(!staffRosterChecklists.find(src=>src.id === input.id)){
                try {
                    const response = await this.api(createStaffRosterChecklist, {input})
                    input = { ...input, ...response.data.createStaffRosterChecklist }
                } catch (error) {
                    console.log("error",{error},error)
                }
            }
            associateRosterChecklist.push(input.id)
        }
    }
    return associateRosterChecklist
}

const generateStaffRosterchecklists = async function(routes, notesDate){
    const rosterChecklists = await getRosterChecklists()
    
    const associateRosterChecklist = {}
    
    const staffRosterChecklists = await getStaffRosterChecklistsByNotesDate(notesDate)

    for (const route of routes){
        const routeStaffId = route.routeStaffId || route.staff?.id
        if(routeStaffId){
            associateRosterChecklist[routeStaffId] = await generateStaffRosterchecklist(notesDate, routeStaffId, rosterChecklists, staffRosterChecklists)
        }
    }
    return associateRosterChecklist
}


const test1 = async(notesDate)=>{
    const [roster] =  await loadRoster(notesDate)
    const routes = filteredRoutes(roster?.route.items || [])
    const associateRosterChecklist = await generateStaffRosterchecklists(routes, notesDate)
    return associateRosterChecklist
}

export default {
    methods: {
        itemById,
        getRosterChecklists,
        findRosterChecklistsByUserId,
        test1
    }
}
<template>
   <Drawer
      v-if="drawerVisibility"
      :title="titleDrawer"
      :visibility="drawerVisibility"
      @close-drawer="hideDrawer"
    >
      <template v-slot:drawerContent>
        <DrawerContent
          :data="tableDataSelected"
          @reloadTable="reloadCustomList"
          @setOptionsToReassign="setOptionsToReassign"
          ref="options"
        />
      </template>
      <template v-slot:drawerfooter-start>
        <ButtonGroupWrapper>
            <template slot-scope="{disabled, setDisabled}">
                <el-button
                    data-cy="drawer-cancel-btn"
                    @click="hideDrawer"
                    :disabled="disabled"
                >
                    Cancel
                </el-button>
                <ButtonWrapper
                    type="primary"
                    data-cy="drawer-save-btn"
                    :clickHandler="saveDrawer"
                    :disabled="disabled || showPremiumRequired"
                    @cb-start="setDisabled(true)"
                    @cb-end="setDisabled(false)"
                >
                    Save
                </ButtonWrapper>
            </template>
        </ButtonGroupWrapper>
      </template>
    </Drawer> 
</template>

<script>
import Drawer from "@/components/shared/Drawer/Drawer";
import DrawerContent from "./DrawerContent";
import customListMixin from '../mixins'
import globalCustomListMixin from '@/mixins/customListMixin'
import { isOptionCustomListLinkedToOtherRecords } from "../utils";
import ButtonWrapper from "@/components/shared/ButtonWrapper/ButtonWrapper";
import ButtonGroupWrapper from "@/components/shared/ButtonGroupWrapper/ButtonGroupWrapper";
import { mapGetters, mapState } from 'vuex';
import { API } from 'aws-amplify';

export default {
    name: 'DrawerCustomList',
    mixins:[
        customListMixin,
        globalCustomListMixin
    ],
    data() {
        return {
            optionsToReassign: [],
        }
    },
    computed: {

        ...mapState([
            'userInfo'
        ]),

        ...mapGetters(['hasVehicleManagement']),

        showPremiumRequired(){
            return this.tableDataSelected.type === 'vehicle-type' && !this.hasVehicleManagement
        },
    },
    components: {
        Drawer,
        DrawerContent,
        ButtonWrapper,
        ButtonGroupWrapper,
    },
    props: {
        drawerVisibility: {
            type: Boolean,
            default: false
        },
        titleDrawer: {
            type: String,
            default: ''
        },
        tableDataSelected: {
            type: Object,
            default: () => ({}),
        }
    },
    methods: {

        async hideDrawer (load = true) {
            this.$emit("hideDrawer");
            if(load) this.reloadCustomList()
        },

        async reloadCustomList () {
            this.optionsToReassign = []
            this.$emit("reloadCustomList");
        },

        async setOptionsToReassign (options) {
            this.optionsToReassign = options
        },

        async saveDrawer () {
            try {
                // Custom List Options
                const options = this.reOrderListOfOptions(this.$refs.options.tableData)

                // Options to remove
                const optionsToRemove = this.$refs.options.optionsToRemove;
                const optionsToRemoveIds = new Set(optionsToRemove.map(option => option.id))

                const filteredOptions = options.filter(option => !optionsToRemoveIds.has(option.id))

                const promises = optionsToRemove.map(async (option) => await isOptionCustomListLinkedToOtherRecords(option.type, option));
                const results = await Promise.all(promises);
                const hasLinkedData = results.some((hasLinkedData) => hasLinkedData === true);
                if (hasLinkedData) {
                    const error = 'Some of the Custom List options marked for deletion are linked to other records and cannot be deleted. Please refresh your browser window to see the latest info.';
                    return this.displayUserError(error);
                }

                let hasEmptyOptionNames = options.some(item => item.option.trim() == '')
                if (hasEmptyOptionNames) throw new Error('An option name is required.');

                let hasSemicolonOptionNames = options.some(item => item.option.includes(';'))
                if (hasSemicolonOptionNames) throw new Error('An option name must not contain semicolon.');
                
                let uniqueOptionNames = filteredOptions.filter((value, index, self) => index === self.findIndex(t => value.option.toLowerCase().trim()  === t.option.toLowerCase().trim()))
                if (uniqueOptionNames.length !== filteredOptions.length) throw new Error('The option names must be unique.');
                const longOptionNames = options.some(item => this.isUsedForMaxCharactorCheck(item))
                if (longOptionNames) throw "The option name can have a maximum of 30 characters.";

                const optionsToAdd = options.filter(item => item.tempAdded && !optionsToRemove.map(option => option.id).includes(item.id))
                const optionsToUpdate = options.filter(item => !item.tempAdded && !optionsToRemove.map(option => option.id).includes(item.id))
                const deleteOptions = await this.deleteCustomListOptionsAlert(optionsToRemove, options)

                if(!deleteOptions) return

                this.hideDrawer(false)

                try {
                    let apiName = 'heraPublicApi';
                    let path = '/executeMutation';
                    let post = {
                        body:{
                            "type": 'custom-list',
                            "mutation": 'update',
                            "group": this.userInfo.tenant.group,
                            "owner": this.userInfo.cognitoSub,
                            "items": {
                                customListToUpdate: this.tableDataSelected,
                                optionsToUpdate,
                                optionsToAdd,
                                optionsToReassign: this.optionsToReassign,
                                optionsToRemove,
                            }
                        }
                    }

                    this.displayUserNotification({
                        title: 'Processing...',
                        type: "info",
                        duration: 5000,
                        message: 'Hera is currently updating the Custom List.'
                    })

                    safeFunction(API.post)(apiName, path, post);

                } catch (e) {
                    this.displayUserNotification({
                        title: "Error",
                        type: "error",
                        message: e
                    })
                }
            } catch (e) {
                this.printUserError(e)
                this.displayUserError(e)
            } finally {
                await this.reloadCustomList();
            }
        },

        isUsedForMaxCharactorCheck(item) {
            if (!item.isCustom) return false
            let check = false
            const type = this.tableDataSelected.type
            if (type === 'incident-type') {
                check = item.accidents?.items.length === 0;
            }else if (type === 'parking-space') {
                let routeParkingSpace = item.routeParkingSpace?.items.length || 0;
                let replaceByRouteParkingSpace = item.replaceByRouteParkingSpace?.items.length || 0;
                let vehicleParkingSpace = item.parkingSpace?.items.length || 0;
                let rosteredVehicle = routeParkingSpace + replaceByRouteParkingSpace + vehicleParkingSpace
                check = rosteredVehicle === 0;
            } else if (type === 'vehicle-type') {
                let vehicles = item.vehicles?.items.length || 0;
                let associates = item.associates?.items.length || 0
                check = vehicles === 0 && associates === 0;
            }else if (type === 'counseling-severity') {
                check = item.counselings?.items.length === 0;
            }else if (type === 'issue-type') {
                check = item.issues?.items.length === 0;
            }else if (type === 'kudo-type') {
                check = item.kudos?.items.length === 0;
            }else if (type === 'vehicle-company') {
                check = item.companies?.items.length === 0;
            }else if (type === 'roster-status') {
                let replaceByRoutes = item.replaceByRoutes?.items.length || 0;
                let routeHelperStatus  = item.routeHelperStatus?.items.length || 0;
                let routeParkingSpace  = item.routeParkingSpace?.items.length || 0;
                let routes = item.routes?.items.length || 0;
                let rostered = replaceByRoutes + routeHelperStatus + routeParkingSpace + routes
                check = rostered === 0;
            }
            else {
                check = item.usedFor === 0
            }
            return check ? item.canBeEdited && item.option.toLowerCase().trim().length > 30 : false
        },
        reOrderListOfOptions(list) {
            return list.map((item, index) => {
                item.order = index
                return item
            })
        }
    }
}
</script>

<style>
.v-modal {
    z-index: 1000 !important;
}
</style>

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
        id
        timeZone
        group
        automatedCoachingSendTime
        coachingCustomMessage

        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo

        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo

        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo

        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo

        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo

        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo

        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo

        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo

        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo

        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo

        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo

        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo

        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo

        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo

        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo

        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo

        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo

        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo

        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo

        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo

        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierCO
        coachingDaTierPR
        coachingDaTierRatingKudo

        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo

        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo

        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo

        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR

        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR

        coachingDnrCO
        coachingDnrPR
        coachingDnrThreshold

        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange

        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo

        updatedAt
    }
  }
`;

export const getDriverRankRange = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
        id
        coachingDriverRankRange
    }
  }
`;
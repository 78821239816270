<template>
  <div v-loading="loading">
        <div class="cell card stripe mt-4" id="card-2">
            <form id="setup-form" :data-secret="client_secret" >
                <div data-locale-reversible="">
                    <div class="row">
                        <div class="field half-width">
                        <input data-cy="stripe-first-name-in" id="stripe-first-name" data-tid="elements_cards.form.first_name_placeholder" class="input empty" type="text" required autocomplete="given-name" v-model="firstName">
                        <label for="stripe-card-first-name" data-tid="elements_cards.form.card_first_name_label">First Name</label>
                        <div class="baseline"></div>
                        </div>
                        <div class="field half-width">
                        <input data-cy="stripe-last-name-in" id="stripe-last-name" data-tid="elements_cards.form.last_name_placeholder" class="input empty" type="text" required autocomplete="family-name" v-model="lastName">
                        <label for="stripe-card-last-name" data-tid="elements_cards.form.card_last_name_label">Last Name</label>
                        <div class="baseline"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="field">
                        <input data-cy="stripe-email-in" id="stripe-email" data-tid="elements_cards.form.email_placeholder" class="input empty" type="text" required autocomplete="email" v-model="email">
                        <label for="stripe-email" data-tid="elements_cards.form.email_label">Email</label>
                        <div class="baseline"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="field">
                        <input data-cy="stripe-address-in" id="stripe-address" data-tid="elements_cards.form.address_placeholder" class="input empty" type="text" required autocomplete="address-line1" v-model="address">
                        <label for="stripe-address" data-tid="elements_cards.form.address_label">Address</label>
                        <div class="baseline"></div>
                        </div>
                    </div>
                    <div class="row" data-locale-reversible="">
                        <div class="field half-width">
                        <input data-cy="stripe-city-in" id="stripe-city" data-tid="elements_cards.form.city_placeholder" class="input empty" type="text" required autocomplete="address-level2" v-model="city">
                        <label for="stripe-city" data-tid="elements_cards.form.city_label">City</label>
                        <div class="baseline"></div>
                        </div>
                        <div class="field quarter-width">
                        <input data-cy="stripe-state-in" id="stripe-state" data-tid="elements_cards.form.state_placeholder" class="input empty" type="text" required autocomplete="address-level1" v-model="state">
                        <label for="stripe-state" data-tid="elements_cards.form.state_label">State</label>
                        <div class="baseline"></div>
                        </div>
                        <div class="field quarter-width">
                        <input data-cy="stripe-zip-in" id="stripe-zip" data-tid="elements_cards.form.postal_code_placeholder" class="input empty" type="text" required autocomplete="postal-code" v-model="zip">
                        <label for="stripe-zip" data-tid="elements_cards.form.postal_code_label">ZIP</label>
                        <div class="baseline"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                <div class="field">
                    <div data-cy="stripe-card-number-in" id="stripe-card-number" class="input"></div>
                    <label for="stripe-card-number" data-tid="elements_cards.form.card_number_label">Card number</label>
                    <div class="baseline"></div>
                </div>
                </div>
                <div class="row">
                <div class="field half-width">
                    <div data-cy="stripe-card-expiry-in" id="stripe-card-expiry" class="input"></div>
                    <label for="stripe-card-expiry" data-tid="elements_cards.form.card_expiry_label">Expiration</label>
                    <div class="baseline"></div>
                </div>
                <div class="field half-width">
                    <div data-cy="stripe-card-cvc-in" id="stripe-card-cvc" class="input"></div>
                    <label for="stripe-card-cvc" data-tid="elements_cards.form.card_cvc_label">CVC</label>
                    <div class="baseline"></div>
                </div>
                </div>
            </form>
        </div>
        <el-form class="mt-6">
            <el-form-item>
                <el-checkbox data-cy="stripe-authorization-chk" v-model="authorization" class="label-wrap">
                    I authorize Hera Solutions, Inc to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.
                </el-checkbox>
            </el-form-item>
        </el-form>
         <div class="w-full">
            <div class="flex">
                <el-button v-if="!checkout" @click="cancel" class="flex-1">Cancel</el-button>
                <el-button data-cy="step3-continue-btn" type="primary" @click="confirmSaveCard" class="flex-1" :disabled="disableSaveCard || loading">Save Card &amp; Continue</el-button>
            </div>
        </div>
  </div>
</template>

<script>
import { Auth, API } from 'aws-amplify';
import { createCard, updateCard, updateTenant, createNotification } from '@/graphql/mutations'
import { cardsByGroup, invoicesByGroup, getTenant } from '../queries'
import { getUser } from '@/api/queries'
import { mapMutations } from 'vuex'
import generateTemplate from '@/scripts/unpaidInvoiceTemplate.js'


var stripe
var elements
var cardNumber = undefined
var cardExpiry = undefined
var cardCvc = undefined 

export default {
  
  name: 'NewCard',
  props: ['loadTrigger', 'clearNewCardForm', 'checkout', 'tenant'],
  data(){
    return{
      loading: false,
      client_secret: '',
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      city: '',
      zip: '',
      state: '',
      authorization: false,
      savedCards: [],
      cardNumberEmpty: true,
      cardExpiryEmpty: true,
      cardCvcEmpty: true
    }
  },

  watch: {
    loadTrigger(){
        this.client_secret = ''
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.address = ''
        this.city = ''
        this.zip = ''
        this.state = ''
        this.authorization = false
        this.cardNumberEmpty = true
        this.cardExpiryEmpty = true
        this.cardCvcEmpty = true

        this.loadCards()
    },
    clearNewCardForm(){
      this.client_secret = ''
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.address = ''
      this.city = ''
      this.zip = ''
      this.state = ''
      this.authorization = false
      this.cardNumberEmpty = true
      this.cardExpiryEmpty = true
      this.cardCvcEmpty = true
    }
  },

  async mounted(){

    await this.initializeStripe();

    this.loading = true
    
    // Floating labels
    var inputs = document.querySelectorAll('.cell.card.stripe .input');
    Array.prototype.forEach.call(inputs, function(input) {
      input.addEventListener('focus', function() {
        input.classList.add('focused');
      });
      input.addEventListener('blur', function() {
        input.classList.remove('focused');
      });
      input.addEventListener('keyup', function() {
        if (input.value.length === 0) {
          input.classList.add('empty');
        } else {
          input.classList.remove('empty');
        }
      });
    });

    var elementStyles = {
      base: {
        color: '#32325D',
        fontWeight: 500,
        fontFamily: "system-ui",
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#CFD7DF',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      }
    };

    var elementClasses = {
      focus: 'focused',
      empty: 'empty',
    };
  
    // if(cardNumber === undefined){
      cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardNumber.mount('#stripe-card-number');
      // Handle real-time validation errors from the card Element.
      cardNumber.on('change', event => {
        if(event.complete) this.cardNumberEmpty = false
        else this.cardNumberEmpty = true
      });
    // }

    // if(cardExpiry === undefined){
      cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardExpiry.mount('#stripe-card-expiry');
      // Handle real-time validation errors from the card Element.
      cardExpiry.on('change', event => {
        if(event.complete) this.cardExpiryEmpty = false
        else this.cardExpiryEmpty = true
      });
    // }

    // if(cardCvc === undefined){
      cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardCvc.mount('#stripe-card-cvc');
      cardCvc.on('change', event => {
        if(event.complete) this.cardCvcEmpty = false
        else this.cardCvcEmpty = true
      });
    // }
    // ------- END Stripe Setup ------

    this.loadCards()

  },

  beforeDestroy(){
    cardNumber.unmount('#stripe-card-number');
    cardExpiry.unmount('#stripe-card-expiry');
    cardCvc.unmount('#stripe-card-cvc');
    cardNumber.destroy('#stripe-card-number');
    cardExpiry.destroy('#stripe-card-expiry');
    cardCvc.destroy('#stripe-card-cvc');

    // Delete the Stripe object to remove references
    delete this.stripe;
    if (window.Stripe) {
      delete window.Stripe;
    }

    // Remove the script tag
    const stripeScript = document.getElementById('stripe-js');
    if (stripeScript) {
      stripeScript.remove();
    }
  },

  computed: {
    fullName(){
      return this.firstName + ' ' + this.lastName
    },
    formInvalid(){
      return !this.firstName || !this.lastName || !this.email || !this.address || !this.city || !this.zip || !this.state || this.cardNumberEmpty || this.cardExpiryEmpty || this.cardCvcEmpty
    },
    disableSaveCard(){
      return !this.authorization || this.formInvalid
    }
  },

  methods:{
    ...mapMutations([
      'setUserInfo'
    ]),

    cancel(){
      this.$emit('cancel')
    },

    showOrderSummary(){
      this.$emit('show-order-summary')
    },

    async getStripeCustomer(customerId){
      let apiName = 'stripeSetup'
      let path = '/customer/' + customerId
      var result = await safeFunction(API.get)(apiName, path)
      return result.customer
    },

    async createStripeCard(clientSecret, customer, setupIntent){
      var setup = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement('cardNumber'),
            billing_details: {
              name: this.fullName,
              address: {
                city: this.city,
                line1: this.address,
                postal_code: this.zip,
                state: this.state
              },
              email: this.email.trim()
            }
          }
        }
      ).then(async result => {
          if (result.error) {
              throw result
          } 
          else {
              // if there is an active card already, make inactive
              var activeCards = this.savedCards.filter(card =>{
                return card.active
              })

              if(activeCards.length > 0){
                for (const card of activeCards) {
                  let input = {
                    id: card.id,
                    active: false
                  }

                  await this.api(updateCard, {input})
                }
              }

              // create card record in dynamo
              let input = {
                cardTenantId: this.$store.state.userInfo.tenant.id,
                group: this.$store.state.userInfo.tenant.group,
                stripeSetupIntent: setupIntent.id,
                stripeCustomerId: customer.id,
                stripePaymentMethodId: result.setupIntent.payment_method,
                active: true
              }
              var createdCard = await this.api(createCard, {input})

              // set card as default
              await this.updateStripeCustomer(customer.id, result.setupIntent.payment_method)
              
              return result
          }
      });
      return setup
    },

    async createStripeCustomer(){
      let apiName = 'stripeSetup'
      let path = '/customer'
      let post = {
        body: {
          name: this.tenant.companyName,
          email: this.tenant.stripeBillingEmail ? this.tenant.stripeBillingEmail.trim() : this.email.trim(),
          address:{
            city: this.tenant.adddressCity,
            line1: this.tenant.addressLine1,
            line2: this.tenant.addressLine2,
            postal_code: this.tenant.addressZip,
            state: this.tenant.addressState
          }
        }
      }
      var result = await safeFunction(API.post)(apiName, path, post)
      return result.customer
    },

    async updateStripeCustomer(customerId, defaultPaymentId){
      try{
        let apiName = 'stripeSetup'
        let path = '/customer/' + customerId
        let post = {
        body: {
          name:  this.tenant.companyName,
          email: this.tenant.stripeBillingEmail?.trim() || this.email.trim(),
          address: {
            city: this.tenant.adddressCity,
            line1: this.tenant.addressLine1?.trim() || this.address,
            line2: this.tenant.addressLine2,
            postal_code: this.tenant.addressZip?.trim() || this.zip,
            state: this.tenant.addressState?.trim() || this.state
          },
          defaultPaymentId: defaultPaymentId
        }
        }
        var result = await safeFunction(API.put)(apiName, path, post)
        return result.customer
      }catch(e){
        console.error('error updating customer',e)
        this.displayUserError(e)
      }finally{
        this.isLoading = false
      }
    },

    async createStripeSetupIntent(customer){
      let apiName = 'stripeSetup'
      let path = '/intent'
      let post = {
        body: {
          customer: customer
        }
      }
      var result = await safeFunction(API.post)(apiName, path, post)
      return result.intent
    },

    async loadCards(){
      this.loading = true
      try{
        // load Stripe keys from Dynamo
        var input = {
          group: this.$store.state.userInfo.tenant.group
        }
        var cards = await this.gLoadListAll(cardsByGroup, input, 'cardsByGroup')

        this.savedCards = cards
      }catch(e){
        console.error(e)
        this.displayUserError(e)
      }finally{
          this.loading = false
      }
    },

    async confirmSaveCard(){
        this.loading = true
      // check for unpaid invoices
      
      let input = {
        group: this.$store.state.userInfo.tenant.group,
        filter: {or:[{status: {eq: "Payment Error"}}, {status: {eq: "Finalized"}}]}
      }
      var unpaidInvoices = await this.gLoadListAll(invoicesByGroup, input, 'invoicesByGroup')
      
      if(unpaidInvoices.length){
        var invoicesHtml = generateTemplate(unpaidInvoices)
        this.loading = false
        this.$confirm('Warning', {
          type: 'warning',
          title: 'Warning',
          message: invoicesHtml,
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'Continue',
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false
        }).then(() => {
          this.saveCard(true)          
        }).catch(() => {
          console.error("canceled confirm")         
          this.loading = false
        });
      }
      else{
        this.saveCard(false)
      }
    },

    async saveCard(chargeOutstandingInvoices){
      this.loading = true
      try{
        // create or get stripe customer
        var customer = null
        if(!this.tenant.stripeCustomerId){
          
          customer = await this.createStripeCustomer()

          // update tenant record in Dynamo
          let input = {
            id: this.$store.state.userInfo.tenant.id,
            group: this.$store.state.userInfo.tenant.group,
            stripeCustomerId: customer.id,
            stripeBillingEmail: this.tenant.stripeBillingEmail ? this.tenant.stripeBillingEmail.trim() : this.email.trim()
          }
          try{
            // Validate StripeBillingEmail & stripeCustomerId duplicatation
            var response = await this.updateTenantBillingInfo(input)          
            this.tenant.updatedAt = response.updatedAt
          }
          catch(e){
            this.displayUserError(e)
            this.loading = false
            return
          }
        }
        else{
          customer = await this.getStripeCustomer(this.tenant.stripeCustomerId)
        }

        // create stripe setup intent
        var setupIntent = await this.createStripeSetupIntent(customer)

        // get client secret and collect card details
        var clientSecret = setupIntent.client_secret
        var card = await this.createStripeCard(clientSecret, customer, setupIntent)

        if(card.error){
          throw card.error
        }

        // charge outstanding invoices
        var allInvoicesPaid
        if(chargeOutstandingInvoices){
          let apiName = 'stripeSetup'
          let path = '/payment-intent/' + this.$store.state.userInfo.tenant.id
          let post = {body: {}}
          var result = await safeFunction(API.post)(apiName, path, post)
          allInvoicesPaid = result.allInvoicesPaid

          // check if all invoices were charged successfully
          if(allInvoicesPaid){
            // update user info of logged in user
            const userId = this.$store.state.userInfo.id
            const user = await this.api(getUser, {id: userId, group: 'system_admin'})

            //Set user info
            this.setUserInfo(user.data.getUser)

            // Update user info for all other users of tenant using notification system
            const tenant = await this.api(getTenant,{id: this.$store.state.userInfo.tenant.id});
            const users = tenant.data.getTenant.users.items

            //Create Notifications
            for await (const user of users) {

              let input = {
                title: "Outstanding Invoices Paid",
                description: 'All outstanding invoices for your account have been paid.',
                isReadS: "false",
                clickAction: "RELOAD",
                owner: user.cognitoSub,
                group: 'system_admin',
                expirationTTL: Math.floor((new Date().getTime() + 90 * 24 * 60 * 60 * 1000) / 1000)                
              }
              if( input.owner && user.id != this.$store.state.userInfo.id){
                await this.api(createNotification, {input} )
              }
            }

            // reload window to updae top nav display
            location.reload()
          }
          else{
            // display error that not all invoices were charged successfully
            this.displayUserError('All outstanding invoices were not charged successfully. Please try an alternate card.')
          }
        }

        this.$emit('complete-order', allInvoicesPaid)
      }
      catch(e){
        console.error('errpor on saveCard', e)
        this.displayUserError('There was an error when adding your credit card. Your card was not charged. Please try adding it again, or if there continues to be a problem, please contact Hera Support via the chat in the lower right-hand corner.')
      }finally{
        this.loading = false
      }
    },
    async updateTenantBillingInfo(input){
      let apiName = 'tenant'
      let path = '/update-billing-info'
      let post = {
        body: input
      }
      var result = await safeFunction(API.post)(apiName, path, post)
      if(!result.success){
        throw new Error(result.message)
      }
      return result.tenant
    },
    async loadStripeScript() {
      return new Promise((resolve, reject) => {
        if (document.getElementById('stripe-js')) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.id = 'stripe-js';
        script.src = 'https://js.stripe.com/v3/';
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    async initializeStripe() {
      try {
        // Ensure Stripe is loaded
        await this.loadStripeScript();

        // Initialize Stripe
        stripe = await safeFunction(Stripe)(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        if (!stripe) {
          throw new Error('Stripe failed to initialize');
        }

        // Initialize Elements
        elements = stripe.elements({
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
            },
          ],
          // Stripe's cards are localized to specific languages, but if
          // you wish to have Elements automatically detect your user's locale,
          // use `locale: 'auto'` instead.
          locale: 'auto',
        });

        if (!elements) {
          throw new Error('Stripe Elements failed to initialize');
        }

        console.log('Stripe and Elements initialized:', stripe, elements);
      } catch (error) {
        console.error('Error in initializing Stripe:', error);
      }
    }
  },
}
</script>

<style scoped>
.border-brand{
  @apply border-2 !important;
}
.card.stripe {
  background-color: #fff;
}

.card.stripe * {
  font-family: system-ui;
  font-size: 16px;
  font-weight: 500;
}

.card.stripe .row {
  display: -ms-flexbox;
  display: flex;
  margin: 0 0px 10px;
}

.card.stripe .field {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0 10px;
}

.card.stripe .field.half-width {
  width: 50%;
}

.card.stripe .field.quarter-width {
  width: calc(25% - 10px);
}

.card.stripe .baseline {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: #cfd7df;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card.stripe label {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 8px;
  color: #cfd7df;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform-origin: 0 50%;
  cursor: text;
  pointer-events: none;
  transition-property: color, transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card.stripe .input {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-bottom: 7px;
  color: #32325d;
  background-color: transparent;
}

.card.stripe .input::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card.stripe .input::-moz-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card.stripe .input:-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card.stripe .input.StripeElement {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
}

.card.stripe .input.focused,
.card.stripe .input:not(.empty) {
  opacity: 1;
}

.card.stripe .input.focused::-webkit-input-placeholder,
.card.stripe .input:not(.empty)::-webkit-input-placeholder {
  color: #cfd7df;
}

.card.stripe .input.focused::-moz-placeholder,
.card.stripe .input:not(.empty)::-moz-placeholder {
  color: #cfd7df;
}

.card.stripe .input.focused:-ms-input-placeholder,
.card.stripe .input:not(.empty):-ms-input-placeholder {
  color: #cfd7df;
}

.card.stripe .input.focused + label,
.card.stripe .input:not(.empty) + label {
  color: #aab7c4;
  transform: scale(0.85) translateY(-25px);
  cursor: default;
}

.card.stripe .input.focused + label {
  @apply text-brand;
}

/* .card.stripe .input.invalid + label {
  color: #ffa27b;
} */

.card.stripe .input.focused + label + .baseline {
  @apply bg-brand;
}

/* .card.stripe .input.focused.invalid + label + .baseline {
  background-color: #e25950;
} */

.card.stripe input, .card.stripe button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

/* .card.stripe input:-webkit-autofill {
  -webkit-text-fill-color: #e39f48;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out;
} */

.card.stripe .StripeElement--webkit-autofill {
  background: transparent !important;
}

.card.stripe input, .card.stripe button {
  -webkit-animation: 1ms void-animation-out;
}

.card.stripe button {
  display: block;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  @apply bg-brand shadow rounded-lg text-white;
  cursor: pointer;
}

.card.stripe .error svg {
  margin-top: 0 !important;
}

.card.stripe .error svg .base {
  fill: #e25950;
}

.card.stripe .error svg .glyph {
  fill: #fff;
}

.card.stripe .error .message {
  color: #e25950;
}

.card.stripe .success .icon .border {
  stroke: #abe9d2;
}

.card.stripe .success .icon .checkmark {
  stroke: #24b47e;
}

.card.stripe .success .title {
  color: #32325d;
  font-size: 16px !important;
}

.card.stripe .success .message {
  color: #8898aa;
  font-size: 13px !important;
}

.card.stripe .success .reset path {
  fill: #24b47e;
}


</style>

<style>
  .label-wrap .el-checkbox__label{
    @apply whitespace-normal !important;
  }
  .label-wrap .el-checkbox__input{
    @apply -mt-10 !important;
  }
</style>
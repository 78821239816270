export var weeklyPerformanceMetrics = [
    {
        defaults: { issueSlider: 100, kudoSlider: 100, thresholdIssue: "750", threshold: "800", thresholdPR: "800", thresholdKudo: "825", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardFICOScore', header: 'Scorecard', src: 'Scorecard', negativeToggleOnly: false, polarity: 1, label: 'FICO® Score', name: 'Fico', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 50, kudoSlider: 30, thresholdIssue: "98.66", threshold: "98.66", thresholdPR: "98.66", thresholdKudo: "99.5", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardDCR', src: 'Scorecard', negativeToggleOnly: false, polarity: 1, label: 'DCR', name: 'Dcr', type: 'percent', tooltip: 'Delivery Completion Rate (Note: the "Fantastic" threshold may vary by station - please check with your station'
    },
    {
        defaults: { issueSlider: 60, kudoSlider: 20, thresholdIssue: "70", threshold: "70", thresholdPR: "70", thresholdKudo: "100", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardDAR', src: 'Scorecard', negativeToggleOnly: false, polarity: 1, label: 'DAR', name: 'Dar', type: 'number', tooltip: 'Delivered And Received rate'
    },
    {
        defaults: { issueSlider: 60, kudoSlider: 0, thresholdIssue: "2", threshold: "0", thresholdPR: "0", thresholdKudo: "", issue: true, pr: true, co: true, kudo: false },
        sliderField: 'ScorecardDNR', src: 'Scorecard', negativeToggleOnly: false, polarity: -1, label: 'DNR', name: 'Dnr', type: 'number', tooltip: 'Did Not Receive rate'
    },
    {
        defaults: { issueSlider: 60, kudoSlider: 10, thresholdIssue: "584", threshold: "259", thresholdPR: "258", thresholdKudo: "100", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardDSB', src: 'Scorecard', negativeToggleOnly: false, polarity: -1, label: 'DSB', name: 'Dsb', type: 'number', tooltip: 'Delivery Success Behaviors'
    },
    {
        defaults: { issueSlider: 30, kudoSlider: 0, thresholdIssue: "98", threshold: "98", thresholdPR: "98", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: false },
        sliderField: 'ScorecardSWCPOD', src: 'Scorecard', negativeToggleOnly: false, polarity: 1, label: 'SWC-POD', name: 'SwcPod', type: 'percent', tooltip: 'Photo on Delivery score'
    },
    {
        defaults: { issueSlider: 30, kudoSlider: 0, thresholdIssue: "100", threshold: "100", thresholdPR: "100", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: false },
        sliderField: 'ScorecardSWCCC', src: 'Scorecard', negativeToggleOnly: false, polarity: 1, label: 'SWC-CC', name: 'SwcCc', type: 'percent', tooltip: 'Contact Compliance percentage'
    },
    {
        defaults: { issueSlider: 30, kudoSlider: 0, thresholdIssue: "99.9", threshold: "99.9", thresholdPR: "99.9", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: false },
        sliderField: 'ScorecardSWCSC', src: 'Scorecard', negativeToggleOnly: false, polarity: 1, label: 'SWC-SC', name: 'SwcSc', type: 'percent', tooltip: 'Scan Compliance percentage'
    },
    {
        defaults: { issueSlider: 30, kudoSlider: 0, thresholdIssue: "0", threshold: "0", thresholdPR: "0", thresholdKudo: "0", issue: true, pr: true, co: false, kudo: false },
        sliderField: 'ScorecardSWCAD', src: 'Scorecard', negativeToggleOnly: true, polarity: 1, label: 'SWC-AD', name: 'SwcAd', type: 'number', tooltip: 'Attended Delivery'
    },
    {
        defaults: { issueSlider: 100, kudoSlider: 10, thresholdIssue: "3", threshold: "0", thresholdPR: "0", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardSeatbeltOffRate', src: 'Scorecard', negativeToggleOnly: false, polarity: -1, label: 'Seatbelt-Off Rate', name: 'SeatbeltOff', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 100, kudoSlider: 10, thresholdIssue: "2", threshold: "0", thresholdPR: "0", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardSpeedingEventRate', src: 'Scorecard', negativeToggleOnly: false, polarity: -1, label: 'Speeding Event Rate', name: 'SpeedingEvent', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 75, kudoSlider: 85, thresholdIssue: "1.9", threshold: "0.2", thresholdPR: "0.2", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardHarshBrakingRate', src: 'Scorecard', negativeToggleOnly: false, polarity: -1, label: 'Harsh Braking Rate', name: 'HarshBrakingRate', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 75, kudoSlider: 85, thresholdIssue: "1.9", threshold: "0.2", thresholdPR: "0.2", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardHarshCorneringRate', src: 'Scorecard', negativeToggleOnly: false, polarity: -1, label: 'Harsh Cornering Rate', name: 'HarshCorneringRate', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 75, kudoSlider: 85, thresholdIssue: "0.9", threshold: "0.2", thresholdPR: "0.2", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardDistractionsRate', src: 'Scorecard', negativeToggleOnly: false, polarity: -1, label: 'Distractions Rate', name: 'DistractionsRate', type: 'number', tooltip: "Please reference Amazon's Metric Guide for further information on how the score is calculated."
    },
    {
        defaults: { issueSlider: 100, kudoSlider: 20, thresholdIssue: "0.5", threshold: "0.3", thresholdPR: "0.3", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardFollowingDistanceRate', src: 'Scorecard', negativeToggleOnly: false, polarity: -1, label: 'Following Distance Rate', name: 'FollowingDistanceRate', type: 'number', tooltip: "Please reference Amazon's Metric Guide for further information on how the score is calculated."
    },
    {
        defaults: { issueSlider: 100, kudoSlider: 30, thresholdIssue: "0.5", threshold: "0.5", thresholdPR: "0.5", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'ScorecardSignSignalViolationRate', src: 'Scorecard', negativeToggleOnly: false, polarity: -1, label: 'Sign/Signal Violation Rate', name: 'SignSignalViolationsRate', type: 'number', tooltip: "Please reference Amazon's Metric Guide for further information on how the score is calculated."
    },
    {
        defaults: { issueSlider: 90, kudoSlider: 50, thresholdIssue: "0", thresholdKudo: "0", ratingIssue: 'Fair', ratingKudo: 'Fantastic', issue: true, kudo: true },
        sliderField: 'ScorecardOverallTier', src: 'Scorecard', name: 'OverallTier', label: 'Overall Tier', tooltip: "Please reference Amazon's Metric Guide for further information on how the score is calculated."
    },
    {
        defaults: { issueSlider: 100, kudoSlider: 90, thresholdIssue: "2", thresholdKudo: "3", ratingIssue: 'Fair', ratingKudo: 'Fantastic', issue: true, kudo: true },
        sliderField: 'ScorecardConsecutiveTier', src: 'Scorecard', name: 'ConsecutiveTier', label: 'Consecutive Overall Tier', tooltip: "Please reference Amazon's Metric Guide for further information on how the score is calculated."
    },
    // {
    //     defaults: { issueSlider: 60, kudoSlider: 60, thresholdIssue: '75', threshold: '98', thresholdPR: '98', thresholdKudo: '100', issue: true, pr: true, co: true, kudo: true },
    //     sliderField: 'CXPositiveFeedback', src: 'CXFeedback', header: 'CX Feedback', negativeToggleOnly: false, polarity: 1, label: 'Positive Feedback', name: 'PositiveFeedback', type: 'number', tooltip: ""
    // },
    {
        defaults: { issueSlider: 60, kudoSlider: 60, thresholdIssue: '0', thresholdKudo: '0', ratingIssue: 'Fair', ratingKudo: 'Fantastic', ratingCO: 'Great', ratingPR: 'Fantastic', issue: true, pr: true, co: true, kudo: true },
        sliderField: 'CXDaTier', src: 'CXFeedback', header: 'CX Feedback', name: 'DaTier', label: 'DA Tier'
    },
    {
        defaults: { issueSlider: 60, kudoSlider: 60, thresholdIssue: '96', threshold: '100', thresholdPR: '100', thresholdKudo: '100', issue: false, pr: false, co: false, kudo: false },
        sliderField: 'CXCdfScore', src: 'CXFeedback', negativeToggleOnly: false, polarity: 1, label: 'CDF Score', name: 'CdfScore', type: 'number', tooltip: ""
    },
    {
        defaults: { issueSlider: 60, kudoSlider: 60, thresholdIssue: "75", threshold: "95", thresholdPR: "100", thresholdKudo: "100", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'PPSCompliance', src: 'ProperParkingSequence(PPS)', header: 'Proper Parking Sequence (PPS)', negativeToggleOnly: false, polarity: 1, label: 'PPS Compliance %', name: 'PpsCompliancePercent', type: 'percent', tooltip: '',
        thresholdIssueMax: 100, thresholdMax: 100, thresholdPRMax: 100, thresholdKudoMax: 100,
    },    
    {
        defaults: { issueSlider: 60, kudoSlider: 60, thresholdIssue: "5", threshold: "1", thresholdPR: "1", thresholdKudo: "0", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'PPSTotalBreaches', src: 'ProperParkingSequence(PPS)', negativeToggleOnly: false, polarity: -1, label: 'PPS Breaches', name: 'PpsBreaches', type: 'number', tooltip: '',
        thresholdIssueMax: Infinity, thresholdMax: Infinity, thresholdPRMax: Infinity, thresholdKudoMax: Infinity,
    },
];

export var scorecardAndCxMetricsMaxInputValue = {
    thresholdIssueMax: 850,
    thresholdMax: 850,
    thresholdPRMax: 850,
    thresholdKudoMax: 850,
}

export var dailyPerformanceMetrics = [
    {
        defaults: { issueSlider: 80, kudoSlider: 80, thresholdIssue: '750', threshold: '800', thresholdPR: '800', thresholdKudo: '825', issue: true, pr: true, co: true, kudo: true },
        sliderField: 'MentorDailyFICO', src: 'Mentor', header: 'Mentor', negativeToggleOnly: false, polarity: 1, label: 'Daily FICO® Score', name: 'DailyFico', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 100, kudoSlider: 10, thresholdIssue: '3', threshold: '0', thresholdPR: '0', thresholdKudo: '0', issue: true, pr: true, co: true, kudo: true },
        sliderField: 'MentorSeatbeltRate', src: 'Mentor', negativeToggleOnly: false, polarity: -1, label: 'Seatbelt', name: 'Seatbelt', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 100, kudoSlider: 10, thresholdIssue: '2', threshold: '0', thresholdPR: '0', thresholdKudo: '0', issue: true, pr: true, co: true, kudo: true },
        sliderField: 'MentorSse', src: 'Mentor', negativeToggleOnly: false, polarity: -1, label: 'Speeding/Sse', name: 'Sse', type: 'number', tooltip: 'If either Speeding or Sse crosses a threshold, the corresponding item will be created.'
    },
    {
        defaults: { issueSlider: 0, issue: false, co: true },
        sliderField: 'MentorTrainingsRemaining', src: 'Mentor', negativeToggleOnly: true, polarity: 1, label: 'Trainings Remaining', name: 'TraningRemaining', type: 'number', tooltip: ''
    },
    {
        defaults: { kudoSlider: 70, thresholdPR: '1', thresholdKudo: '1', pr: true, kudo: true },
        sliderField: 'NetradyneDriverStar', src: 'Netradyne', header: 'Netradyne', positiveToggleOnly: true, min: 0, polarity: 1, label: 'Driver Star', name: 'DriverStar', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 0, thresholdIssue: '0', threshold: '0', issue: false, co: true },
        sliderField: 'NetradyneCameraObstruction', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Camera Obstruction', name: 'CameraObstruction', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 100, thresholdIssue: '0', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneDriverDistraction', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Driver Distraction', name: 'DriverDistraction', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 0, thresholdIssue: '0', threshold: '0', issue: false, co: true },
        sliderField: 'NetradyneDriverDrowsiness', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Driver Drowsiness', name: 'DriverDrowsiness', type: 'number', tooltip: ''
    },
    // { defaults:{issueSlider: 50, thresholdIssue: '1', threshold: '0', issue: true, co: true },
    // sliderField: 'NetradyneDriverInitiated', src: 'Netradyne', negativeToggleOnly: true,  min: 0, polarity: -1, label: 'Driver Initiated', name: 'DriverInitiated', type: 'number', tooltip: ''},
    // { defaults:{issueSlider: 50, thresholdIssue: '1', threshold: '0', issue: true, co: true },
    // sliderField: 'NetradyneFaceMaskCompliance', src: 'Netradyne', negativeToggleOnly: true,  min: 0, polarity: -1, label: 'Face Mask Compliance', name: 'FaceMaskCompliance', type: 'number', tooltip: ''},
    {
        defaults: { issueSlider: 100, thresholdIssue: '0', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneFollowingDistance', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Following Distance', name: 'FollowingDistance', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 30, thresholdIssue: '5', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneHardAcceleration', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Hard Acceleration', name: 'HardAcceleration', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 50, thresholdIssue: '1', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneHardBraking', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Hard Braking', name: 'HardBraking', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 60, thresholdIssue: '1', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneHardTurn', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Hard Turn', name: 'HardTurn', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 60, thresholdIssue: '1', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneHighG', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'High G', name: 'HighG', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 0, thresholdIssue: '0', threshold: '0', issue: false, co: false },
        sliderField: 'NetradyneLowImpact', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Low Impact', name: 'LowImpact', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 0, thresholdIssue: '0', threshold: '0', issue: false, co: false },
        sliderField: 'NetradyneSeatbeltCompliance', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Seatbelt Compliance', name: 'SeatbeltCompliance', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 100, thresholdIssue: '0', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneSignViolations', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Sign Violations', name: 'SignViolations', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 50, thresholdIssue: '0', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneSpeedingViolations', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Speeding Violations', name: 'SpeedingViolations', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 100, thresholdIssue: '0', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneTrafficLightViolation', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Traffic Light Violation', name: 'TrafficLightViolation', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 100, thresholdIssue: '0', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneUturn', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'U-turn', name: 'UTurn', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 30, thresholdIssue: '1', threshold: '0', issue: true, co: true },
        sliderField: 'NetradyneWeaving', src: 'Netradyne', negativeToggleOnly: true, min: 0, polarity: -1, label: 'Weaving', name: 'Weaving', type: 'number', tooltip: ''
    },
    {
        defaults: { issueSlider: 60, kudoSlider: 60, thresholdIssue: '75', threshold: '95', thresholdPR: "100", thresholdKudo: "100", issue: true, pr: true, co: true, kudo: true },
        sliderField: 'EOCDailyComplianceRate', src: 'EOC', header: 'Engine Off Compliance (EOC)', negativeToggleOnly: false, min: 0, polarity: 1, label: 'Average Daily Compliance', name: 'DailyComplianceRate', type: 'percent', tooltip: 'Daily Compliance percentage'
    },
];
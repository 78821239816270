/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateTenantById = /* GraphQL */ `
  subscription OnUpdateTenantById($id: ID!) {
    onUpdateTenantById(id: $id) {
      id
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      group
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      cards {
        items {
          id
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
        }
        nextToken
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          createdAt
          updatedAt
        }
        nextToken
      }
      textractjobs {
        items {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      waves {
        items {
          id
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
        }
        nextToken
      }
      recurringMessage {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      parentAccount {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        nextToken
      }
      valueLists {
        items {
          id
          group
          key
          createdAt
          updatedAt
        }
        nextToken
      }
      premiumStatusHistory {
        items {
          id
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      messageServiceProviderHistory {
        items {
          id
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const subscriptionInfractionByGroup = /* GraphQL */ `
  subscription SubscriptionInfractionByGroup($group: String!) {
    subscriptionInfractionByGroup(group: $group) {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const subscriptionKudoByGroup = /* GraphQL */ `
  subscription SubscriptionKudoByGroup($group: String!) {
    subscriptionKudoByGroup(group: $group) {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateInfractionByGroup = /* GraphQL */ `
  subscription OnCreateInfractionByGroup($group: String!) {
    onCreateInfractionByGroup(group: $group) {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateInfractionByGroup = /* GraphQL */ `
  subscription OnUpdateInfractionByGroup($group: String!) {
    onUpdateInfractionByGroup(group: $group) {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteInfractionByGroup = /* GraphQL */ `
  subscription OnDeleteInfractionByGroup($group: String!) {
    onDeleteInfractionByGroup(group: $group) {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateKudoByGroup = /* GraphQL */ `
  subscription OnCreateKudoByGroup($group: String!) {
    onCreateKudoByGroup(group: $group) {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateKudoByGroup = /* GraphQL */ `
  subscription OnUpdateKudoByGroup($group: String!) {
    onUpdateKudoByGroup(group: $group) {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteKudoByGroup = /* GraphQL */ `
  subscription OnDeleteKudoByGroup($group: String!) {
    onDeleteKudoByGroup(group: $group) {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateStaffByGroup = /* GraphQL */ `
  subscription OnCreateStaffByGroup($group: String!) {
    onCreateStaffByGroup(group: $group) {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateStaffByGroup = /* GraphQL */ `
  subscription OnUpdateStaffByGroup($group: String!) {
    onUpdateStaffByGroup(group: $group) {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteStaffByGroup = /* GraphQL */ `
  subscription OnDeleteStaffByGroup($group: String!) {
    onDeleteStaffByGroup(group: $group) {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateVehicleByGroup = /* GraphQL */ `
  subscription OnCreateVehicleByGroup($group: String!) {
    onCreateVehicleByGroup(group: $group) {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateVehicleByGroup = /* GraphQL */ `
  subscription OnUpdateVehicleByGroup($group: String!) {
    onUpdateVehicleByGroup(group: $group) {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteVehicleByGroup = /* GraphQL */ `
  subscription OnDeleteVehicleByGroup($group: String!) {
    onDeleteVehicleByGroup(group: $group) {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateDeviceByGroup = /* GraphQL */ `
  subscription OnCreateDeviceByGroup($group: String!) {
    onCreateDeviceByGroup(group: $group) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const onUpdateDeviceByGroup = /* GraphQL */ `
  subscription OnUpdateDeviceByGroup($group: String!) {
    onUpdateDeviceByGroup(group: $group) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const onDeleteDeviceByGroup = /* GraphQL */ `
  subscription OnDeleteDeviceByGroup($group: String!) {
    onDeleteDeviceByGroup(group: $group) {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const onCreateUserByTenant = /* GraphQL */ `
  subscription OnCreateUserByTenant($userTenantId: ID!) {
    onCreateUserByTenant(userTenantId: $userTenantId) {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUserByTenant = /* GraphQL */ `
  subscription OnUpdateUserByTenant($userTenantId: ID!) {
    onUpdateUserByTenant(userTenantId: $userTenantId) {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUserByTenant = /* GraphQL */ `
  subscription OnDeleteUserByTenant($userTenantId: ID!) {
    onDeleteUserByTenant(userTenantId: $userTenantId) {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateOptionsCustomListsStaffByGroup = /* GraphQL */ `
  subscription OnCreateOptionsCustomListsStaffByGroup($group: String!) {
    onCreateOptionsCustomListsStaffByGroup(group: $group) {
      id
      group
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteOptionsCustomListsStaffByGroup = /* GraphQL */ `
  subscription OnDeleteOptionsCustomListsStaffByGroup($group: String!) {
    onDeleteOptionsCustomListsStaffByGroup(group: $group) {
      id
      group
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateMessageByGroup = /* GraphQL */ `
  subscription OnCreateMessageByGroup($group: String!) {
    onCreateMessageByGroup(group: $group) {
      id
      carrierMessageId
      group
      createdAt
      staffId
      userId
      messageType
      channelType
      destinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      smsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      senderId
      owner
      destinationName
      hasParts
      rosterChecklistSubjectId
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateMessageByGroup = /* GraphQL */ `
  subscription OnUpdateMessageByGroup($group: String!) {
    onUpdateMessageByGroup(group: $group) {
      id
      carrierMessageId
      group
      createdAt
      staffId
      userId
      messageType
      channelType
      destinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      smsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      senderId
      owner
      destinationName
      hasParts
      rosterChecklistSubjectId
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateRouteByGroup = /* GraphQL */ `
  subscription OnCreateRouteByGroup($group: String!) {
    onCreateRouteByGroup(group: $group) {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateRouteByGroup = /* GraphQL */ `
  subscription OnUpdateRouteByGroup($group: String!) {
    onUpdateRouteByGroup(group: $group) {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteRouteByGroup = /* GraphQL */ `
  subscription OnDeleteRouteByGroup($group: String!) {
    onDeleteRouteByGroup(group: $group) {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateReplaceByRouteByGroup = /* GraphQL */ `
  subscription OnCreateReplaceByRouteByGroup($group: String!) {
    onCreateReplaceByRouteByGroup(group: $group) {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateReplaceByRouteByGroup = /* GraphQL */ `
  subscription OnUpdateReplaceByRouteByGroup($group: String!) {
    onUpdateReplaceByRouteByGroup(group: $group) {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteReplaceByRouteByGroup = /* GraphQL */ `
  subscription OnDeleteReplaceByRouteByGroup($group: String!) {
    onDeleteReplaceByRouteByGroup(group: $group) {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDailyRosterByGroup = /* GraphQL */ `
  subscription OnUpdateDailyRosterByGroup($group: String!) {
    onUpdateDailyRosterByGroup(group: $group) {
      id
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      notes {
        items {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateDailyLogByGroup = /* GraphQL */ `
  subscription OnCreateDailyLogByGroup($group: String!) {
    onCreateDailyLogByGroup(group: $group) {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDailyLogByGroup = /* GraphQL */ `
  subscription OnUpdateDailyLogByGroup($group: String!) {
    onUpdateDailyLogByGroup(group: $group) {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteDailyLogByGroup = /* GraphQL */ `
  subscription OnDeleteDailyLogByGroup($group: String!) {
    onDeleteDailyLogByGroup(group: $group) {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateAccidentByGroup = /* GraphQL */ `
  subscription OnCreateAccidentByGroup($group: String!) {
    onCreateAccidentByGroup(group: $group) {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      incidentDocumentConnections {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamageRoute {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      maintenanceVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      damageVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      odometerReadingVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteAccidentByGroup = /* GraphQL */ `
  subscription OnDeleteAccidentByGroup($group: String!) {
    onDeleteAccidentByGroup(group: $group) {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      incidentDocumentConnections {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamageRoute {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      maintenanceVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      damageVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      odometerReadingVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateUserNotificationByUserId = /* GraphQL */ `
  subscription OnCreateUserNotificationByUserId($userId: String!) {
    onCreateUserNotificationByUserId(userId: $userId) {
      id
      group
      title
      description
      expirationTTL
      notificationType
      isRead
      payload
      userId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessageReadStatusByGroup = /* GraphQL */ `
  subscription OnCreateMessageReadStatusByGroup($group: String!) {
    onCreateMessageReadStatusByGroup(group: $group) {
      id
      group
      userID
      staffID
      readUpToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessageReadStatusByGroup = /* GraphQL */ `
  subscription OnUpdateMessageReadStatusByGroup($group: String!) {
    onUpdateMessageReadStatusByGroup(group: $group) {
      id
      group
      userID
      staffID
      readUpToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompanyNotificationByGroup = /* GraphQL */ `
  subscription OnCreateCompanyNotificationByGroup($group: String!) {
    onCreateCompanyNotificationByGroup(group: $group) {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNoteByGroup = /* GraphQL */ `
  subscription OnCreateNoteByGroup($group: String!) {
    onCreateNoteByGroup(group: $group) {
      id
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      vehiclesMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      staffMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateNoteByGroup = /* GraphQL */ `
  subscription OnUpdateNoteByGroup($group: String!) {
    onUpdateNoteByGroup(group: $group) {
      id
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      vehiclesMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      staffMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateResourceUsageByGroup = /* GraphQL */ `
  subscription OnCreateResourceUsageByGroup($group: String!) {
    onCreateResourceUsageByGroup(group: $group) {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateResourceUsageByGroup = /* GraphQL */ `
  subscription OnUpdateResourceUsageByGroup($group: String!) {
    onUpdateResourceUsageByGroup(group: $group) {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteResourceUsageByGroup = /* GraphQL */ `
  subscription OnDeleteResourceUsageByGroup($group: String!) {
    onDeleteResourceUsageByGroup(group: $group) {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRosterChecklistSubjectItemByGroup = /* GraphQL */ `
  subscription OnCreateRosterChecklistSubjectItemByGroup($group: String!) {
    onCreateRosterChecklistSubjectItemByGroup(group: $group) {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateRosterChecklistSubjectItemByGroup = /* GraphQL */ `
  subscription OnUpdateRosterChecklistSubjectItemByGroup($group: String!) {
    onUpdateRosterChecklistSubjectItemByGroup(group: $group) {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteRosterChecklistSubjectItemByGroup = /* GraphQL */ `
  subscription OnDeleteRosterChecklistSubjectItemByGroup($group: String!) {
    onDeleteRosterChecklistSubjectItemByGroup(group: $group) {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateCard = /* GraphQL */ `
  subscription OnCreateCard {
    onCreateCard {
      id
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCard = /* GraphQL */ `
  subscription OnUpdateCard {
    onUpdateCard {
      id
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCard = /* GraphQL */ `
  subscription OnDeleteCard {
    onDeleteCard {
      id
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateCompanyScoreCard = /* GraphQL */ `
  subscription OnCreateCompanyScoreCard {
    onCreateCompanyScoreCard {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompanyScoreCard = /* GraphQL */ `
  subscription OnUpdateCompanyScoreCard {
    onUpdateCompanyScoreCard {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompanyScoreCard = /* GraphQL */ `
  subscription OnDeleteCompanyScoreCard {
    onDeleteCompanyScoreCard {
      id
      group
      week
      year
      yearWeek
      overall
      safetyAndCompliance
      comprehensiveAudit
      safetyScore
      safeDriving
      safeDrivingText
      fico
      seatbeltOff
      seatbeltOffText
      speedingEvent
      speedingEventText
      dvcrCompliance
      dvcrComplianceText
      onTimeCompliance
      onTimeComplianceText
      complianceScoreText
      workingHoursCompliance
      workingHoursComplianceText
      dspAudit
      dspAuditText
      thirtyDaysNoticeText
      customerEscalationDefectDPMO
      customerEscalationDefectDPMOText
      team
      highPerformersShare
      highPerformersShareText
      lowPerformersShare
      lowPerformersShareText
      attritionRate
      attritionRateText
      quality
      deliveryCompletion
      deliveryCompletionText
      deliveredAndReceived
      deliveredAndReceivedText
      standardWorkComplianceText
      photoOnDelivery
      photoOnDeliveryText
      contactCompliance
      contactComplianceText
      scanCompliance
      scanComplianceText
      attendedDeliveryAccuracy
      attendedDeliveryAccuracyText
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      distractionsRateText
      followingDistanceRateText
      signSignalViolationsRateText
      scorecardPdf
      podQualityPdf
      customerFeedbackPdf
      ppsCsv
      harshBrakingEvent
      harshCorneringEvent
      harshBrakingEventText
      harshCorneringEventText
      deliverySlotPerformance
      deliverySlotPerformanceText
      tenantId
      customerDeliveryExperience
      customerDeliveryFeedback
      customerDeliveryFeedbackText
      cdfDpmo
      cdfDpmoText
      breachOfContract
      tenuredWorkforce
      tenuredWorkforceText
      deliverySuccessBehaviors
      deliverySuccessBehaviorsText
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCounselingTemplate = /* GraphQL */ `
  subscription OnCreateCounselingTemplate {
    onCreateCounselingTemplate {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCounselingTemplate = /* GraphQL */ `
  subscription OnUpdateCounselingTemplate {
    onUpdateCounselingTemplate {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCounselingTemplate = /* GraphQL */ `
  subscription OnDeleteCounselingTemplate {
    onDeleteCounselingTemplate {
      id
      group
      templateName
      counselingNotes
      priorDiscussionSummary
      correctiveActionSummary
      consequencesOfFailure
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessageTemplate = /* GraphQL */ `
  subscription OnCreateMessageTemplate {
    onCreateMessageTemplate {
      id
      group
      name
      message
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessageTemplate = /* GraphQL */ `
  subscription OnUpdateMessageTemplate {
    onUpdateMessageTemplate {
      id
      group
      name
      message
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessageTemplate = /* GraphQL */ `
  subscription OnDeleteMessageTemplate {
    onDeleteMessageTemplate {
      id
      group
      name
      message
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRecurringMessages = /* GraphQL */ `
  subscription OnCreateRecurringMessages {
    onCreateRecurringMessages {
      id
      group
      messageName
      messageBody
      sendTime
      channelType
      senderId
      senderName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateRecurringMessages = /* GraphQL */ `
  subscription OnUpdateRecurringMessages {
    onUpdateRecurringMessages {
      id
      group
      messageName
      messageBody
      sendTime
      channelType
      senderId
      senderName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteRecurringMessages = /* GraphQL */ `
  subscription OnDeleteRecurringMessages {
    onDeleteRecurringMessages {
      id
      group
      messageName
      messageBody
      sendTime
      channelType
      senderId
      senderName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateDrugTest = /* GraphQL */ `
  subscription OnCreateDrugTest {
    onCreateDrugTest {
      id
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDrugTest = /* GraphQL */ `
  subscription OnUpdateDrugTest {
    onUpdateDrugTest {
      id
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteDrugTest = /* GraphQL */ `
  subscription OnDeleteDrugTest {
    onDeleteDrugTest {
      id
      group
      date
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateInjury = /* GraphQL */ `
  subscription OnCreateInjury {
    onCreateInjury {
      id
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      completedByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateInjury = /* GraphQL */ `
  subscription OnUpdateInjury {
    onUpdateInjury {
      id
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      completedByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteInjury = /* GraphQL */ `
  subscription OnDeleteInjury {
    onDeleteInjury {
      id
      group
      createdAt
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      updatedAt
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      completedByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreatePendingMessage = /* GraphQL */ `
  subscription OnCreatePendingMessage {
    onCreatePendingMessage {
      id
      group
      createdAt
      staffId
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdatePendingMessage = /* GraphQL */ `
  subscription OnUpdatePendingMessage {
    onUpdatePendingMessage {
      id
      group
      createdAt
      staffId
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeletePendingMessage = /* GraphQL */ `
  subscription OnDeletePendingMessage {
    onDeletePendingMessage {
      id
      group
      createdAt
      staffId
      channelType
      destinationNumber
      destinationEmail
      numberOfCO
      numberOfPR
      numberOfWeeklyCO
      numberOfWeeklyPR
      numberOfDailyCO
      numberOfMentorCO
      numberOfNetradyneCO
      numberOfEocCO
      numberOfDailyPR
      numberOfMentorPR
      numberOfNetradynePR
      numberOfEocPR
      bodyText
      bodyTextCO
      bodyTextPR
      bodyTextDailyCO
      bodyTextDailyPR
      bodyTextMentorCO
      bodyTextMentorPR
      bodyTextNetradyneCO
      bodyTextNetradynePR
      bodyTextEocCO
      bodyTextEocPR
      bodyTextWeeklyCO
      bodyTextWeeklyPR
      subject
      bodyHtml
      importId
      smsSendInformation
      emailSendInformation
      isRead
      startLastWeeklyImport
      endLastWeeklyImport
      lastDailyImport
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateReplacedByStandbyByRoute = /* GraphQL */ `
  subscription OnCreateReplacedByStandbyByRoute {
    onCreateReplacedByStandbyByRoute {
      id
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateReplacedByStandbyByRoute = /* GraphQL */ `
  subscription OnUpdateReplacedByStandbyByRoute {
    onUpdateReplacedByStandbyByRoute {
      id
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteReplacedByStandbyByRoute = /* GraphQL */ `
  subscription OnDeleteReplacedByStandbyByRoute {
    onDeleteReplacedByStandbyByRoute {
      id
      group
      createdAt
      routeNumber
      standby
      time
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateSystem = /* GraphQL */ `
  subscription OnCreateSystem {
    onCreateSystem {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSystem = /* GraphQL */ `
  subscription OnUpdateSystem {
    onUpdateSystem {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSystem = /* GraphQL */ `
  subscription OnDeleteSystem {
    onDeleteSystem {
      id
      group
      defaultCostStandard
      defaultCostBundle
      defaultCostPerformance
      defaultCostRostering
      defaultCostStaff
      defaultCostVehicles
      importDisabled
      importPodDisabled
      importCxDisabled
      importPpsDisabled
      importMentorDisabled
      importNetradyneDisabled
      importEocDisabled
      isZohoCrmDisabled
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDailyRostersColumns = /* GraphQL */ `
  subscription OnCreateDailyRostersColumns {
    onCreateDailyRostersColumns {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDailyRostersColumns = /* GraphQL */ `
  subscription OnUpdateDailyRostersColumns {
    onUpdateDailyRostersColumns {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDailyRostersColumns = /* GraphQL */ `
  subscription OnDeleteDailyRostersColumns {
    onDeleteDailyRostersColumns {
      id
      group
      order
      label
      isFixed
      isDisplayed
      displayedFor
      btnEditCustomList
      customListType
      isCustomizable
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTextractJob = /* GraphQL */ `
  subscription OnCreateTextractJob($owner: String) {
    onCreateTextractJob(owner: $owner) {
      id
      group
      owner
      userId
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      cxFeedbackSummary {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      properParkingSequenceSummary {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateTextractJob = /* GraphQL */ `
  subscription OnUpdateTextractJob($owner: String) {
    onUpdateTextractJob(owner: $owner) {
      id
      group
      owner
      userId
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      cxFeedbackSummary {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      properParkingSequenceSummary {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteTextractJob = /* GraphQL */ `
  subscription OnDeleteTextractJob($owner: String) {
    onDeleteTextractJob(owner: $owner) {
      id
      group
      owner
      userId
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      tenantUsesXL
      tenantUsesXLS
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      podQualitySummary {
        id
        group
        week
        year
        yearWeek
        success
        bypass
        rejects
        opportunities
        noPackageDetected
        packageNotClearlyVisible
        blurryPhoto
        packageTooClose
        humanInThePicture
        packageInHand
        photoTooDark
        packageInCar
        other
        grandTotal
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      cxFeedbackSummary {
        id
        group
        week
        year
        yearWeek
        dcfTier
        dcfScore
        positiveFeedback
        negativeFeedback
        deliveriesWithoutCF
        deliveryWasGreat
        respectfulOfProperty
        followedInstructions
        friendly
        aboveAndBeyond
        deliveredWithCare
        thankMyDriver
        deliveryWasntGreat
        mishandledPackage
        driverUnprofessional
        driverDidNotFollowDeliveryInstructions
        onTime
        lateDelivery
        itemDamaged
        deliveredToWrongAddress
        neverReceivedDelivery
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      properParkingSequenceSummary {
        id
        group
        week
        year
        yearWeek
        evaluatedStops
        compliance
        percentCompliance
        invalid
        percentInvalid
        missingGearInPark
        percentMissingGearInPark
        missingParkingBrake
        percentMissingParkingBrake
        totalBreaches
        percentTotalBreaches
        textractJob {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreatePodQualitySummary = /* GraphQL */ `
  subscription OnCreatePodQualitySummary {
    onCreatePodQualitySummary {
      id
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePodQualitySummary = /* GraphQL */ `
  subscription OnUpdatePodQualitySummary {
    onUpdatePodQualitySummary {
      id
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePodQualitySummary = /* GraphQL */ `
  subscription OnDeletePodQualitySummary {
    onDeletePodQualitySummary {
      id
      group
      week
      year
      yearWeek
      success
      bypass
      rejects
      opportunities
      noPackageDetected
      packageNotClearlyVisible
      blurryPhoto
      packageTooClose
      humanInThePicture
      packageInHand
      photoTooDark
      packageInCar
      other
      grandTotal
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCxFeedbackSummary = /* GraphQL */ `
  subscription OnCreateCxFeedbackSummary {
    onCreateCxFeedbackSummary {
      id
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCxFeedbackSummary = /* GraphQL */ `
  subscription OnUpdateCxFeedbackSummary {
    onUpdateCxFeedbackSummary {
      id
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCxFeedbackSummary = /* GraphQL */ `
  subscription OnDeleteCxFeedbackSummary {
    onDeleteCxFeedbackSummary {
      id
      group
      week
      year
      yearWeek
      dcfTier
      dcfScore
      positiveFeedback
      negativeFeedback
      deliveriesWithoutCF
      deliveryWasGreat
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      deliveryWasntGreat
      mishandledPackage
      driverUnprofessional
      driverDidNotFollowDeliveryInstructions
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWave = /* GraphQL */ `
  subscription OnCreateWave {
    onCreateWave {
      id
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateWave = /* GraphQL */ `
  subscription OnUpdateWave {
    onUpdateWave {
      id
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteWave = /* GraphQL */ `
  subscription OnDeleteWave {
    onDeleteWave {
      id
      group
      startTime
      endTime
      waveName
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRouteStaffHelper = /* GraphQL */ `
  subscription OnCreateRouteStaffHelper {
    onCreateRouteStaffHelper {
      id
      group
      routeId
      staffId
      startTime
      endTime
      status
      daIssueCreated
      counselingCreated
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateRouteStaffHelper = /* GraphQL */ `
  subscription OnUpdateRouteStaffHelper {
    onUpdateRouteStaffHelper {
      id
      group
      routeId
      staffId
      startTime
      endTime
      status
      daIssueCreated
      counselingCreated
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteRouteStaffHelper = /* GraphQL */ `
  subscription OnDeleteRouteStaffHelper {
    onDeleteRouteStaffHelper {
      id
      group
      routeId
      staffId
      startTime
      endTime
      status
      daIssueCreated
      counselingCreated
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStripeQueue = /* GraphQL */ `
  subscription OnCreateStripeQueue($owner: String!) {
    onCreateStripeQueue(owner: $owner) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateStripeQueue = /* GraphQL */ `
  subscription OnUpdateStripeQueue($owner: String!) {
    onUpdateStripeQueue(owner: $owner) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteStripeQueue = /* GraphQL */ `
  subscription OnDeleteStripeQueue($owner: String!) {
    onDeleteStripeQueue(owner: $owner) {
      id
      group
      tenant
      result
      invoiceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateAuditLogArchive = /* GraphQL */ `
  subscription OnCreateAuditLogArchive {
    onCreateAuditLogArchive {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      createdAt
      auditLogCreatedAt
      ipAddress
      pageUrl
      userType
      updatedAt
    }
  }
`;
export const onUpdateAuditLogArchive = /* GraphQL */ `
  subscription OnUpdateAuditLogArchive {
    onUpdateAuditLogArchive {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      createdAt
      auditLogCreatedAt
      ipAddress
      pageUrl
      userType
      updatedAt
    }
  }
`;
export const onDeleteAuditLogArchive = /* GraphQL */ `
  subscription OnDeleteAuditLogArchive {
    onDeleteAuditLogArchive {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      createdAt
      auditLogCreatedAt
      ipAddress
      pageUrl
      userType
      updatedAt
    }
  }
`;
export const onCreateNickname = /* GraphQL */ `
  subscription OnCreateNickname {
    onCreateNickname {
      id
      name
      nicknames
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNickname = /* GraphQL */ `
  subscription OnUpdateNickname {
    onUpdateNickname {
      id
      name
      nicknames
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNickname = /* GraphQL */ `
  subscription OnDeleteNickname {
    onDeleteNickname {
      id
      name
      nicknames
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAccident = /* GraphQL */ `
  subscription OnCreateAccident {
    onCreateAccident {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      incidentDocumentConnections {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamageRoute {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      maintenanceVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      damageVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      odometerReadingVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateAccident = /* GraphQL */ `
  subscription OnUpdateAccident {
    onUpdateAccident {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      incidentDocumentConnections {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamageRoute {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      maintenanceVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      damageVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      odometerReadingVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteAccident = /* GraphQL */ `
  subscription OnDeleteAccident {
    onDeleteAccident {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      incidentDocumentConnections {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamageRoute {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      maintenanceVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      damageVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      odometerReadingVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment {
    onCreateAttachment {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      message {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment {
    onUpdateAttachment {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      message {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment {
    onDeleteAttachment {
      id
      group
      createdAt
      s3Key
      expirationDate
      contentType
      fileSize
      updatedAt
      shortenUrl {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      message {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateEocScore = /* GraphQL */ `
  subscription OnCreateEocScore {
    onCreateEocScore {
      id
      group
      date
      level
      average
      averageDailyCompliance
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateEocScore = /* GraphQL */ `
  subscription OnUpdateEocScore {
    onUpdateEocScore {
      id
      group
      date
      level
      average
      averageDailyCompliance
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteEocScore = /* GraphQL */ `
  subscription OnDeleteEocScore {
    onDeleteEocScore {
      id
      group
      date
      level
      average
      averageDailyCompliance
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateCounseling = /* GraphQL */ `
  subscription OnCreateCounseling {
    onCreateCounseling {
      id
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      severity {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCounseling = /* GraphQL */ `
  subscription OnUpdateCounseling {
    onUpdateCounseling {
      id
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      severity {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCounseling = /* GraphQL */ `
  subscription OnDeleteCounseling {
    onDeleteCounseling {
      id
      group
      date
      refusedToSign
      refusedByHeraUserId
      refusedToSignDateTime
      refusedByHeraUserExplanation
      signatureAcknowledge
      signature
      counselingNotes
      internalStaffNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      createdFrom
      createdAt
      updatedAt
      images {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      severity {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateShortenUrl = /* GraphQL */ `
  subscription OnCreateShortenUrl {
    onCreateShortenUrl {
      id
      group
      type
      shortenId
      expirationTTL
      urlToken
      attachmentId
      rosterChecklistSubjectId
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateShortenUrl = /* GraphQL */ `
  subscription OnUpdateShortenUrl {
    onUpdateShortenUrl {
      id
      group
      type
      shortenId
      expirationTTL
      urlToken
      attachmentId
      rosterChecklistSubjectId
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteShortenUrl = /* GraphQL */ `
  subscription OnDeleteShortenUrl {
    onDeleteShortenUrl {
      id
      group
      type
      shortenId
      expirationTTL
      urlToken
      attachmentId
      rosterChecklistSubjectId
      isOpenLink
      urlParameters
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateDailyRoster = /* GraphQL */ `
  subscription OnCreateDailyRoster {
    onCreateDailyRoster {
      id
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      notes {
        items {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDailyRoster = /* GraphQL */ `
  subscription OnUpdateDailyRoster {
    onUpdateDailyRoster {
      id
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      notes {
        items {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteDailyRoster = /* GraphQL */ `
  subscription OnDeleteDailyRoster {
    onDeleteDailyRoster {
      id
      group
      notesDate
      lastStandUpSentTime
      userId
      creationRoute
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      notes {
        items {
          id
          group
          type
          date
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSchedulerTask = /* GraphQL */ `
  subscription OnCreateSchedulerTask {
    onCreateSchedulerTask {
      id
      type
      apiEndpoint
      apiStage
      apiPath
      lambdaName
      group
      taskPayload
      scheduledDate
      executionId
      initiated
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSchedulerTask = /* GraphQL */ `
  subscription OnUpdateSchedulerTask {
    onUpdateSchedulerTask {
      id
      type
      apiEndpoint
      apiStage
      apiPath
      lambdaName
      group
      taskPayload
      scheduledDate
      executionId
      initiated
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSchedulerTask = /* GraphQL */ `
  subscription OnDeleteSchedulerTask {
    onDeleteSchedulerTask {
      id
      type
      apiEndpoint
      apiStage
      apiPath
      lambdaName
      group
      taskPayload
      scheduledDate
      executionId
      initiated
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRosterChecklist = /* GraphQL */ `
  subscription OnCreateRosterChecklist {
    onCreateRosterChecklist {
      id
      name
      completionFrequencyId
      assignedToId
      group
      tenantId
      sendTime
      totalSimpleItems
      totalVehicleItems
      status
      createdAt
      updatedAt
      vehicleTypes {
        items {
          id
          vehicleTypeId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedUsers {
        items {
          id
          userId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      checklistTasks {
        items {
          id
          type
          taskId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistItems {
        items {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      completionFrequency {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      assignedTo {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateRosterChecklist = /* GraphQL */ `
  subscription OnUpdateRosterChecklist {
    onUpdateRosterChecklist {
      id
      name
      completionFrequencyId
      assignedToId
      group
      tenantId
      sendTime
      totalSimpleItems
      totalVehicleItems
      status
      createdAt
      updatedAt
      vehicleTypes {
        items {
          id
          vehicleTypeId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedUsers {
        items {
          id
          userId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      checklistTasks {
        items {
          id
          type
          taskId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistItems {
        items {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      completionFrequency {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      assignedTo {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteRosterChecklist = /* GraphQL */ `
  subscription OnDeleteRosterChecklist {
    onDeleteRosterChecklist {
      id
      name
      completionFrequencyId
      assignedToId
      group
      tenantId
      sendTime
      totalSimpleItems
      totalVehicleItems
      status
      createdAt
      updatedAt
      vehicleTypes {
        items {
          id
          vehicleTypeId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedUsers {
        items {
          id
          userId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      checklistTasks {
        items {
          id
          type
          taskId
          rosterChecklistId
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistItems {
        items {
          id
          typeId
          titleQuestion
          descriptionExplanation
          order
          group
          status
          rosterChecklistId
          createdAt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      completionFrequency {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      assignedTo {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateRosterChecklistVehicleType = /* GraphQL */ `
  subscription OnCreateRosterChecklistVehicleType {
    onCreateRosterChecklistVehicleType {
      id
      vehicleTypeId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      VehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateRosterChecklistVehicleType = /* GraphQL */ `
  subscription OnUpdateRosterChecklistVehicleType {
    onUpdateRosterChecklistVehicleType {
      id
      vehicleTypeId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      VehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteRosterChecklistVehicleType = /* GraphQL */ `
  subscription OnDeleteRosterChecklistVehicleType {
    onDeleteRosterChecklistVehicleType {
      id
      vehicleTypeId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      VehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateRosterChecklistUser = /* GraphQL */ `
  subscription OnCreateRosterChecklistUser {
    onCreateRosterChecklistUser {
      id
      userId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateRosterChecklistUser = /* GraphQL */ `
  subscription OnUpdateRosterChecklistUser {
    onUpdateRosterChecklistUser {
      id
      userId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteRosterChecklistUser = /* GraphQL */ `
  subscription OnDeleteRosterChecklistUser {
    onDeleteRosterChecklistUser {
      id
      userId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRosterChecklistTask = /* GraphQL */ `
  subscription OnCreateRosterChecklistTask {
    onCreateRosterChecklistTask {
      id
      type
      taskId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      task {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateRosterChecklistTask = /* GraphQL */ `
  subscription OnUpdateRosterChecklistTask {
    onUpdateRosterChecklistTask {
      id
      type
      taskId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      task {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteRosterChecklistTask = /* GraphQL */ `
  subscription OnDeleteRosterChecklistTask {
    onDeleteRosterChecklistTask {
      id
      type
      taskId
      rosterChecklistId
      group
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      task {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateRosterChecklistItem = /* GraphQL */ `
  subscription OnCreateRosterChecklistItem {
    onCreateRosterChecklistItem {
      id
      typeId
      titleQuestion
      descriptionExplanation
      order
      group
      status
      rosterChecklistId
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateRosterChecklistItem = /* GraphQL */ `
  subscription OnUpdateRosterChecklistItem {
    onUpdateRosterChecklistItem {
      id
      typeId
      titleQuestion
      descriptionExplanation
      order
      group
      status
      rosterChecklistId
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteRosterChecklistItem = /* GraphQL */ `
  subscription OnDeleteRosterChecklistItem {
    onDeleteRosterChecklistItem {
      id
      typeId
      titleQuestion
      descriptionExplanation
      order
      group
      status
      rosterChecklistId
      createdAt
      updatedAt
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      group
      deviceName
      phoneNumber
      carrier
      status
      imei
      notes
      createdAt
      updatedAt
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route2 {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicle2 {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const onCreateDailyLog = /* GraphQL */ `
  subscription OnCreateDailyLog {
    onCreateDailyLog {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDailyLog = /* GraphQL */ `
  subscription OnUpdateDailyLog {
    onUpdateDailyLog {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteDailyLog = /* GraphQL */ `
  subscription OnDeleteDailyLog {
    onDeleteDailyLog {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      dailyLogCreationLinkSentByUserId
      dailyLogCreationLinkAssociateId
      dailyLogTakenByUserId
      dailyLogTakenByAssociateId
      dailyLogRosteredDayId
      dailyLogVehicleId
      dailyLogCreatedByUserId
      specificPhotos
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      rosteredDay {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      history {
        items {
          id
          date
          group
          previousValue
          currentValue
          dailyLogId
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      creationLinkAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      creationLinkSentByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      createdByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateDailyLogHistory = /* GraphQL */ `
  subscription OnCreateDailyLogHistory {
    onCreateDailyLogHistory {
      id
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateDailyLogHistory = /* GraphQL */ `
  subscription OnUpdateDailyLogHistory {
    onUpdateDailyLogHistory {
      id
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteDailyLogHistory = /* GraphQL */ `
  subscription OnDeleteDailyLogHistory {
    onDeleteDailyLogHistory {
      id
      date
      group
      previousValue
      currentValue
      dailyLogId
      createdAt
      updatedAt
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument {
    onCreateDocument {
      id
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      aiSubmissionDateTime
      aiResponseDateTime
      aiResponse
      aiResponseStatus
      aiIssues
      aiResponseAcknowledgedDateTime
      aiResponseFeedback
      aiDeterminedOdometerReadingValue
      aiResponseAcknowledgedBy
      injury {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imagevehicleDamage {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      maintenance {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imageCounseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentRecords {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      infraction {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomLists {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument {
    onUpdateDocument {
      id
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      aiSubmissionDateTime
      aiResponseDateTime
      aiResponse
      aiResponseStatus
      aiIssues
      aiResponseAcknowledgedDateTime
      aiResponseFeedback
      aiDeterminedOdometerReadingValue
      aiResponseAcknowledgedBy
      injury {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imagevehicleDamage {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      maintenance {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imageCounseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentRecords {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      infraction {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomLists {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument {
    onDeleteDocument {
      id
      group
      name
      key
      type
      uploadDate
      notes
      documentDate
      isDocVisibleToAssociate
      aiSubmissionDateTime
      aiResponseDateTime
      aiResponse
      aiResponseStatus
      aiIssues
      aiResponseAcknowledgedDateTime
      aiResponseFeedback
      aiDeterminedOdometerReadingValue
      aiResponseAcknowledgedBy
      injury {
        id
        group
        createdAt
        caseNumber
        injuryDate
        injuryTime
        injuryTimeIsUnknown
        timeStaffStartedWork
        completedBy
        completedByTitle
        completedByPhone
        driverHireDate
        driverDOB
        driverGender
        driverAddress
        driverCity
        driverState
        driverZip
        physicianName
        physicianFacility
        physicianAddress
        physicianCity
        physicianState
        physicianZip
        wasTreatedInER
        wasHospitalizedOvernight
        descriptionBeforeAccident
        descriptionInjury
        descriptionIncident
        descriptionDirectHarmCause
        injuryType
        notes
        dateOfDeath
        caseNumberFromLog
        staffId
        updatedAt
        documents {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        completedByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imagevehicleDamage {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      maintenance {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      imageCounseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentRecords {
        items {
          id
          documentID
          group
          createdByDocument
          incidentID
          createdAt
          updatedAt
        }
        nextToken
      }
      infraction {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        infractionTypeId
        createdFrom
        createdAt
        updatedAt
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        documents {
          nextToken
          scannedCount
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      takenByAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomLists {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      takenByUser {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateDocumentConnections = /* GraphQL */ `
  subscription OnCreateDocumentConnections {
    onCreateDocumentConnections {
      id
      documentID
      group
      createdByDocument
      incidentID
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentDocument {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateDocumentConnections = /* GraphQL */ `
  subscription OnUpdateDocumentConnections {
    onUpdateDocumentConnections {
      id
      documentID
      group
      createdByDocument
      incidentID
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentDocument {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteDocumentConnections = /* GraphQL */ `
  subscription OnDeleteDocumentConnections {
    onDeleteDocumentConnections {
      id
      documentID
      group
      createdByDocument
      incidentID
      createdAt
      updatedAt
      incident {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      incidentDocument {
        id
        group
        name
        key
        type
        uploadDate
        notes
        documentDate
        isDocVisibleToAssociate
        aiSubmissionDateTime
        aiResponseDateTime
        aiResponse
        aiResponseStatus
        aiIssues
        aiResponseAcknowledgedDateTime
        aiResponseFeedback
        aiDeterminedOdometerReadingValue
        aiResponseAcknowledgedBy
        injury {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        createdAt
        updatedAt
        incident {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imagevehicleDamage {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        maintenance {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        imageCounseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLog {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        incidentRecords {
          nextToken
        }
        infraction {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomLists {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateInfraction = /* GraphQL */ `
  subscription OnCreateInfraction {
    onCreateInfraction {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateInfraction = /* GraphQL */ `
  subscription OnUpdateInfraction {
    onUpdateInfraction {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteInfraction = /* GraphQL */ `
  subscription OnDeleteInfraction {
    onDeleteInfraction {
      id
      group
      infractionType
      comment
      date
      time
      staffId
      station
      trackingNumber
      infractionTier
      infractionDescription
      appealDate
      resolvedDate
      appealStatus
      appealNotes
      miscNotes
      infractionTypeId
      createdFrom
      createdAt
      updatedAt
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice {
    onCreateInvoice {
      id
      createdAt
      group
      month
      year
      invoiceTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice {
    onUpdateInvoice {
      id
      createdAt
      group
      month
      year
      invoiceTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice {
    onDeleteInvoice {
      id
      createdAt
      group
      month
      year
      invoiceTotal
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      averageActiveDriverCount
      status
      html
      cardLastFourCharged
      updatedAt
      invoiceLineItems {
        items {
          id
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateInvoiceLineItem = /* GraphQL */ `
  subscription OnCreateInvoiceLineItem {
    onCreateInvoiceLineItem {
      id
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateInvoiceLineItem = /* GraphQL */ `
  subscription OnUpdateInvoiceLineItem {
    onUpdateInvoiceLineItem {
      id
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteInvoiceLineItem = /* GraphQL */ `
  subscription OnDeleteInvoiceLineItem {
    onDeleteInvoiceLineItem {
      id
      createdAt
      group
      date
      month
      year
      day
      isTrial
      activeStaff
      standardCost
      standardCostExt
      bundleCost
      bundleCostExt
      performanceCost
      performanceCostExt
      rosteringCost
      rosteringCostExt
      staffCost
      staffCostExt
      vehiclesCost
      vehiclesCostExt
      messagingCost
      messagingCostExt
      updatedAt
      invoice {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
        invoiceLineItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
    }
  }
`;
export const onCreateKudo = /* GraphQL */ `
  subscription OnCreateKudo {
    onCreateKudo {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateKudo = /* GraphQL */ `
  subscription OnUpdateKudo {
    onUpdateKudo {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteKudo = /* GraphQL */ `
  subscription OnDeleteKudo {
    onDeleteKudo {
      id
      group
      kudoType
      notes
      date
      time
      staffId
      createdFrom
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      type {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($owner: String) {
    onCreateMessage(owner: $owner) {
      id
      carrierMessageId
      group
      createdAt
      staffId
      userId
      messageType
      channelType
      destinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      smsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      senderId
      owner
      destinationName
      hasParts
      rosterChecklistSubjectId
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($owner: String) {
    onUpdateMessage(owner: $owner) {
      id
      carrierMessageId
      group
      createdAt
      staffId
      userId
      messageType
      channelType
      destinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      smsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      senderId
      owner
      destinationName
      hasParts
      rosterChecklistSubjectId
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($owner: String) {
    onDeleteMessage(owner: $owner) {
      id
      carrierMessageId
      group
      createdAt
      staffId
      userId
      messageType
      channelType
      destinationNumber
      destinationEmail
      subject
      bodyText
      bodyHtml
      senderName
      smsStatus
      smsSendInformation
      emailStatus
      emailSendInformation
      isReadS
      sentBy
      sentAt
      linkExpiryDate
      attachmentLink
      contentType
      senderId
      owner
      destinationName
      hasParts
      rosterChecklistSubjectId
      updatedAt
      attachment {
        id
        group
        createdAt
        s3Key
        expirationDate
        contentType
        fileSize
        updatedAt
        shortenUrl {
          nextToken
        }
        dailyRoster {
          nextToken
        }
        message {
          nextToken
        }
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      sender {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      isReadBy {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateResourceUsage = /* GraphQL */ `
  subscription OnCreateResourceUsage {
    onCreateResourceUsage {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateResourceUsage = /* GraphQL */ `
  subscription OnUpdateResourceUsage {
    onUpdateResourceUsage {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteResourceUsage = /* GraphQL */ `
  subscription OnDeleteResourceUsage {
    onDeleteResourceUsage {
      id
      group
      resourceIdentifier
      resourceType
      inUseAsOfDateTime
      userInstanceId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote {
    onCreateNote {
      id
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      vehiclesMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      staffMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateNote = /* GraphQL */ `
  subscription OnUpdateNote {
    onUpdateNote {
      id
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      vehiclesMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      staffMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote {
    onDeleteNote {
      id
      group
      type
      date
      text
      createdAt
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      vehiclesMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      staffMentioned {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateVehicleMention = /* GraphQL */ `
  subscription OnCreateVehicleMention {
    onCreateVehicleMention {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateVehicleMention = /* GraphQL */ `
  subscription OnUpdateVehicleMention {
    onUpdateVehicleMention {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteVehicleMention = /* GraphQL */ `
  subscription OnDeleteVehicleMention {
    onDeleteVehicleMention {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStaffMention = /* GraphQL */ `
  subscription OnCreateStaffMention {
    onCreateStaffMention {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateStaffMention = /* GraphQL */ `
  subscription OnUpdateStaffMention {
    onUpdateStaffMention {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteStaffMention = /* GraphQL */ `
  subscription OnDeleteStaffMention {
    onDeleteStaffMention {
      id
      group
      date
      createdAt
      updatedAt
      note {
        id
        group
        type
        date
        text
        createdAt
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        vehiclesMentioned {
          nextToken
        }
        staffMentioned {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($owner: String) {
    onCreateNotification(owner: $owner) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification($owner: String) {
    onUpdateNotification(owner: $owner) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification($owner: String) {
    onDeleteNotification(owner: $owner) {
      id
      group
      owner
      title
      createdAt
      description
      releaseNotes
      payload
      clickAction
      expirationTTL
      isReadS
      updatedAt
    }
  }
`;
export const onCreateCompanyNotification = /* GraphQL */ `
  subscription OnCreateCompanyNotification {
    onCreateCompanyNotification {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompanyNotification = /* GraphQL */ `
  subscription OnUpdateCompanyNotification {
    onUpdateCompanyNotification {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompanyNotification = /* GraphQL */ `
  subscription OnDeleteCompanyNotification {
    onDeleteCompanyNotification {
      id
      group
      streamTable
      affectedTable
      owner
      message
      expirationTTL
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOnBoard = /* GraphQL */ `
  subscription OnCreateOnBoard {
    onCreateOnBoard {
      id
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateOnBoard = /* GraphQL */ `
  subscription OnUpdateOnBoard {
    onUpdateOnBoard {
      id
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteOnBoard = /* GraphQL */ `
  subscription OnDeleteOnBoard {
    onDeleteOnBoard {
      id
      group
      name
      isComplete
      status
      dateComplete
      dateStart
      date3
      date4
      date5
      trainer
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreatePhysical = /* GraphQL */ `
  subscription OnCreatePhysical {
    onCreatePhysical {
      id
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdatePhysical = /* GraphQL */ `
  subscription OnUpdatePhysical {
    onUpdatePhysical {
      id
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeletePhysical = /* GraphQL */ `
  subscription OnDeletePhysical {
    onDeletePhysical {
      id
      group
      date
      expirationDate
      location
      results
      file
      fileName
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreatePodQuality = /* GraphQL */ `
  subscription OnCreatePodQuality {
    onCreatePodQuality {
      id
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdatePodQuality = /* GraphQL */ `
  subscription OnUpdatePodQuality {
    onUpdatePodQuality {
      id
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeletePodQuality = /* GraphQL */ `
  subscription OnDeletePodQuality {
    onDeletePodQuality {
      id
      group
      matched
      matchedS
      week
      year
      employeeName
      transporterId
      blurry
      explicit
      mailSlot
      noPackage
      other
      opportunities
      success
      bypass
      packageInHand
      notClearlyVisible
      packageTooClose
      personInPhoto
      photoTooDark
      takenFromCar
      grandTotal
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateReplaceByRoute = /* GraphQL */ `
  subscription OnCreateReplaceByRoute {
    onCreateReplaceByRoute {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateReplaceByRoute = /* GraphQL */ `
  subscription OnUpdateReplaceByRoute {
    onUpdateReplaceByRoute {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteReplaceByRoute = /* GraphQL */ `
  subscription OnDeleteReplaceByRoute {
    onDeleteReplaceByRoute {
      id
      group
      notes
      createdAt
      replaceByRouteParkingSpaceId
      routeNumber
      staging
      replaceByRouteStatusId
      helperStatus
      isNotActive
      standby
      time
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replaceByRouteId
      replaceByRouteDailyRosterId
      replaceByRouteDeviceId
      replaceByRouteStaffId
      replaceByRouteVehicleId
      updatedAt
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      replaceByRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRoute = /* GraphQL */ `
  subscription OnCreateRoute {
    onCreateRoute {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateRoute = /* GraphQL */ `
  subscription OnUpdateRoute {
    onUpdateRoute {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteRoute = /* GraphQL */ `
  subscription OnDeleteRoute {
    onDeleteRoute {
      id
      group
      notes
      routeNumber
      staging
      previousStatus
      statusChangedTime
      standby
      time
      isNotActive
      messageSentTime
      messageSentError
      receivedAnnouncement
      rescued
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      routeDailyRosterId
      routeDeviceId
      routeStaffId
      helpers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          status
          daIssueCreated
          counselingCreated
          createdAt
          updatedAt
        }
        nextToken
      }
      helperUpdatedDateTime
      routeVehicleId
      totalStops
      totalPackages
      completedStops
      completedPackages
      undeliveredPackagesTotal
      undeliveredPackagesBusinessClose
      undeliveredPackagesUnableToAccess
      undeliveredPackagesOtherAWS
      pickUpReturnPackage
      additionalPackagesFromRescue
      splitWithRosterAssignment
      additionalPackagesFromSplit
      firstStopTime
      lastStopTime
      daWorkStartTime
      daWorkEndTime
      daRouteStartTime
      daRouteEndTime
      rtsTime
      lunchStartTime
      lunchEndTime
      inspectionFueled
      inspectionCleaned
      inspectionFlashers
      inspectionCreditCard
      inspectionKeys
      inspectionDeviceCharger
      inspectionDeviceBattery
      inspectionNotes
      requiredVehicleTypeString
      expectedDurationHrs
      createdAt
      updatedAt
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      document {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      helper {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rescuer {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      rosterChecklistSubjects {
        items {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      status {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      helperStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      rescuers {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateLabelType = /* GraphQL */ `
  subscription OnCreateLabelType {
    onCreateLabelType {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateLabelType = /* GraphQL */ `
  subscription OnUpdateLabelType {
    onUpdateLabelType {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteLabelType = /* GraphQL */ `
  subscription OnDeleteLabelType {
    onDeleteLabelType {
      id
      group
      name
      createdAt
      updatedAt
      labelList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateLabelTypeList = /* GraphQL */ `
  subscription OnCreateLabelTypeList {
    onCreateLabelTypeList {
      id
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateLabelTypeList = /* GraphQL */ `
  subscription OnUpdateLabelTypeList {
    onUpdateLabelTypeList {
      id
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteLabelTypeList = /* GraphQL */ `
  subscription OnDeleteLabelTypeList {
    onDeleteLabelTypeList {
      id
      group
      createdAt
      updatedAt
      type {
        id
        group
        name
        createdAt
        updatedAt
        labelList {
          nextToken
        }
      }
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const onCreateLabel = /* GraphQL */ `
  subscription OnCreateLabel {
    onCreateLabel {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateLabel = /* GraphQL */ `
  subscription OnUpdateLabel {
    onUpdateLabel {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteLabel = /* GraphQL */ `
  subscription OnDeleteLabel {
    onDeleteLabel {
      id
      group
      name
      status
      createdAt
      updatedAt
      typeList {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateLabelSubscription = /* GraphQL */ `
  subscription OnCreateLabelSubscription {
    onCreateLabelSubscription {
      id
      group
      labelId
      staffId
      vehicleId
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateLabelSubscription = /* GraphQL */ `
  subscription OnUpdateLabelSubscription {
    onUpdateLabelSubscription {
      id
      group
      labelId
      staffId
      vehicleId
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteLabelSubscription = /* GraphQL */ `
  subscription OnDeleteLabelSubscription {
    onDeleteLabelSubscription {
      id
      group
      labelId
      staffId
      vehicleId
      createdAt
      updatedAt
      label {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          nextToken
        }
        items {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStaff = /* GraphQL */ `
  subscription OnCreateStaff {
    onCreateStaff {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateStaff = /* GraphQL */ `
  subscription OnUpdateStaff {
    onUpdateStaff {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteStaff = /* GraphQL */ `
  subscription OnDeleteStaff {
    onDeleteStaff {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      keyFocusArea
      keyFocusAreaCompleted
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      smsLastMessageTimestamp
      smsLastMessage
      vehicleType
      photo
      onboardingPinned
      netradyneDriverId
      heraScore
      heraRank
      prevHeraRank
      replacedByStandbyByRoute {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      drugTests {
        items {
          id
          group
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveTextMessagesChangeLog
      receiveEmailMessages
      receiveEmailMessagesChangeLog
      injuries {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      notes
      onboardingNotes
      customDeliveryVan
      authorizedLBS
      preferredDaysOff
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      eocScores {
        items {
          id
          group
          date
          level
          average
          averageDailyCompliance
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      infractions {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelper {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeRescuer {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagePreferencesHistory {
        items {
          id
          group
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          messageHasBeenSent
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          noFeedback
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          daTier
          cdfScore
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          matched
          messageHasBeenSent
          date
          station
          trips
          geotabTrips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      netrdadyneAlerts {
        items {
          id
          group
          staffId
          matched
          matchedS
          driverName
          driverId
          groupName
          vehicleNumber
          alertId
          timestamp
          alertType
          alertSeverity
          description
          alertVideoStatus
          duration
          startLatLong
          endLatLong
          sortKey
          date
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          cdf
          cdfDpmo
          ced
          dcr
          dar
          dsb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          harshBrakingRate
          harshCorneringRate
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      routeStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultVehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle2 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      defaultVehicle3 {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
      routeRescuerStaff {
        items {
          id
          group
          routeId
          staffId
          startTime
          endTime
          totalRescuedPackages
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          group
          matched
          matchedS
          messageHasBeenSent
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateMessagePreferencesHistory = /* GraphQL */ `
  subscription OnCreateMessagePreferencesHistory {
    onCreateMessagePreferencesHistory {
      id
      group
      messagePreferenceType
      description
      datetime
      messagePreferencesHistoryStaffId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessagePreferencesHistory = /* GraphQL */ `
  subscription OnUpdateMessagePreferencesHistory {
    onUpdateMessagePreferencesHistory {
      id
      group
      messagePreferenceType
      description
      datetime
      messagePreferencesHistoryStaffId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessagePreferencesHistory = /* GraphQL */ `
  subscription OnDeleteMessagePreferencesHistory {
    onDeleteMessagePreferencesHistory {
      id
      group
      messagePreferenceType
      description
      datetime
      messagePreferencesHistoryStaffId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStaffCxFeedback = /* GraphQL */ `
  subscription OnCreateStaffCxFeedback {
    onCreateStaffCxFeedback {
      id
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateStaffCxFeedback = /* GraphQL */ `
  subscription OnUpdateStaffCxFeedback {
    onUpdateStaffCxFeedback {
      id
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteStaffCxFeedback = /* GraphQL */ `
  subscription OnDeleteStaffCxFeedback {
    onDeleteStaffCxFeedback {
      id
      group
      matched
      matchedS
      week
      year
      name
      messageHasBeenSent
      transporterId
      positiveFeedback
      negativeFeedback
      deliveryWasGreat
      deliveryWasntGreat
      totalDeliveries
      respectfulOfProperty
      followedInstructions
      friendly
      aboveAndBeyond
      deliveredWithCare
      thankMyDriver
      careForOthers
      mishandledPackage
      drivingUnsafely
      driverUnprofessional
      notDeliveredToPreferredLocation
      noFeedback
      onTime
      lateDelivery
      itemDamaged
      deliveredToWrongAddress
      neverReceivedDelivery
      daTier
      cdfScore
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStaffMentor = /* GraphQL */ `
  subscription OnCreateStaffMentor {
    onCreateStaffMentor {
      id
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateStaffMentor = /* GraphQL */ `
  subscription OnUpdateStaffMentor {
    onUpdateStaffMentor {
      id
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteStaffMentor = /* GraphQL */ `
  subscription OnDeleteStaffMentor {
    onDeleteStaffMentor {
      id
      group
      name
      matched
      messageHasBeenSent
      date
      station
      trips
      geotabTrips
      miles
      time
      fico
      accel
      braking
      cornering
      speeding
      distraction
      seatbelt
      backUp
      sse
      mpg
      idling
      engineOff
      preDvcr
      postDvcr
      trainingAssigned
      trainingCompleted
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStaffNetradyneAlert = /* GraphQL */ `
  subscription OnCreateStaffNetradyneAlert {
    onCreateStaffNetradyneAlert {
      id
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateStaffNetradyneAlert = /* GraphQL */ `
  subscription OnUpdateStaffNetradyneAlert {
    onUpdateStaffNetradyneAlert {
      id
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteStaffNetradyneAlert = /* GraphQL */ `
  subscription OnDeleteStaffNetradyneAlert {
    onDeleteStaffNetradyneAlert {
      id
      group
      staffId
      matched
      matchedS
      driverName
      driverId
      groupName
      vehicleNumber
      alertId
      timestamp
      alertType
      alertSeverity
      description
      alertVideoStatus
      duration
      startLatLong
      endLatLong
      sortKey
      date
      textractJobId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStaffScoreCard = /* GraphQL */ `
  subscription OnCreateStaffScoreCard {
    onCreateStaffScoreCard {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateStaffScoreCard = /* GraphQL */ `
  subscription OnUpdateStaffScoreCard {
    onUpdateStaffScoreCard {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteStaffScoreCard = /* GraphQL */ `
  subscription OnDeleteStaffScoreCard {
    onDeleteStaffScoreCard {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      name
      transporterId
      overallTier
      delivered
      keyFocusArea
      ficoScore
      seatbeltOffRate
      cdf
      cdfDpmo
      ced
      dcr
      dar
      dsb
      swcPod
      swcCc
      swcSc
      swcAd
      dnrs
      podOpps
      ccOpps
      speedingEventRate
      distractionsRate
      followingDistanceRate
      signSignalViolationsRate
      harshBrakingRate
      harshCorneringRate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStaffStatus = /* GraphQL */ `
  subscription OnCreateStaffStatus {
    onCreateStaffStatus {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateStaffStatus = /* GraphQL */ `
  subscription OnUpdateStaffStatus {
    onUpdateStaffStatus {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteStaffStatus = /* GraphQL */ `
  subscription OnDeleteStaffStatus {
    onDeleteStaffStatus {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRouteStatus = /* GraphQL */ `
  subscription OnCreateRouteStatus {
    onCreateRouteStatus {
      id
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      associate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      previousStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      currentStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateRouteStatus = /* GraphQL */ `
  subscription OnUpdateRouteStatus {
    onUpdateRouteStatus {
      id
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      associate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      previousStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      currentStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteRouteStatus = /* GraphQL */ `
  subscription OnDeleteRouteStatus {
    onDeleteRouteStatus {
      id
      group
      associateType
      reason
      date
      createdAt
      updatedAt
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      associate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      previousStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      currentStatus {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateRosterChecklistSubject = /* GraphQL */ `
  subscription OnCreateRosterChecklistSubject {
    onCreateRosterChecklistSubject {
      id
      notesDate
      group
      inactive
      staffId
      userId
      rosterChecklistId
      routeId
      replaceByRouteId
      dailyRosterId
      simpleItemsCompleted
      vehicleItemsCompleted
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      sentMessages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubjectItems {
        items {
          id
          group
          rosterChecklistSubjectId
          rosterChecklistItemId
          dailyLogId
          odometerReadingId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateRosterChecklistSubject = /* GraphQL */ `
  subscription OnUpdateRosterChecklistSubject {
    onUpdateRosterChecklistSubject {
      id
      notesDate
      group
      inactive
      staffId
      userId
      rosterChecklistId
      routeId
      replaceByRouteId
      dailyRosterId
      simpleItemsCompleted
      vehicleItemsCompleted
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      sentMessages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubjectItems {
        items {
          id
          group
          rosterChecklistSubjectId
          rosterChecklistItemId
          dailyLogId
          odometerReadingId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteRosterChecklistSubject = /* GraphQL */ `
  subscription OnDeleteRosterChecklistSubject {
    onDeleteRosterChecklistSubject {
      id
      notesDate
      group
      inactive
      staffId
      userId
      rosterChecklistId
      routeId
      replaceByRouteId
      dailyRosterId
      simpleItemsCompleted
      vehicleItemsCompleted
      createdAt
      updatedAt
      shortenUrls {
        items {
          id
          group
          type
          shortenId
          expirationTTL
          urlToken
          attachmentId
          rosterChecklistSubjectId
          isOpenLink
          urlParameters
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyRoster {
        id
        group
        notesDate
        lastStandUpSentTime
        userId
        creationRoute
        replacedByStandbyByRoute {
          nextToken
        }
        createdAt
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        notes {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        rosterChecklistSubjects {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
      }
      rosterChecklist {
        id
        name
        completionFrequencyId
        assignedToId
        group
        tenantId
        sendTime
        totalSimpleItems
        totalVehicleItems
        status
        createdAt
        updatedAt
        vehicleTypes {
          nextToken
        }
        assignedUsers {
          nextToken
        }
        checklistTasks {
          nextToken
        }
        rosterChecklistItems {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        completionFrequency {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        assignedTo {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      sentMessages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      replaceByRoute {
        id
        group
        notes
        createdAt
        replaceByRouteParkingSpaceId
        routeNumber
        staging
        replaceByRouteStatusId
        helperStatus
        isNotActive
        standby
        time
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replaceByRouteId
        replaceByRouteDailyRosterId
        replaceByRouteDeviceId
        replaceByRouteStaffId
        replaceByRouteVehicleId
        updatedAt
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        replaceByRouteStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      rosterChecklistSubjectItems {
        items {
          id
          group
          rosterChecklistSubjectId
          rosterChecklistItemId
          dailyLogId
          odometerReadingId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRosterChecklistSubjectItem = /* GraphQL */ `
  subscription OnCreateRosterChecklistSubjectItem {
    onCreateRosterChecklistSubjectItem {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateRosterChecklistSubjectItem = /* GraphQL */ `
  subscription OnUpdateRosterChecklistSubjectItem {
    onUpdateRosterChecklistSubjectItem {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteRosterChecklistSubjectItem = /* GraphQL */ `
  subscription OnDeleteRosterChecklistSubjectItem {
    onDeleteRosterChecklistSubjectItem {
      id
      group
      rosterChecklistSubjectId
      rosterChecklistItemId
      dailyLogId
      odometerReadingId
      value
      createdAt
      updatedAt
      odometerReading {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistItem {
        id
        typeId
        titleQuestion
        descriptionExplanation
        order
        group
        status
        rosterChecklistId
        createdAt
        updatedAt
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        type {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
      }
      dailyLog {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      rosterChecklistSubject {
        id
        notesDate
        group
        inactive
        staffId
        userId
        rosterChecklistId
        routeId
        replaceByRouteId
        dailyRosterId
        simpleItemsCompleted
        vehicleItemsCompleted
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        rosterChecklist {
          id
          name
          completionFrequencyId
          assignedToId
          group
          tenantId
          sendTime
          totalSimpleItems
          totalVehicleItems
          status
          createdAt
          updatedAt
        }
        sentMessages {
          nextToken
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubjectItems {
          nextToken
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateTask = /* GraphQL */ `
  subscription OnCreateTask($owner: String) {
    onCreateTask(owner: $owner) {
      id
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigneeAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      assigner {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      assignee {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateTask = /* GraphQL */ `
  subscription OnUpdateTask($owner: String) {
    onUpdateTask(owner: $owner) {
      id
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigneeAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      assigner {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      assignee {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteTask = /* GraphQL */ `
  subscription OnDeleteTask($owner: String) {
    onDeleteTask(owner: $owner) {
      id
      createdAt
      owner
      creator
      group
      executionARN
      description
      date
      status
      taskName
      isDateReminder
      isNotifyOnCreation
      reminderDate
      updatedAt
      assigneeAssociate {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      assigner {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      assignee {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateTenant = /* GraphQL */ `
  subscription OnCreateTenant {
    onCreateTenant {
      id
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      group
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      cards {
        items {
          id
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
        }
        nextToken
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          createdAt
          updatedAt
        }
        nextToken
      }
      textractjobs {
        items {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      waves {
        items {
          id
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
        }
        nextToken
      }
      recurringMessage {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      parentAccount {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        nextToken
      }
      valueLists {
        items {
          id
          group
          key
          createdAt
          updatedAt
        }
        nextToken
      }
      premiumStatusHistory {
        items {
          id
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      messageServiceProviderHistory {
        items {
          id
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTenant = /* GraphQL */ `
  subscription OnUpdateTenant {
    onUpdateTenant {
      id
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      group
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      cards {
        items {
          id
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
        }
        nextToken
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          createdAt
          updatedAt
        }
        nextToken
      }
      textractjobs {
        items {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      waves {
        items {
          id
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
        }
        nextToken
      }
      recurringMessage {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      parentAccount {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        nextToken
      }
      valueLists {
        items {
          id
          group
          key
          createdAt
          updatedAt
        }
        nextToken
      }
      premiumStatusHistory {
        items {
          id
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      messageServiceProviderHistory {
        items {
          id
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTenant = /* GraphQL */ `
  subscription OnDeleteTenant {
    onDeleteTenant {
      id
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      group
      zohoCrmAccountRecordId
      originationNumber
      timeZone
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      isTestingAccount
      calculatedOutstandingBalance
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      allowLibraryUpload
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingDsbThresholdIssue
      coachingDsbThreshold
      coachingDsbThresholdPR
      coachingDsbThresholdKudo
      coachingDsbIssue
      coachingDsbCO
      coachingDsbPR
      coachingDsbKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingCdfDpmoThresholdIssue
      coachingCdfDpmoThreshold
      coachingCdfDpmoThresholdPR
      coachingCdfDpmoThresholdKudo
      coachingCdfDpmoIssue
      coachingCdfDpmoKudo
      coachingCdfDpmoRatingIssue
      coachingCdfDpmoCO
      coachingCdfDpmoPR
      coachingCdfDpmoRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingPpsCompliancePercentThresholdIssue
      coachingPpsCompliancePercentThreshold
      coachingPpsCompliancePercentThresholdPR
      coachingPpsCompliancePercentThresholdKudo
      coachingPpsCompliancePercentIssue
      coachingPpsCompliancePercentCO
      coachingPpsCompliancePercentPR
      coachingPpsCompliancePercentKudo
      coachingPpsBreachesThresholdIssue
      coachingPpsBreachesThreshold
      coachingPpsBreachesThresholdPR
      coachingPpsBreachesThresholdKudo
      coachingPpsBreachesIssue
      coachingPpsBreachesCO
      coachingPpsBreachesPR
      coachingPpsBreachesKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      usesXLcoaching
      shortCode
      growSurfParticipantId
      growSurfReferrerEmail
      growSurfReferrerFullName
      growSurfReferredEmail
      growSurfReferredFullName
      growSurfReferralComplete
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      driverReportDayRange
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      allowParentData
      parentAccountId
      permissionHeraAi
      permissionVehiclePhotoLogsHeraAi
      featureAccessVehiclePhotoLogsHeraAi
      featureEnabledVehiclePhotoLogsHeraAi
      featureEnabledInventoryManagement
      featureAccessInventoryManagement
      permissionInventoryManagement
      cards {
        items {
          id
          group
          stripeSetupIntent
          stripeCustomerId
          stripePaymentMethodId
          active
          createdAt
          chargeError
          updatedAt
        }
        nextToken
      }
      companyScoreCardsByYearWeek {
        items {
          id
          group
          week
          year
          yearWeek
          overall
          safetyAndCompliance
          comprehensiveAudit
          safetyScore
          safeDriving
          safeDrivingText
          fico
          seatbeltOff
          seatbeltOffText
          speedingEvent
          speedingEventText
          dvcrCompliance
          dvcrComplianceText
          onTimeCompliance
          onTimeComplianceText
          complianceScoreText
          workingHoursCompliance
          workingHoursComplianceText
          dspAudit
          dspAuditText
          thirtyDaysNoticeText
          customerEscalationDefectDPMO
          customerEscalationDefectDPMOText
          team
          highPerformersShare
          highPerformersShareText
          lowPerformersShare
          lowPerformersShareText
          attritionRate
          attritionRateText
          quality
          deliveryCompletion
          deliveryCompletionText
          deliveredAndReceived
          deliveredAndReceivedText
          standardWorkComplianceText
          photoOnDelivery
          photoOnDeliveryText
          contactCompliance
          contactComplianceText
          scanCompliance
          scanComplianceText
          attendedDeliveryAccuracy
          attendedDeliveryAccuracyText
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          distractionsRateText
          followingDistanceRateText
          signSignalViolationsRateText
          scorecardPdf
          podQualityPdf
          customerFeedbackPdf
          ppsCsv
          harshBrakingEvent
          harshCorneringEvent
          harshBrakingEventText
          harshCorneringEventText
          deliverySlotPerformance
          deliverySlotPerformanceText
          tenantId
          customerDeliveryExperience
          customerDeliveryFeedback
          customerDeliveryFeedbackText
          cdfDpmo
          cdfDpmoText
          breachOfContract
          tenuredWorkforce
          tenuredWorkforceText
          deliverySuccessBehaviors
          deliverySuccessBehaviorsText
          createdAt
          updatedAt
        }
        nextToken
      }
      textractjobs {
        items {
          id
          group
          owner
          userId
          key
          fileName
          week
          year
          template
          jobId
          jobStatus
          type
          isProcessed
          isProcessedS
          results
          summaryTableResults
          timeZone
          tenantUsesXL
          tenantUsesXLS
          customerSubType
          metadataCreationDate
          metadataModificationDate
          metadataYear
          metadataWeek
          scoreCardFailedValidators
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      waves {
        items {
          id
          group
          startTime
          endTime
          waveName
          createdAt
          updatedAt
        }
        nextToken
      }
      recurringMessage {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      dailyRoster {
        items {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          createdAt
          group
          month
          year
          invoiceTotal
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          averageActiveDriverCount
          status
          html
          cardLastFourCharged
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      parentAccount {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        nextToken
      }
      valueLists {
        items {
          id
          group
          key
          createdAt
          updatedAt
        }
        nextToken
      }
      premiumStatusHistory {
        items {
          id
          group
          accountPremiumStatus
          changeNotes
          createdAt
          updatedAt
        }
        nextToken
      }
      messageServiceProviderHistory {
        items {
          id
          group
          date
          previousMessageServicerProvider
          currentMessageServicerProvider
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateCoachingHistory = /* GraphQL */ `
  subscription OnCreateCoachingHistory {
    onCreateCoachingHistory {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoachingHistory = /* GraphQL */ `
  subscription OnUpdateCoachingHistory {
    onUpdateCoachingHistory {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoachingHistory = /* GraphQL */ `
  subscription OnDeleteCoachingHistory {
    onDeleteCoachingHistory {
      id
      group
      type
      importId
      results
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCoachingRecords = /* GraphQL */ `
  subscription OnCreateCoachingRecords {
    onCreateCoachingRecords {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoachingRecords = /* GraphQL */ `
  subscription OnUpdateCoachingRecords {
    onUpdateCoachingRecords {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoachingRecords = /* GraphQL */ `
  subscription OnDeleteCoachingRecords {
    onDeleteCoachingRecords {
      id
      group
      importId
      type
      results
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomLists = /* GraphQL */ `
  subscription OnCreateCustomLists {
    onCreateCustomLists {
      id
      group
      type
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCustomLists = /* GraphQL */ `
  subscription OnUpdateCustomLists {
    onUpdateCustomLists {
      id
      group
      type
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCustomLists = /* GraphQL */ `
  subscription OnDeleteCustomLists {
    onDeleteCustomLists {
      id
      group
      type
      listCategory
      listName
      listDisplay
      canDeleteAllOptions
      createdAt
      updatedAt
      options {
        items {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateOptionsCustomLists = /* GraphQL */ `
  subscription OnCreateOptionsCustomLists {
    onCreateOptionsCustomLists {
      id
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      statusType
      driverReportSetting
      key
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      issues {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRouteParkingSpace {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeParkingSpace {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routes {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelperStatus {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      previousRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      currentRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      customLists {
        id
        group
        type
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      associates {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicles {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      parkingSpace {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      companies {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const onUpdateOptionsCustomLists = /* GraphQL */ `
  subscription OnUpdateOptionsCustomLists {
    onUpdateOptionsCustomLists {
      id
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      statusType
      driverReportSetting
      key
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      issues {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRouteParkingSpace {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeParkingSpace {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routes {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelperStatus {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      previousRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      currentRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      customLists {
        id
        group
        type
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      associates {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicles {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      parkingSpace {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      companies {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const onDeleteOptionsCustomLists = /* GraphQL */ `
  subscription OnDeleteOptionsCustomLists {
    onDeleteOptionsCustomLists {
      id
      group
      order
      option
      default
      isEnabled
      isCustom
      usedFor
      daysCount
      canBeEdited
      canBeDeleted
      canBeReorder
      pillColor
      statusType
      driverReportSetting
      key
      isDefaultForSections
      isHiddenForSections
      replacedByStandbyByRoutes {
        items {
          id
          group
          createdAt
          routeNumber
          standby
          time
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      counselings {
        items {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      issues {
        items {
          id
          group
          infractionType
          comment
          date
          time
          staffId
          station
          trackingNumber
          infractionTier
          infractionDescription
          appealDate
          resolvedDate
          appealStatus
          appealNotes
          miscNotes
          infractionTypeId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      kudos {
        items {
          id
          group
          kudoType
          notes
          date
          time
          staffId
          createdFrom
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRouteParkingSpace {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      replaceByRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeParkingSpace {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routes {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      routeHelperStatus {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      previousRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      currentRouteStatusHistory {
        items {
          id
          group
          associateType
          reason
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      customLists {
        id
        group
        type
        listCategory
        listName
        listDisplay
        canDeleteAllOptions
        createdAt
        updatedAt
        options {
          nextToken
        }
      }
      associates {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicles {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      parkingSpace {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      companies {
        items {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
    }
  }
`;
export const onCreateOptionsCustomListsStaff = /* GraphQL */ `
  subscription OnCreateOptionsCustomListsStaff {
    onCreateOptionsCustomListsStaff {
      id
      group
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onUpdateOptionsCustomListsStaff = /* GraphQL */ `
  subscription OnUpdateOptionsCustomListsStaff {
    onUpdateOptionsCustomListsStaff {
      id
      group
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onDeleteOptionsCustomListsStaff = /* GraphQL */ `
  subscription OnDeleteOptionsCustomListsStaff {
    onDeleteOptionsCustomListsStaff {
      id
      group
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      optionCustomList {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
    }
  }
`;
export const onCreateUniform = /* GraphQL */ `
  subscription OnCreateUniform {
    onCreateUniform {
      id
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      uniformType {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
      size {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateUniform = /* GraphQL */ `
  subscription OnUpdateUniform {
    onUpdateUniform {
      id
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      uniformType {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
      size {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteUniform = /* GraphQL */ `
  subscription OnDeleteUniform {
    onDeleteUniform {
      id
      group
      qty
      issueDate
      returnedDate
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
      uniformType {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
      size {
        id
        group
        value
        custom
        hidden
        deleted
        driverReportSetting
        createdAt
        updatedAt
        uniformTypes {
          nextToken
        }
        uniformSizes {
          nextToken
        }
        valueList {
          id
          group
          key
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
      emailVerified
      role
      isOwner
      isCreator
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionVehiclePhotoLogs
      permissionManageLabels
      permissionVehicleManagement
      permissionTasksReports
      permissionMessageTemplate
      permissionCompanySettingsRosterChecklistTemplates
      userTenantId
      zohoCrmContactRecordId
      injuriesCompletedBy {
        items {
          id
          group
          createdAt
          caseNumber
          injuryDate
          injuryTime
          injuryTimeIsUnknown
          timeStaffStartedWork
          completedBy
          completedByTitle
          completedByPhone
          driverHireDate
          driverDOB
          driverGender
          driverAddress
          driverCity
          driverState
          driverZip
          physicianName
          physicianFacility
          physicianAddress
          physicianCity
          physicianState
          physicianZip
          wasTreatedInER
          wasHospitalizedOvernight
          descriptionBeforeAccident
          descriptionInjury
          descriptionIncident
          descriptionDirectHarmCause
          injuryType
          notes
          dateOfDeath
          caseNumberFromLog
          staffId
          updatedAt
        }
        nextToken
      }
      recurringMessagesSent {
        items {
          id
          group
          messageName
          messageBody
          sendTime
          channelType
          senderId
          senderName
          createdAt
          updatedAt
        }
        nextToken
      }
      lastLogin
      createdAt
      updatedAt
      accidentsVerified {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      accidentCreatedBy {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        associateStatement
        witnessName
        witnessStatement
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        incidentDocuments {
          nextToken
          scannedCount
        }
        vehicleDamageImages {
          nextToken
          scannedCount
        }
        maintenanceDocuments {
          nextToken
          scannedCount
        }
        incidentDocumentConnections {
          nextToken
        }
        vehicleDamageRoute {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        optionCustomList {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        maintenanceVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        damageVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        odometerReadingVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      counseling {
        id
        group
        date
        refusedToSign
        refusedByHeraUserId
        refusedToSignDateTime
        refusedByHeraUserExplanation
        signatureAcknowledge
        signature
        counselingNotes
        internalStaffNotes
        employeeNotes
        correctiveActionSummary
        priorDiscussionSummary
        consequencesOfFailure
        dateSent
        status
        dateSigned
        tenantId
        createdFrom
        createdAt
        updatedAt
        images {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        severity {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
      }
      dailyLogCreatedBy {
        id
        group
        type
        date
        notes
        vehicleId
        creationLinkSentDateTime
        dailyLogCreationLinkSentByUserId
        dailyLogCreationLinkAssociateId
        dailyLogTakenByUserId
        dailyLogTakenByAssociateId
        dailyLogRosteredDayId
        dailyLogVehicleId
        dailyLogCreatedByUserId
        specificPhotos
        createdAt
        updatedAt
        shortenUrls {
          nextToken
        }
        rosteredDay {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        history {
          nextToken
        }
        documents {
          nextToken
          scannedCount
        }
        creationLinkAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        takenByAssociate {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        creationLinkSentByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        takenByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        createdByUser {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      messagesSent {
        items {
          id
          carrierMessageId
          group
          createdAt
          staffId
          userId
          messageType
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          senderName
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          isReadS
          sentBy
          sentAt
          linkExpiryDate
          attachmentLink
          contentType
          senderId
          owner
          destinationName
          hasParts
          rosterChecklistSubjectId
          updatedAt
        }
        nextToken
      }
      resourcesUsage {
        items {
          id
          group
          resourceIdentifier
          resourceType
          inUseAsOfDateTime
          userInstanceId
          createdAt
          updatedAt
        }
        nextToken
      }
      assignerTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      assigneeTasks {
        items {
          id
          createdAt
          owner
          creator
          group
          executionARN
          description
          date
          status
          taskName
          isDateReminder
          isNotifyOnCreation
          reminderDate
          updatedAt
        }
        nextToken
      }
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      userNotification {
        items {
          id
          group
          title
          description
          expirationTTL
          notificationType
          isRead
          payload
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      completedReminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesRead {
        items {
          id
          userID
          messageID
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      messageReadStatus {
        items {
          id
          group
          userID
          staffID
          readUpToDateTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateUserNotification = /* GraphQL */ `
  subscription OnCreateUserNotification {
    onCreateUserNotification {
      id
      group
      title
      description
      expirationTTL
      notificationType
      isRead
      payload
      userId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserNotification = /* GraphQL */ `
  subscription OnUpdateUserNotification {
    onUpdateUserNotification {
      id
      group
      title
      description
      expirationTTL
      notificationType
      isRead
      payload
      userId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserNotification = /* GraphQL */ `
  subscription OnDeleteUserNotification {
    onDeleteUserNotification {
      id
      group
      title
      description
      expirationTTL
      notificationType
      isRead
      payload
      userId
      createdAt
      updatedAt
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateValueList = /* GraphQL */ `
  subscription OnCreateValueList {
    onCreateValueList {
      id
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      items {
        items {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateValueList = /* GraphQL */ `
  subscription OnUpdateValueList {
    onUpdateValueList {
      id
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      items {
        items {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteValueList = /* GraphQL */ `
  subscription OnDeleteValueList {
    onDeleteValueList {
      id
      group
      key
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
      items {
        items {
          id
          group
          value
          custom
          hidden
          deleted
          driverReportSetting
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateValueListItem = /* GraphQL */ `
  subscription OnCreateValueListItem {
    onCreateValueListItem {
      id
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      uniformSizes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      valueList {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateValueListItem = /* GraphQL */ `
  subscription OnUpdateValueListItem {
    onUpdateValueListItem {
      id
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      uniformSizes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      valueList {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteValueListItem = /* GraphQL */ `
  subscription OnDeleteValueListItem {
    onDeleteValueListItem {
      id
      group
      value
      custom
      hidden
      deleted
      driverReportSetting
      createdAt
      updatedAt
      uniformTypes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      uniformSizes {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      valueList {
        id
        group
        key
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        items {
          nextToken
        }
      }
    }
  }
`;
export const onCreateVehicle = /* GraphQL */ `
  subscription OnCreateVehicle {
    onCreateVehicle {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateVehicle = /* GraphQL */ `
  subscription OnUpdateVehicle {
    onUpdateVehicle {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteVehicle = /* GraphQL */ `
  subscription OnDeleteVehicle {
    onDeleteVehicle {
      id
      group
      name
      vehicle
      type
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      vin
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      notes
      lastOdometerReadingDate
      overTenThousandPounds
      customDeliveryVan
      tollPassId
      createdAt
      updatedAt
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        imei
        notes
        createdAt
        updatedAt
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
          scannedCount
        }
        vehicle2 {
          nextToken
          scannedCount
        }
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          aiSubmissionDateTime
          aiResponseDateTime
          aiResponse
          aiResponseStatus
          aiIssues
          aiResponseAcknowledgedDateTime
          aiResponseFeedback
          aiDeterminedOdometerReadingValue
          aiResponseAcknowledgedBy
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      mentionedInNotes {
        items {
          id
          group
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      replacedRoutes {
        items {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        nextToken
        scannedCount
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      labels {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      vehicleType {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      company {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      parkingSpace {
        id
        group
        order
        option
        default
        isEnabled
        isCustom
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        pillColor
        statusType
        driverReportSetting
        key
        isDefaultForSections
        isHiddenForSections
        replacedByStandbyByRoutes {
          nextToken
        }
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        issues {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        replaceByRouteParkingSpace {
          nextToken
          scannedCount
        }
        replaceByRoutes {
          nextToken
          scannedCount
        }
        routeParkingSpace {
          nextToken
          scannedCount
        }
        routes {
          nextToken
          scannedCount
        }
        routeHelperStatus {
          nextToken
          scannedCount
        }
        previousRouteStatusHistory {
          nextToken
        }
        currentRouteStatusHistory {
          nextToken
        }
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        associates {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
          scannedCount
        }
        parkingSpace {
          nextToken
          scannedCount
        }
        companies {
          nextToken
          scannedCount
        }
      }
      vehicleRecordHistory {
        items {
          id
          group
          changeType
          date
          changes
          statusChangeReason
          tenantId
          userId
          userFirstName
          userLastName
          userEmail
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateVehicleHistory = /* GraphQL */ `
  subscription OnCreateVehicleHistory {
    onCreateVehicleHistory {
      id
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      vehicleId
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateVehicleHistory = /* GraphQL */ `
  subscription OnUpdateVehicleHistory {
    onUpdateVehicleHistory {
      id
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      vehicleId
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteVehicleHistory = /* GraphQL */ `
  subscription OnDeleteVehicleHistory {
    onDeleteVehicleHistory {
      id
      group
      changeType
      date
      changes
      statusChangeReason
      tenantId
      userId
      userFirstName
      userLastName
      userEmail
      vehicleId
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateVehicleMaintenance = /* GraphQL */ `
  subscription OnCreateVehicleMaintenance {
    onCreateVehicleMaintenance {
      id
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateVehicleMaintenance = /* GraphQL */ `
  subscription OnUpdateVehicleMaintenance {
    onUpdateVehicleMaintenance {
      id
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteVehicleMaintenance = /* GraphQL */ `
  subscription OnDeleteVehicleMaintenance {
    onDeleteVehicleMaintenance {
      id
      group
      dateScheduled
      dateComleted
      description
      receipt
      odometer
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateVehicleMaintenanceReminder = /* GraphQL */ `
  subscription OnCreateVehicleMaintenanceReminder {
    onCreateVehicleMaintenanceReminder {
      id
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateVehicleMaintenanceReminder = /* GraphQL */ `
  subscription OnUpdateVehicleMaintenanceReminder {
    onUpdateVehicleMaintenanceReminder {
      id
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteVehicleMaintenanceReminder = /* GraphQL */ `
  subscription OnDeleteVehicleMaintenanceReminder {
    onDeleteVehicleMaintenanceReminder {
      id
      group
      dueByDate
      dueByMileage
      services
      status
      vehicleId
      dueBySort
      dateCompleted
      userId
      notes
      createdAt
      updatedAt
      completedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateVehicleStatus = /* GraphQL */ `
  subscription OnCreateVehicleStatus {
    onCreateVehicleStatus {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateVehicleStatus = /* GraphQL */ `
  subscription OnUpdateVehicleStatus {
    onUpdateVehicleStatus {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteVehicleStatus = /* GraphQL */ `
  subscription OnDeleteVehicleStatus {
    onDeleteVehicleStatus {
      id
      group
      reason
      date
      previousStatus
      currentStatus
      createdAt
      updatedAt
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        maintenanceRecords {
          nextToken
          scannedCount
        }
        vehicleDamage {
          nextToken
          scannedCount
        }
        odometerReadings {
          nextToken
          scannedCount
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        dailyLogs {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        mentionedInNotes {
          nextToken
        }
        replacedRoutes {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        defaultStaff {
          nextToken
          scannedCount
        }
        defaultStaff2 {
          nextToken
          scannedCount
        }
        defaultStaff3 {
          nextToken
          scannedCount
        }
        vehicleType {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        company {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicleRecordHistory {
          nextToken
        }
        maintenance {
          nextToken
        }
        reminders {
          nextToken
        }
        vehicleStatusHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRouteStaffRescuer = /* GraphQL */ `
  subscription OnCreateRouteStaffRescuer {
    onCreateRouteStaffRescuer {
      id
      group
      routeId
      staffId
      startTime
      endTime
      totalRescuedPackages
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateRouteStaffRescuer = /* GraphQL */ `
  subscription OnUpdateRouteStaffRescuer {
    onUpdateRouteStaffRescuer {
      id
      group
      routeId
      staffId
      startTime
      endTime
      totalRescuedPackages
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteRouteStaffRescuer = /* GraphQL */ `
  subscription OnDeleteRouteStaffRescuer {
    onDeleteRouteStaffRescuer {
      id
      group
      routeId
      staffId
      startTime
      endTime
      totalRescuedPackages
      createdAt
      updatedAt
      route {
        id
        group
        notes
        routeNumber
        staging
        previousStatus
        statusChangedTime
        standby
        time
        isNotActive
        messageSentTime
        messageSentError
        receivedAnnouncement
        rescued
        replacedByStandbyByRoute {
          nextToken
        }
        routeDailyRosterId
        routeDeviceId
        routeStaffId
        helpers {
          nextToken
        }
        helperUpdatedDateTime
        routeVehicleId
        totalStops
        totalPackages
        completedStops
        completedPackages
        undeliveredPackagesTotal
        undeliveredPackagesBusinessClose
        undeliveredPackagesUnableToAccess
        undeliveredPackagesOtherAWS
        pickUpReturnPackage
        additionalPackagesFromRescue
        splitWithRosterAssignment
        additionalPackagesFromSplit
        firstStopTime
        lastStopTime
        daWorkStartTime
        daWorkEndTime
        daRouteStartTime
        daRouteEndTime
        rtsTime
        lunchStartTime
        lunchEndTime
        inspectionFueled
        inspectionCleaned
        inspectionFlashers
        inspectionCreditCard
        inspectionKeys
        inspectionDeviceCharger
        inspectionDeviceBattery
        inspectionNotes
        requiredVehicleTypeString
        expectedDurationHrs
        createdAt
        updatedAt
        vehicleDamage {
          nextToken
          scannedCount
        }
        dailyRoster {
          id
          group
          notesDate
          lastStandUpSentTime
          userId
          creationRoute
          createdAt
          updatedAt
        }
        device {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        device2 {
          id
          group
          deviceName
          phoneNumber
          carrier
          status
          imei
          notes
          createdAt
          updatedAt
        }
        document {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        helper {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rescuer {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        routeStatusHistory {
          nextToken
        }
        rosterChecklistSubjects {
          nextToken
        }
        parkingSpace {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        status {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        helperStatus {
          id
          group
          order
          option
          default
          isEnabled
          isCustom
          usedFor
          daysCount
          canBeEdited
          canBeDeleted
          canBeReorder
          pillColor
          statusType
          driverReportSetting
          key
          isDefaultForSections
          isHiddenForSections
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        rescuers {
          nextToken
        }
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessageReader = /* GraphQL */ `
  subscription OnCreateMessageReader {
    onCreateMessageReader {
      id
      userID
      messageID
      group
      createdAt
      updatedAt
      message {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      readBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessageReader = /* GraphQL */ `
  subscription OnUpdateMessageReader {
    onUpdateMessageReader {
      id
      userID
      messageID
      group
      createdAt
      updatedAt
      message {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      readBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessageReader = /* GraphQL */ `
  subscription OnDeleteMessageReader {
    onDeleteMessageReader {
      id
      userID
      messageID
      group
      createdAt
      updatedAt
      message {
        id
        carrierMessageId
        group
        createdAt
        staffId
        userId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        senderName
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        isReadS
        sentBy
        sentAt
        linkExpiryDate
        attachmentLink
        contentType
        senderId
        owner
        destinationName
        hasParts
        rosterChecklistSubjectId
        updatedAt
        attachment {
          id
          group
          createdAt
          s3Key
          expirationDate
          contentType
          fileSize
          updatedAt
        }
        replaceByRoute {
          id
          group
          notes
          createdAt
          replaceByRouteParkingSpaceId
          routeNumber
          staging
          replaceByRouteStatusId
          helperStatus
          isNotActive
          standby
          time
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          replaceByRouteId
          replaceByRouteDailyRosterId
          replaceByRouteDeviceId
          replaceByRouteStaffId
          replaceByRouteVehicleId
          updatedAt
        }
        route {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeDailyRosterId
          routeDeviceId
          routeStaffId
          helperUpdatedDateTime
          routeVehicleId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          requiredVehicleTypeString
          expectedDurationHrs
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveTextMessagesChangeLog
          receiveEmailMessages
          receiveEmailMessagesChangeLog
          notes
          onboardingNotes
          customDeliveryVan
          authorizedLBS
          preferredDaysOff
          createdAt
          updatedAt
        }
        rosterChecklistSubject {
          id
          notesDate
          group
          inactive
          staffId
          userId
          rosterChecklistId
          routeId
          replaceByRouteId
          dailyRosterId
          simpleItemsCompleted
          vehicleItemsCompleted
          createdAt
          updatedAt
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        user {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        sender {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          notificationEmailAddress
          notificationSmsNumber
          receiveNotificationInApp
          receiveNotificationBySms
          receiveNotificationByEmail
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionManageCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionVehiclePhotoLogs
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionCompanySettingsRosterChecklistTemplates
          userTenantId
          zohoCrmContactRecordId
          lastLogin
          createdAt
          updatedAt
        }
        isReadBy {
          nextToken
        }
      }
      readBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        notificationEmailAddress
        notificationSmsNumber
        receiveNotificationInApp
        receiveNotificationBySms
        receiveNotificationByEmail
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionManageCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionVehiclePhotoLogs
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
        permissionCompanySettingsRosterChecklistTemplates
        userTenantId
        zohoCrmContactRecordId
        injuriesCompletedBy {
          nextToken
        }
        recurringMessagesSent {
          nextToken
        }
        lastLogin
        createdAt
        updatedAt
        accidentsVerified {
          nextToken
          scannedCount
        }
        accidentCreatedBy {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          associateStatement
          witnessName
          witnessStatement
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        counseling {
          id
          group
          date
          refusedToSign
          refusedByHeraUserId
          refusedToSignDateTime
          refusedByHeraUserExplanation
          signatureAcknowledge
          signature
          counselingNotes
          internalStaffNotes
          employeeNotes
          correctiveActionSummary
          priorDiscussionSummary
          consequencesOfFailure
          dateSent
          status
          dateSigned
          tenantId
          createdFrom
          createdAt
          updatedAt
        }
        dailyLogCreatedBy {
          id
          group
          type
          date
          notes
          vehicleId
          creationLinkSentDateTime
          dailyLogCreationLinkSentByUserId
          dailyLogCreationLinkAssociateId
          dailyLogTakenByUserId
          dailyLogTakenByAssociateId
          dailyLogRosteredDayId
          dailyLogVehicleId
          dailyLogCreatedByUserId
          specificPhotos
          createdAt
          updatedAt
        }
        messagesSent {
          nextToken
        }
        resourcesUsage {
          nextToken
        }
        assignerTasks {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        userNotification {
          nextToken
        }
        completedReminders {
          nextToken
        }
        messagesRead {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessageReadStatus = /* GraphQL */ `
  subscription OnCreateMessageReadStatus {
    onCreateMessageReadStatus {
      id
      group
      userID
      staffID
      readUpToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessageReadStatus = /* GraphQL */ `
  subscription OnUpdateMessageReadStatus {
    onUpdateMessageReadStatus {
      id
      group
      userID
      staffID
      readUpToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessageReadStatus = /* GraphQL */ `
  subscription OnDeleteMessageReadStatus {
    onDeleteMessageReadStatus {
      id
      group
      userID
      staffID
      readUpToDateTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePremiumStatusHistory = /* GraphQL */ `
  subscription OnCreatePremiumStatusHistory {
    onCreatePremiumStatusHistory {
      id
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdatePremiumStatusHistory = /* GraphQL */ `
  subscription OnUpdatePremiumStatusHistory {
    onUpdatePremiumStatusHistory {
      id
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeletePremiumStatusHistory = /* GraphQL */ `
  subscription OnDeletePremiumStatusHistory {
    onDeletePremiumStatusHistory {
      id
      group
      accountPremiumStatus
      changeNotes
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateMessageServiceProvider = /* GraphQL */ `
  subscription OnCreateMessageServiceProvider {
    onCreateMessageServiceProvider {
      id
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessageServiceProvider = /* GraphQL */ `
  subscription OnUpdateMessageServiceProvider {
    onUpdateMessageServiceProvider {
      id
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessageServiceProvider = /* GraphQL */ `
  subscription OnDeleteMessageServiceProvider {
    onDeleteMessageServiceProvider {
      id
      group
      date
      previousMessageServicerProvider
      currentMessageServicerProvider
      createdAt
      updatedAt
      tenant {
        id
        createdAt
        companyName
        nameLog
        numberOfSeats
        logo
        accidentReport
        workmansComp
        dispatchNumber
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZip
        group
        zohoCrmAccountRecordId
        originationNumber
        timeZone
        trialExpDate
        firstInterestDateTime
        firstStartedTrialDateTime
        firstConvertedToPaidDateTime
        firstChurnedDateTime
        accountPremiumStatus
        accountCanceledNotes
        accountCanceledReason
        notes
        costStandard
        costBundle
        costPerformance
        costRostering
        costStaff
        costVehicles
        costMessaging
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        payOutstandingByDate
        lastPaidPositiveInvoiceLineItemDateTime
        totalNumberOfMonthsPaidByTenant
        lifetimePaymentTotal
        averageMonthlyInvoiceTotal
        isTestingAccount
        calculatedOutstandingBalance
        bundleDeactivationAllowedAt
        performanceDeactivationAllowedAt
        rosteringDeactivationAllowedAt
        staffDeactivationAllowedAt
        vehiclesDeactivationAllowedAt
        automatedCoachingSendTime
        allowLibraryUpload
        coachingCustomMessage
        messageServiceProvider
        OriginationNumberOrderId
        OriginationNumberStatus
        customerStatus
        coachingFicoThresholdIssue
        coachingFicoThreshold
        coachingFicoThresholdPR
        coachingFicoThresholdKudo
        coachingFicoIssue
        coachingFicoCO
        coachingFicoPR
        coachingFicoKudo
        coachingDcrThresholdIssue
        coachingDcrThreshold
        coachingDcrThresholdPR
        coachingDcrThresholdKudo
        coachingDcrIssue
        coachingDcrCO
        coachingDcrPR
        coachingDcrKudo
        coachingDarThresholdIssue
        coachingDarThreshold
        coachingDarThresholdPR
        coachingDarThresholdKudo
        coachingDarIssue
        coachingDarCO
        coachingDarPR
        coachingDarKudo
        coachingDnrThresholdIssue
        coachingDnrThreshold
        coachingDnrThresholdPR
        coachingDnrThresholdKudo
        coachingDnrIssue
        coachingDnrCO
        coachingDnrPR
        coachingDnrKudo
        coachingDsbThresholdIssue
        coachingDsbThreshold
        coachingDsbThresholdPR
        coachingDsbThresholdKudo
        coachingDsbIssue
        coachingDsbCO
        coachingDsbPR
        coachingDsbKudo
        coachingSwcPodThresholdIssue
        coachingSwcPodThreshold
        coachingSwcPodThresholdKudo
        coachingSwcPodThresholdPR
        coachingSwcPodIssue
        coachingSwcPodCO
        coachingSwcPodPR
        coachingSwcPodKudo
        coachingSwcCcThresholdIssue
        coachingSwcCcThreshold
        coachingSwcCcThresholdKudo
        coachingSwcCcThresholdPR
        coachingSwcCcIssue
        coachingSwcCcCO
        coachingSwcCcPR
        coachingSwcCcKudo
        coachingSwcScThresholdIssue
        coachingSwcScThreshold
        coachingSwcScThresholdKudo
        coachingSwcScThresholdPR
        coachingSwcScIssue
        coachingSwcScCO
        coachingSwcScPR
        coachingSwcScKudo
        coachingSwcAdThresholdIssue
        coachingSwcAdThreshold
        coachingSwcAdThresholdKudo
        coachingSwcAdThresholdPR
        coachingSwcAdIssue
        coachingSwcAdCO
        coachingSwcAdPR
        coachingSwcAdKudo
        coachingSeatbeltOffThresholdIssue
        coachingSeatbeltOffThreshold
        coachingSeatbeltOffThresholdPR
        coachingSeatbeltOffThresholdKudo
        coachingSeatbeltOffIssue
        coachingSeatbeltOffCO
        coachingSeatbeltOffPR
        coachingSeatbeltOffKudo
        coachingSpeedingEventThresholdIssue
        coachingSpeedingEventThreshold
        coachingSpeedingEventThresholdPR
        coachingSpeedingEventThresholdKudo
        coachingSpeedingEventIssue
        coachingSpeedingEventCO
        coachingSpeedingEventPR
        coachingSpeedingEventKudo
        coachingDistractionsRateThresholdIssue
        coachingDistractionsRateThreshold
        coachingDistractionsRateThresholdPR
        coachingDistractionsRateThresholdKudo
        coachingDistractionsRateIssue
        coachingDistractionsRateCO
        coachingDistractionsRatePR
        coachingDistractionsRateKudo
        coachingFollowingDistanceRateThresholdIssue
        coachingFollowingDistanceRateThreshold
        coachingFollowingDistanceRateThresholdPR
        coachingFollowingDistanceRateThresholdKudo
        coachingFollowingDistanceRateIssue
        coachingFollowingDistanceRateCO
        coachingFollowingDistanceRatePR
        coachingFollowingDistanceRateKudo
        coachingSignSignalViolationsRateThresholdIssue
        coachingSignSignalViolationsRateThreshold
        coachingSignSignalViolationsRateThresholdPR
        coachingSignSignalViolationsRateThresholdKudo
        coachingSignSignalViolationsRateIssue
        coachingSignSignalViolationsRateCO
        coachingSignSignalViolationsRatePR
        coachingSignSignalViolationsRateKudo
        coachingOverallTierThresholdIssue
        coachingOverallTierThresholdKudo
        coachingOverallTierIssue
        coachingOverallTierKudo
        coachingOverallTierRatingIssue
        coachingOverallTierRatingKudo
        coachingConsecutiveTierThresholdIssue
        coachingConsecutiveTierThresholdKudo
        coachingConsecutiveTierIssue
        coachingConsecutiveTierKudo
        coachingConsecutiveTierRatingIssue
        coachingConsecutiveTierRatingKudo
        coachingPositiveFeedbackThresholdIssue
        coachingPositiveFeedbackThreshold
        coachingPositiveFeedbackThresholdPR
        coachingPositiveFeedbackThresholdKudo
        coachingPositiveFeedbackIssue
        coachingPositiveFeedbackCO
        coachingPositiveFeedbackPR
        coachingPositiveFeedbackKudo
        coachingDailyFicoThresholdIssue
        coachingDailyFicoThreshold
        coachingDailyFicoThresholdPR
        coachingDailyFicoThresholdKudo
        coachingDailyFicoIssue
        coachingDailyFicoCO
        coachingDailyFicoPR
        coachingDailyFicoKudo
        coachingSeatbeltThresholdIssue
        coachingSeatbeltThreshold
        coachingSeatbeltThresholdPR
        coachingSeatbeltThresholdKudo
        coachingSeatbeltIssue
        coachingSeatbeltCO
        coachingSeatbeltPR
        coachingSeatbeltKudo
        coachingSseIssue
        coachingSseCO
        coachingSsePR
        coachingSseKudo
        coachingSseThresholdIssue
        coachingSseThreshold
        coachingSseThresholdPR
        coachingSseThresholdKudo
        coachingDvcrsIssue
        coachingDvcrsCO
        coachingDvcrsPR
        coachingHarshBrakingRateThresholdIssue
        coachingHarshBrakingRateThreshold
        coachingHarshBrakingRateThresholdPR
        coachingHarshBrakingRateThresholdKudo
        coachingHarshBrakingRateIssue
        coachingHarshBrakingRateCO
        coachingHarshBrakingRatePR
        coachingHarshBrakingRateKudo
        coachingDaTierThresholdIssue
        coachingDaTierThresholdKudo
        coachingDaTierIssue
        coachingDaTierKudo
        coachingDaTierPR
        coachingDaTierCO
        coachingDaTierRatingIssue
        coachingDaTierRatingCO
        coachingDaTierRatingPR
        coachingDaTierRatingKudo
        coachingCdfScoreThresholdIssue
        coachingCdfScoreThreshold
        coachingCdfScoreThresholdPR
        coachingCdfScoreThresholdKudo
        coachingCdfScoreIssue
        coachingCdfScoreKudo
        coachingCdfScoreRatingIssue
        coachingCdfScoreCO
        coachingCdfScorePR
        coachingCdfScoreRatingKudo
        coachingCdfDpmoThresholdIssue
        coachingCdfDpmoThreshold
        coachingCdfDpmoThresholdPR
        coachingCdfDpmoThresholdKudo
        coachingCdfDpmoIssue
        coachingCdfDpmoKudo
        coachingCdfDpmoRatingIssue
        coachingCdfDpmoCO
        coachingCdfDpmoPR
        coachingCdfDpmoRatingKudo
        coachingHarshCorneringRateThresholdIssue
        coachingHarshCorneringRateThreshold
        coachingHarshCorneringRateThresholdPR
        coachingHarshCorneringRateThresholdKudo
        coachingHarshCorneringRateIssue
        coachingHarshCorneringRateCO
        coachingHarshCorneringRatePR
        coachingHarshCorneringRateKudo
        coachingPpsCompliancePercentThresholdIssue
        coachingPpsCompliancePercentThreshold
        coachingPpsCompliancePercentThresholdPR
        coachingPpsCompliancePercentThresholdKudo
        coachingPpsCompliancePercentIssue
        coachingPpsCompliancePercentCO
        coachingPpsCompliancePercentPR
        coachingPpsCompliancePercentKudo
        coachingPpsBreachesThresholdIssue
        coachingPpsBreachesThreshold
        coachingPpsBreachesThresholdPR
        coachingPpsBreachesThresholdKudo
        coachingPpsBreachesIssue
        coachingPpsBreachesCO
        coachingPpsBreachesPR
        coachingPpsBreachesKudo
        coachingDailyComplianceRateThresholdIssue
        coachingDailyComplianceRateThreshold
        coachingDailyComplianceRateThresholdPR
        coachingDailyComplianceRateThresholdKudo
        coachingDailyComplianceRateIssue
        coachingDailyComplianceRateCO
        coachingDailyComplianceRatePR
        coachingDailyComplianceRateKudo
        coachingTraningRemainingIssue
        coachingTraningRemainingCO
        coachingTraningRemainingPR
        coachingCameraObstructionIssue
        coachingCameraObstructionCO
        coachingCameraObstructionThresholdIssue
        coachingCameraObstructionThreshold
        coachingDriverDistractionIssue
        coachingDriverDistractionCO
        coachingDriverDistractionThresholdIssue
        coachingDriverDistractionThreshold
        coachingDriverDrowsinessIssue
        coachingDriverDrowsinessCO
        coachingDriverDrowsinessThresholdIssue
        coachingDriverDrowsinessThreshold
        coachingDriverInitiatedIssue
        coachingDriverInitiatedCO
        coachingDriverInitiatedThresholdIssue
        coachingDriverInitiatedThreshold
        coachingDriverStarPR
        coachingDriverStarKudo
        coachingDriverStarThresholdPR
        coachingDriverStarThresholdKudo
        coachingFaceMaskComplianceIssue
        coachingFaceMaskComplianceCO
        coachingFaceMaskComplianceThresholdIssue
        coachingFaceMaskComplianceThreshold
        coachingFollowingDistanceIssue
        coachingFollowingDistanceCO
        coachingFollowingDistanceThresholdIssue
        coachingFollowingDistanceThreshold
        coachingHardAccelerationIssue
        coachingHardAccelerationCO
        coachingHardAccelerationThresholdIssue
        coachingHardAccelerationThreshold
        coachingHardBrakingIssue
        coachingHardBrakingCO
        coachingHardBrakingThresholdIssue
        coachingHardBrakingThreshold
        coachingHardTurnIssue
        coachingHardTurnCO
        coachingHardTurnThresholdIssue
        coachingHardTurnThreshold
        coachingHighGIssue
        coachingHighGCO
        coachingHighGThresholdIssue
        coachingHighGThreshold
        coachingLowImpactIssue
        coachingLowImpactCO
        coachingLowImpactThresholdIssue
        coachingLowImpactThreshold
        coachingSeatbeltComplianceIssue
        coachingSeatbeltComplianceCO
        coachingSeatbeltComplianceThresholdIssue
        coachingSeatbeltComplianceThreshold
        coachingSignViolationsIssue
        coachingSignViolationsCO
        coachingSignViolationsThresholdIssue
        coachingSignViolationsThreshold
        coachingSpeedingViolationsIssue
        coachingSpeedingViolationsCO
        coachingSpeedingViolationsThresholdIssue
        coachingSpeedingViolationsThreshold
        coachingTrafficLightViolationIssue
        coachingTrafficLightViolationCO
        coachingTrafficLightViolationThresholdIssue
        coachingTrafficLightViolationThreshold
        coachingUTurnIssue
        coachingUTurnCO
        coachingUTurnThresholdIssue
        coachingUTurnThreshold
        coachingWeavingIssue
        coachingWeavingCO
        coachingWeavingThresholdIssue
        coachingWeavingThreshold
        coachingDriverRankRange
        usesXLcoaching
        shortCode
        growSurfParticipantId
        growSurfReferrerEmail
        growSurfReferrerFullName
        growSurfReferredEmail
        growSurfReferredFullName
        growSurfReferralComplete
        growSurfReferredByEmailBackup
        growSurfReferredByFullNameBackup
        growSurfParticipantEmail
        growSurfParticipantFullName
        growSurfParticipantReferralComplete
        stripeCustomerId
        stripeBillingEmail
        driverReportDayRange
        accountType
        customerType
        customerSubType
        accountCountry
        isTemporaryAccount
        allowParentData
        parentAccountId
        permissionHeraAi
        permissionVehiclePhotoLogsHeraAi
        featureAccessVehiclePhotoLogsHeraAi
        featureEnabledVehiclePhotoLogsHeraAi
        featureEnabledInventoryManagement
        featureAccessInventoryManagement
        permissionInventoryManagement
        cards {
          nextToken
        }
        companyScoreCardsByYearWeek {
          nextToken
        }
        textractjobs {
          nextToken
        }
        waves {
          nextToken
        }
        recurringMessage {
          nextToken
        }
        updatedAt
        dailyRoster {
          nextToken
        }
        invoices {
          nextToken
        }
        messages {
          nextToken
        }
        parentAccount {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        users {
          nextToken
        }
        valueLists {
          nextToken
        }
        premiumStatusHistory {
          nextToken
        }
        messageServiceProviderHistory {
          nextToken
        }
      }
    }
  }
`;
export const onCreateTelnyx = /* GraphQL */ `
  subscription OnCreateTelnyx {
    onCreateTelnyx {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTelnyx = /* GraphQL */ `
  subscription OnUpdateTelnyx {
    onUpdateTelnyx {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTelnyx = /* GraphQL */ `
  subscription OnDeleteTelnyx {
    onDeleteTelnyx {
      id
      group
      phoneNumber
      telnyxId
      status
      date
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAuditLog = /* GraphQL */ `
  subscription OnCreateAuditLog {
    onCreateAuditLog {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
    }
  }
`;
export const onUpdateAuditLog = /* GraphQL */ `
  subscription OnUpdateAuditLog {
    onUpdateAuditLog {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
    }
  }
`;
export const onDeleteAuditLog = /* GraphQL */ `
  subscription OnDeleteAuditLog {
    onDeleteAuditLog {
      id
      tenantID
      group
      email
      userID
      cognitoSub
      firstName
      lastName
      tenantName
      mutationName
      mutationNameText
      mutatedRecordId
      mutatedData
      mutatedDataDiff
      createdAt
      ipAddress
      ttl
      pageUrl
      logRocketSessionUrl
      userType
      updatedAt
    }
  }
`;
export const onCreateProperParkingSequenceSummary = /* GraphQL */ `
  subscription OnCreateProperParkingSequenceSummary {
    onCreateProperParkingSequenceSummary {
      id
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProperParkingSequenceSummary = /* GraphQL */ `
  subscription OnUpdateProperParkingSequenceSummary {
    onUpdateProperParkingSequenceSummary {
      id
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProperParkingSequenceSummary = /* GraphQL */ `
  subscription OnDeleteProperParkingSequenceSummary {
    onDeleteProperParkingSequenceSummary {
      id
      group
      week
      year
      yearWeek
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      textractJob {
        id
        group
        owner
        userId
        key
        fileName
        week
        year
        template
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        summaryTableResults
        timeZone
        tenantUsesXL
        tenantUsesXLS
        customerSubType
        metadataCreationDate
        metadataModificationDate
        metadataYear
        metadataWeek
        scoreCardFailedValidators
        date
        podQualitySummary {
          id
          group
          week
          year
          yearWeek
          success
          bypass
          rejects
          opportunities
          noPackageDetected
          packageNotClearlyVisible
          blurryPhoto
          packageTooClose
          humanInThePicture
          packageInHand
          photoTooDark
          packageInCar
          other
          grandTotal
          createdAt
          updatedAt
        }
        cxFeedbackSummary {
          id
          group
          week
          year
          yearWeek
          dcfTier
          dcfScore
          positiveFeedback
          negativeFeedback
          deliveriesWithoutCF
          deliveryWasGreat
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          thankMyDriver
          deliveryWasntGreat
          mishandledPackage
          driverUnprofessional
          driverDidNotFollowDeliveryInstructions
          onTime
          lateDelivery
          itemDamaged
          deliveredToWrongAddress
          neverReceivedDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        tenant {
          id
          createdAt
          companyName
          nameLog
          numberOfSeats
          logo
          accidentReport
          workmansComp
          dispatchNumber
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZip
          group
          zohoCrmAccountRecordId
          originationNumber
          timeZone
          trialExpDate
          firstInterestDateTime
          firstStartedTrialDateTime
          firstConvertedToPaidDateTime
          firstChurnedDateTime
          accountPremiumStatus
          accountCanceledNotes
          accountCanceledReason
          notes
          costStandard
          costBundle
          costPerformance
          costRostering
          costStaff
          costVehicles
          costMessaging
          discountPercent
          discountFixed
          discountPercentLabel
          discountFixedLabel
          payOutstandingByDate
          lastPaidPositiveInvoiceLineItemDateTime
          totalNumberOfMonthsPaidByTenant
          lifetimePaymentTotal
          averageMonthlyInvoiceTotal
          isTestingAccount
          calculatedOutstandingBalance
          bundleDeactivationAllowedAt
          performanceDeactivationAllowedAt
          rosteringDeactivationAllowedAt
          staffDeactivationAllowedAt
          vehiclesDeactivationAllowedAt
          automatedCoachingSendTime
          allowLibraryUpload
          coachingCustomMessage
          messageServiceProvider
          OriginationNumberOrderId
          OriginationNumberStatus
          customerStatus
          coachingFicoThresholdIssue
          coachingFicoThreshold
          coachingFicoThresholdPR
          coachingFicoThresholdKudo
          coachingFicoIssue
          coachingFicoCO
          coachingFicoPR
          coachingFicoKudo
          coachingDcrThresholdIssue
          coachingDcrThreshold
          coachingDcrThresholdPR
          coachingDcrThresholdKudo
          coachingDcrIssue
          coachingDcrCO
          coachingDcrPR
          coachingDcrKudo
          coachingDarThresholdIssue
          coachingDarThreshold
          coachingDarThresholdPR
          coachingDarThresholdKudo
          coachingDarIssue
          coachingDarCO
          coachingDarPR
          coachingDarKudo
          coachingDnrThresholdIssue
          coachingDnrThreshold
          coachingDnrThresholdPR
          coachingDnrThresholdKudo
          coachingDnrIssue
          coachingDnrCO
          coachingDnrPR
          coachingDnrKudo
          coachingDsbThresholdIssue
          coachingDsbThreshold
          coachingDsbThresholdPR
          coachingDsbThresholdKudo
          coachingDsbIssue
          coachingDsbCO
          coachingDsbPR
          coachingDsbKudo
          coachingSwcPodThresholdIssue
          coachingSwcPodThreshold
          coachingSwcPodThresholdKudo
          coachingSwcPodThresholdPR
          coachingSwcPodIssue
          coachingSwcPodCO
          coachingSwcPodPR
          coachingSwcPodKudo
          coachingSwcCcThresholdIssue
          coachingSwcCcThreshold
          coachingSwcCcThresholdKudo
          coachingSwcCcThresholdPR
          coachingSwcCcIssue
          coachingSwcCcCO
          coachingSwcCcPR
          coachingSwcCcKudo
          coachingSwcScThresholdIssue
          coachingSwcScThreshold
          coachingSwcScThresholdKudo
          coachingSwcScThresholdPR
          coachingSwcScIssue
          coachingSwcScCO
          coachingSwcScPR
          coachingSwcScKudo
          coachingSwcAdThresholdIssue
          coachingSwcAdThreshold
          coachingSwcAdThresholdKudo
          coachingSwcAdThresholdPR
          coachingSwcAdIssue
          coachingSwcAdCO
          coachingSwcAdPR
          coachingSwcAdKudo
          coachingSeatbeltOffThresholdIssue
          coachingSeatbeltOffThreshold
          coachingSeatbeltOffThresholdPR
          coachingSeatbeltOffThresholdKudo
          coachingSeatbeltOffIssue
          coachingSeatbeltOffCO
          coachingSeatbeltOffPR
          coachingSeatbeltOffKudo
          coachingSpeedingEventThresholdIssue
          coachingSpeedingEventThreshold
          coachingSpeedingEventThresholdPR
          coachingSpeedingEventThresholdKudo
          coachingSpeedingEventIssue
          coachingSpeedingEventCO
          coachingSpeedingEventPR
          coachingSpeedingEventKudo
          coachingDistractionsRateThresholdIssue
          coachingDistractionsRateThreshold
          coachingDistractionsRateThresholdPR
          coachingDistractionsRateThresholdKudo
          coachingDistractionsRateIssue
          coachingDistractionsRateCO
          coachingDistractionsRatePR
          coachingDistractionsRateKudo
          coachingFollowingDistanceRateThresholdIssue
          coachingFollowingDistanceRateThreshold
          coachingFollowingDistanceRateThresholdPR
          coachingFollowingDistanceRateThresholdKudo
          coachingFollowingDistanceRateIssue
          coachingFollowingDistanceRateCO
          coachingFollowingDistanceRatePR
          coachingFollowingDistanceRateKudo
          coachingSignSignalViolationsRateThresholdIssue
          coachingSignSignalViolationsRateThreshold
          coachingSignSignalViolationsRateThresholdPR
          coachingSignSignalViolationsRateThresholdKudo
          coachingSignSignalViolationsRateIssue
          coachingSignSignalViolationsRateCO
          coachingSignSignalViolationsRatePR
          coachingSignSignalViolationsRateKudo
          coachingOverallTierThresholdIssue
          coachingOverallTierThresholdKudo
          coachingOverallTierIssue
          coachingOverallTierKudo
          coachingOverallTierRatingIssue
          coachingOverallTierRatingKudo
          coachingConsecutiveTierThresholdIssue
          coachingConsecutiveTierThresholdKudo
          coachingConsecutiveTierIssue
          coachingConsecutiveTierKudo
          coachingConsecutiveTierRatingIssue
          coachingConsecutiveTierRatingKudo
          coachingPositiveFeedbackThresholdIssue
          coachingPositiveFeedbackThreshold
          coachingPositiveFeedbackThresholdPR
          coachingPositiveFeedbackThresholdKudo
          coachingPositiveFeedbackIssue
          coachingPositiveFeedbackCO
          coachingPositiveFeedbackPR
          coachingPositiveFeedbackKudo
          coachingDailyFicoThresholdIssue
          coachingDailyFicoThreshold
          coachingDailyFicoThresholdPR
          coachingDailyFicoThresholdKudo
          coachingDailyFicoIssue
          coachingDailyFicoCO
          coachingDailyFicoPR
          coachingDailyFicoKudo
          coachingSeatbeltThresholdIssue
          coachingSeatbeltThreshold
          coachingSeatbeltThresholdPR
          coachingSeatbeltThresholdKudo
          coachingSeatbeltIssue
          coachingSeatbeltCO
          coachingSeatbeltPR
          coachingSeatbeltKudo
          coachingSseIssue
          coachingSseCO
          coachingSsePR
          coachingSseKudo
          coachingSseThresholdIssue
          coachingSseThreshold
          coachingSseThresholdPR
          coachingSseThresholdKudo
          coachingDvcrsIssue
          coachingDvcrsCO
          coachingDvcrsPR
          coachingHarshBrakingRateThresholdIssue
          coachingHarshBrakingRateThreshold
          coachingHarshBrakingRateThresholdPR
          coachingHarshBrakingRateThresholdKudo
          coachingHarshBrakingRateIssue
          coachingHarshBrakingRateCO
          coachingHarshBrakingRatePR
          coachingHarshBrakingRateKudo
          coachingDaTierThresholdIssue
          coachingDaTierThresholdKudo
          coachingDaTierIssue
          coachingDaTierKudo
          coachingDaTierPR
          coachingDaTierCO
          coachingDaTierRatingIssue
          coachingDaTierRatingCO
          coachingDaTierRatingPR
          coachingDaTierRatingKudo
          coachingCdfScoreThresholdIssue
          coachingCdfScoreThreshold
          coachingCdfScoreThresholdPR
          coachingCdfScoreThresholdKudo
          coachingCdfScoreIssue
          coachingCdfScoreKudo
          coachingCdfScoreRatingIssue
          coachingCdfScoreCO
          coachingCdfScorePR
          coachingCdfScoreRatingKudo
          coachingCdfDpmoThresholdIssue
          coachingCdfDpmoThreshold
          coachingCdfDpmoThresholdPR
          coachingCdfDpmoThresholdKudo
          coachingCdfDpmoIssue
          coachingCdfDpmoKudo
          coachingCdfDpmoRatingIssue
          coachingCdfDpmoCO
          coachingCdfDpmoPR
          coachingCdfDpmoRatingKudo
          coachingHarshCorneringRateThresholdIssue
          coachingHarshCorneringRateThreshold
          coachingHarshCorneringRateThresholdPR
          coachingHarshCorneringRateThresholdKudo
          coachingHarshCorneringRateIssue
          coachingHarshCorneringRateCO
          coachingHarshCorneringRatePR
          coachingHarshCorneringRateKudo
          coachingPpsCompliancePercentThresholdIssue
          coachingPpsCompliancePercentThreshold
          coachingPpsCompliancePercentThresholdPR
          coachingPpsCompliancePercentThresholdKudo
          coachingPpsCompliancePercentIssue
          coachingPpsCompliancePercentCO
          coachingPpsCompliancePercentPR
          coachingPpsCompliancePercentKudo
          coachingPpsBreachesThresholdIssue
          coachingPpsBreachesThreshold
          coachingPpsBreachesThresholdPR
          coachingPpsBreachesThresholdKudo
          coachingPpsBreachesIssue
          coachingPpsBreachesCO
          coachingPpsBreachesPR
          coachingPpsBreachesKudo
          coachingDailyComplianceRateThresholdIssue
          coachingDailyComplianceRateThreshold
          coachingDailyComplianceRateThresholdPR
          coachingDailyComplianceRateThresholdKudo
          coachingDailyComplianceRateIssue
          coachingDailyComplianceRateCO
          coachingDailyComplianceRatePR
          coachingDailyComplianceRateKudo
          coachingTraningRemainingIssue
          coachingTraningRemainingCO
          coachingTraningRemainingPR
          coachingCameraObstructionIssue
          coachingCameraObstructionCO
          coachingCameraObstructionThresholdIssue
          coachingCameraObstructionThreshold
          coachingDriverDistractionIssue
          coachingDriverDistractionCO
          coachingDriverDistractionThresholdIssue
          coachingDriverDistractionThreshold
          coachingDriverDrowsinessIssue
          coachingDriverDrowsinessCO
          coachingDriverDrowsinessThresholdIssue
          coachingDriverDrowsinessThreshold
          coachingDriverInitiatedIssue
          coachingDriverInitiatedCO
          coachingDriverInitiatedThresholdIssue
          coachingDriverInitiatedThreshold
          coachingDriverStarPR
          coachingDriverStarKudo
          coachingDriverStarThresholdPR
          coachingDriverStarThresholdKudo
          coachingFaceMaskComplianceIssue
          coachingFaceMaskComplianceCO
          coachingFaceMaskComplianceThresholdIssue
          coachingFaceMaskComplianceThreshold
          coachingFollowingDistanceIssue
          coachingFollowingDistanceCO
          coachingFollowingDistanceThresholdIssue
          coachingFollowingDistanceThreshold
          coachingHardAccelerationIssue
          coachingHardAccelerationCO
          coachingHardAccelerationThresholdIssue
          coachingHardAccelerationThreshold
          coachingHardBrakingIssue
          coachingHardBrakingCO
          coachingHardBrakingThresholdIssue
          coachingHardBrakingThreshold
          coachingHardTurnIssue
          coachingHardTurnCO
          coachingHardTurnThresholdIssue
          coachingHardTurnThreshold
          coachingHighGIssue
          coachingHighGCO
          coachingHighGThresholdIssue
          coachingHighGThreshold
          coachingLowImpactIssue
          coachingLowImpactCO
          coachingLowImpactThresholdIssue
          coachingLowImpactThreshold
          coachingSeatbeltComplianceIssue
          coachingSeatbeltComplianceCO
          coachingSeatbeltComplianceThresholdIssue
          coachingSeatbeltComplianceThreshold
          coachingSignViolationsIssue
          coachingSignViolationsCO
          coachingSignViolationsThresholdIssue
          coachingSignViolationsThreshold
          coachingSpeedingViolationsIssue
          coachingSpeedingViolationsCO
          coachingSpeedingViolationsThresholdIssue
          coachingSpeedingViolationsThreshold
          coachingTrafficLightViolationIssue
          coachingTrafficLightViolationCO
          coachingTrafficLightViolationThresholdIssue
          coachingTrafficLightViolationThreshold
          coachingUTurnIssue
          coachingUTurnCO
          coachingUTurnThresholdIssue
          coachingUTurnThreshold
          coachingWeavingIssue
          coachingWeavingCO
          coachingWeavingThresholdIssue
          coachingWeavingThreshold
          coachingDriverRankRange
          usesXLcoaching
          shortCode
          growSurfParticipantId
          growSurfReferrerEmail
          growSurfReferrerFullName
          growSurfReferredEmail
          growSurfReferredFullName
          growSurfReferralComplete
          growSurfReferredByEmailBackup
          growSurfReferredByFullNameBackup
          growSurfParticipantEmail
          growSurfParticipantFullName
          growSurfParticipantReferralComplete
          stripeCustomerId
          stripeBillingEmail
          driverReportDayRange
          accountType
          customerType
          customerSubType
          accountCountry
          isTemporaryAccount
          allowParentData
          parentAccountId
          permissionHeraAi
          permissionVehiclePhotoLogsHeraAi
          featureAccessVehiclePhotoLogsHeraAi
          featureEnabledVehiclePhotoLogsHeraAi
          featureEnabledInventoryManagement
          featureAccessInventoryManagement
          permissionInventoryManagement
          updatedAt
        }
        properParkingSequenceSummary {
          id
          group
          week
          year
          yearWeek
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProperParkingSequence = /* GraphQL */ `
  subscription OnCreateProperParkingSequence {
    onCreateProperParkingSequence {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateProperParkingSequence = /* GraphQL */ `
  subscription OnUpdateProperParkingSequence {
    onUpdateProperParkingSequence {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteProperParkingSequence = /* GraphQL */ `
  subscription OnDeleteProperParkingSequence {
    onDeleteProperParkingSequence {
      id
      group
      matched
      matchedS
      messageHasBeenSent
      week
      year
      date
      employeeName
      transporterId
      evaluatedStops
      compliance
      percentCompliance
      invalid
      percentInvalid
      missingGearInPark
      percentMissingGearInPark
      missingParkingBrake
      percentMissingParkingBrake
      totalBreaches
      percentTotalBreaches
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        replacedByStandbyByRoute {
          nextToken
        }
        drugTests {
          nextToken
        }
        receiveTextMessages
        receiveTextMessagesChangeLog
        receiveEmailMessages
        receiveEmailMessagesChangeLog
        injuries {
          nextToken
        }
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
        accidents {
          nextToken
          scannedCount
        }
        eocScores {
          nextToken
        }
        counselings {
          nextToken
          scannedCount
        }
        documents {
          nextToken
          scannedCount
        }
        infractions {
          nextToken
          scannedCount
        }
        kudos {
          nextToken
          scannedCount
        }
        messages {
          nextToken
        }
        mentionedInNotes {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        replaceByRoute {
          nextToken
          scannedCount
        }
        route {
          nextToken
          scannedCount
        }
        routeHelper {
          nextToken
          scannedCount
        }
        routeRescuer {
          nextToken
          scannedCount
        }
        labels {
          nextToken
        }
        messagePreferencesHistory {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        netrdadyneAlerts {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        routeStatusHistory {
          nextToken
        }
        assigneeTasks {
          nextToken
        }
        authorizedToDrive {
          nextToken
          scannedCount
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
        routeRescuerStaff {
          nextToken
        }
        messageReadStatus {
          nextToken
        }
        properParkingSequences {
          nextToken
        }
      }
    }
  }
`;

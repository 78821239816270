import {
    onCreateVehicleByGroup,
    onUpdateVehicleByGroup,
    onDeleteVehicleByGroup
} from '../../subscriptions';
import store from '@/store'
import pako from 'pako';
import { API } from "aws-amplify";
import { initializeSubscription, disconnectSubscription } from '../helper'

"use strict";
let subscriptionVehicles = null

export const loadVehicles = async function(){
    if(!store.state.userInfo?.tenant?.group) return
    try {
        let apiName = "heraPublicApi";
        let path = "/executeMutation";
        let post = {
            body: {
                type: "vehicle",
                mutation: "load-all",
                group: store.state.userInfo.tenant.group,
                owner: store.state.userInfo.cognitoSub,
                items: {},
            },
        };
        const result = await safeFunction(API.post)(apiName, path, post);  
        const compressedData = atob(result);
        const compressedArray = new Uint8Array(compressedData.split('').map(char => char.charCodeAt(0)));
        const decompressed = pako.ungzip(compressedArray, { to: 'string' });
        const vehicles = JSON.parse(decompressed);
        store.dispatch("subscriptionStore/setVehicleList", vehicles)
    } catch (e) {
        console.error(e)
    }
}

export const vehicleSubscribe = function(instance){
    const queries = {
        onCreateVehicleByGroup,
        onUpdateVehicleByGroup,
        onDeleteVehicleByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionVehicles, queries, input, "subscriptionStore/mutateVehicle", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionVehicles = subscriptions['vehicle'] = {
        loadList: loadVehicles,
        subscribe: vehicleSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: vehicleEventCallback,
        subscription
    }
}

export const vehicleEventCallback = function(subscriptionState, payload){
    //
}
<template>
  <el-dialog
    :title="title"
    :visible="dialogVisible"
    width="30%"
    center
    :before-close="closeDialog"
    :append-to-body="true"
  >
    <p class="break-normal">
      {{ rowData.option }} is in use for {{ useForText }} in Hera.
      Which option would you like to use for these {{ typeCustomLists }} instead?
    </p>
    <br />
    <br />
    <el-select
      v-model="selectedOption"
      placeholder="Select">
      <el-option
        v-for="item in customListOptions"
        :key="item.id"
        :label="item.option"
        :value="item.id">
      </el-option>
    </el-select>
    <span v-if="!selectedOption && showWarnig" class="text-red-600 font-bold text-xs ml-5">Select an option.</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">Cancel</el-button>
      <el-button type="primary" @click="reAssign">Continue</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    rowData: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "",
    },
    optionsToRemove: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      selectedOption: null,
      showWarnig: false,
      customListType: {
        ['vehicle-type']: 'vehicleTypeList',
        ['parking-space']: 'parkingSpaceList',
        ['incident-type']: 'incidentTypeList',
        ['counseling-severity']: 'counselingSeverityTypeList',
        ['issue-type']: 'issueTypeList',
        ['kudo-type']: 'kudoTypeList',
        ['vehicle-company']: 'vehicleCompanyList',
        ['roster-status']: 'rosterStatusList',
      }
    };
  },
  computed: {
    ...mapGetters([
      'vehicleTypeList',
      'parkingSpaceList',
      'incidentTypeList',
      'counselingSeverityTypeList',
      'issueTypeList',
      'kudoTypeList',
      'vehicleCompanyList',
      'rosterStatusList'
    ]),

    useForText() {
      if(this.type == "vehicle-type"){
        let vehicles = this.rowData.vehicles.items.length
        let associates = this.rowData.associates.items.length
        return `${vehicles} ${this.pluralize(vehicles, 'Vehicle', 'Vehicles')} and ${associates} ${this.pluralize(associates, 'Associate', 'Associates')}`
      } else if(this.type == "incident-type") {
        let accidents = this.rowData.accidents.items.length
        return `${accidents} ${this.pluralize(accidents, 'Incident', 'Incidents')}`
      } else if(this.type == "counseling-severity") {
        let counselings = this.rowData.counselings.items.length
        return `${counselings} ${this.pluralize(counselings, 'Counseling', 'Counselings')}`
      } else if(this.type == "issue-type") {
        let issues = this.rowData.issues.items.length
        return `${issues} ${this.pluralize(issues, 'Issue', 'Issues')}`
      } else if(this.type == "kudo-type") {
        let kudos = this.rowData.kudos.items.length
        return `${kudos} ${this.pluralize(kudos, 'Kudo', 'Kudos')}`
      } else if(this.type == "vehicle-company") {
        let companies = this.rowData.companies.items.length
        return `${companies} ${this.pluralize(companies, 'Vehicle', 'Vehicles')}`
      } else if(this.type == "roster-status") {
        let routes = this.rowData.routes.items.length
        let routeHelperStatus = this.rowData.routeHelperStatus.items.length
        let replaceByRoutes = this.rowData.replaceByRoutes.items.length
        let total = routes + routeHelperStatus + replaceByRoutes
        return `${total} ${this.pluralize(total, 'Rostered Route', 'Rostered Routes')}`
      } else {
        return this.pluralize(this.rowData.usedFor, 'Route', 'Routes');
      }
    },

    typeCustomLists() {
      if (this.type == "incident-type"){
        let accidents = this.rowData.accidents.items.length
        return this.pluralize(accidents, 'Incident', 'Incidents');
      } else if(this.type == "parking-space"){
        return this.pluralize(this.rowData.usedFor, 'Rostered Vehicle', 'Rostered Vehicles');
      } else if(this.type == "vehicle-role-for-route"){
        return this.pluralize(this.rowData.usedFor, 'Vehicle Assignment', 'Vehicle Assignments');
      } else if(this.type == "vehicle-type"){
        let vehicles = this.rowData.vehicles.items.length
        let associates = this.rowData.associates.items.length
        return `${this.pluralize(vehicles, 'Vehicle', 'Vehicles')} and ${this.pluralize(associates, 'Associate', 'Associates')}`
      } else if(this.type == "counseling-severity") {
        let counselings = this.rowData.counselings.items.length
        return `${this.pluralize(counselings, 'Counseling', 'Counselings')}`
      } else if(this.type == "issue-type") {
        let issues = this.rowData.issues.items.length
        return `${this.pluralize(issues, 'Issue', 'Issues')}`
      } else if(this.type == "kudo-type") {
        let kudos = this.rowData.kudos.items.length
        return `${this.pluralize(kudos, 'Kudo', 'Kudos')}`
      } else if(this.type == "vehicle-company"){
        let companies = this.rowData.companies.items.length
        return `${this.pluralize(companies, 'Vehicle', 'Vehicles')}`
      } else if(this.type == "roster-status"){
        let routes = this.rowData.routes.items.length
        let routeHelperStatus = this.rowData.routeHelperStatus.items.length
        let replaceByRoutes = this.rowData.replaceByRoutes.items.length
        let total = routes + routeHelperStatus + replaceByRoutes;
        return `${this.pluralize(total, 'Rostered Route', 'Rostered Routes')}`
      } else {
        console.log({ rowData: this.rowData });
        return this.pluralize(this.rowData.usedFor, 'Route', 'Routes');
      }
    },

    customListOptions() {
      const mapOptionsToRemove = this.optionsToRemove.map(item => item.id)
      return this[this.customListType[this.type]].filter(item => item.id !== this.rowData.id && !mapOptionsToRemove.includes(item.id))
    }
  },
  methods: {
    closeDialog() {
      this.selectedOption = null
      this.showWarnig = false
      this.$emit("dialogClose", false);
    },

    reAssign() {
      if(!this.selectedOption) return this.showWarnig = true

      this.showWarnig = false
      let reAssignedOptions = {
        newValue: this.selectedOption,
        oldValue: this.rowData.id,
      }
      this.$emit("reAssignContentItems", reAssignedOptions);
    },
  },
};
</script>

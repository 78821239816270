
export const rosterChecklistFragment = /* GraphQL */ `
fragment rosterChecklistFragment on RosterChecklist {
    id
    name
    completionFrequencyId
    assignedToId
    status
    vehicleTypes{
        items{
            id
            vehicleTypeId
        }
    }
    sendTime
    createdAt
    updatedAt
}`;

export const rosterChecklistVehicleTypeFragment = /* GraphQL */ `
fragment rosterChecklistVehicleTypeFragment on RosterChecklistVehicleType {
    id
    vehicleTypeId
    rosterChecklistId
    createdAt
    updatedAt
}`;

export const rosterChecklistUserFragment = /* GraphQL */ `
fragment rosterChecklistUserFragment on RosterChecklistUser {
    id
    userId
    rosterChecklistId
    createdAt
    updatedAt
}`;

export const rosterChecklistTaskFragment = /* GraphQL */ `
fragment rosterChecklistTaskFragment on RosterChecklistTask {
    id
    type
    taskId
    rosterChecklistId
    createdAt
    updatedAt
}`;

export const rosterChecklistItemFragment = /* GraphQL */ `
fragment rosterChecklistItemFragment on RosterChecklistItem {
    createdAt
    status
    id
    group
    descriptionExplanation
    order
    rosterChecklistId
    titleQuestion
    typeId
    updatedAt
}`;

import moment from 'moment-timezone'
import store from '@/store/index'
/**
 * This function is used to send by parameter the current date in a specific format and is used for * daily rostering
 * @returns date format: YYYY-MM-DD
 */
const getTenantTimeZone = ()=> store.state.userInfo?.tenant.timeZone || ''

export function date(onlyDate = null){
    var today = new Date()
    const tenantTimeZone = getTenantTimeZone()
    if(onlyDate && tenantTimeZone){
        const dateTenantTimeZone = moment().tz(tenantTimeZone).format()
        today = dateTenantTimeZone.split("T")[0]
    }
    return today
}

export function dateTimeZone(date) {
    const todayConvert = getUtcTimestampInTenantTimeZone(date)
    return todayConvert.split("T")[0]
}

export function getUtcTimestampInTenantTimeZone(timestamp) {
    const parseZone = moment.tz(timestamp, getTenantTimeZone()).utc().format();
    return parseZone;
}

export function getDateLimitsInTenantTimeZone(date) {
    const tenantTimeZone = getTenantTimeZone()
    const isValidTimezone = !!moment.tz.zone(tenantTimeZone);
    const dateStr = moment(date).format('YYYY-MM-DD');
    if (!isValidTimezone) {
        return [
            moment(dateStr, 'YYYY-MM-DD').toISOString(),
            moment(dateStr, 'YYYY-MM-DD').add(1, 'day').toISOString(),
        ];
    }
    const start = moment.tz(dateStr, tenantTimeZone).toISOString();
    const end = moment.tz(dateStr, tenantTimeZone).add(1, 'day').toISOString();
    return [start, end];
}

export function getDateLimitsInTenantTimeZoneHistoryType(date, historyType = 'Odometer Reading') {
    const parseDate = getDateLimitsInTenantTimeZone(date);
    const mapParseDate = parseDate.map((date) => {
        return { vehicleHistoryType: historyType, accidentDate: date }
    });
    return mapParseDate;
}

export const betweenDateTime = function(date, start, end){
    const a = new Date(date).getTime()
    const b = new Date(start).getTime()
    const c = new Date(end).getTime()
    return a > b && a < c
}


export const isAfterDateTime = function(dateA, dateB){
    const a = new Date(dateA).getTime()
    const b = new Date(dateB).getTime()
    return a > b
}

export const getShortMonthName = function(monthIndex){
  // Create a moment object for the first day of the specified month
  const monthMoment = moment().month(monthIndex).date(1);

  // Format the moment object to get the short month name
  const shortMonthName = monthMoment.format('MMM');

  return shortMonthName;
}
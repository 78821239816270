<template>
  <div v-loading="loading">
    <!-- CARD GRID -->
    <div class="mt-4 grid grid-cols-8 grid-flow-row gap-4">

      <div class="col-span-8 p-4 relative border-2 rounded-lg shadow">
        <div class="float-left">
          <p class="inline-block text-gray-600 text-base font-bold mr-2">COMPANY DETAILS</p>
        </div>
        <div class="flex space-x-4 justify-end">
          <i 
            @click="openEditTenantModal"
            class="icon-button hover:text-brand uil uil-pen"/>
        </div>
        <el-form label-position="top" size="small" >
          <div class="block md:flex md:space-x-4">
            <div class="flex-1">
              <el-form-item label="Company Name" prop="companyName" class="non-edit-field" :class="{'placeholder': !tenant.companyName}">
                <div class="flex">
                  {{ tenant.companyName | text }}
                  <el-tooltip class="item flex" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                      <div slot="content" class="custom-tooltip-content-overflow">
                          <p class="font-bold mb-1">Company Name Change Log</p>
                          <p v-html="tenant.nameLog"></p>
                      </div>
                      <p class="text-gray-600"><i class="uil uil-question-circle text-base"></i></p>
                  </el-tooltip>
                </div>
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Short Code" prop="shortCode" class="non-edit-field" :class="{'placeholder': !tenant.shortCode}">
                {{ tenant.shortCode | text }}
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Billing Email" prop="billingEmail" class="non-edit-field" :class="{'placeholder': !tenant.stripeBillingEmail}">
                {{ tenant.stripeBillingEmail | text }}
              </el-form-item>
            </div>
            <div class="flex-1">
              <el-form-item label="Address" prop="address" class="non-edit-field" :class="{'placeholder': !tenant.addressLine1}">
                {{ tenant.addressLine1 | text }}
              </el-form-item>
              <el-form-item v-if="tenant.addressLine2" prop="addressLine2" class="non-edit-field -mt-4">
                {{ tenant.addressLine2 | text }}
              </el-form-item>
              <el-form-item v-if="addressLine3" prop="addressLine3" class="non-edit-field -mt-4">
                {{ addressLine3 | text }}
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <div v-if="showTrial" class="p-4 rounded-lg shadow col-span-8 border-2">
        <div class="flex">
          <div class="flex-1">
            <p class="text-gray-600 text-lg font-bold ">HERA TRIAL</p>
           
          </div>
          <div class="flex-initial text-center"> 
            <p class=" text-2xl font-bold text-gray-600">{{trialDaysRemainingDisplay}}</p>
            <p class="text-xs mb-2 text-gray-600">Days Remaining</p>
          </div>
        </div>
      </div>


      <!-- CURRENT PAYMENT PLAN -->
      <div class="col-span-8 md:col-span-4 p-4 relative border-2 rounded-lg shadow">
        <div class="text-gray-600 text-base font-bold">CURRENT PAYMENT PLAN</div>
        <EstimatedTotal
          :selected="selected"
          :costPerDriver="costPerDriver"
          :numActiveStaff="numActiveStaff"/>
        <div class="w-full absolute bottom-0 mb-4 pr-8 mt-20">
          <el-button data-cy="plan-edit-btn" class="mt-4 w-full" type="primary" @click="handleEdit()" :disabled="premiumBundleActive">Edit</el-button>
          <!-- <div v-if="premiumBundleActive" class="text-xxs text-center -mb-4 text-gray-700">Hera Premium selected until {{tenant.bundleDeactivationAllowedAt | date}}</div> -->
        </div>
      </div>

      <!-- SAVED CARDS -->
      <div  class="col-span-8 md:col-span-4 p-4 relative border-2 rounded-lg shadow" >
        <p  v-if="!newCard" class="inline-block text-gray-600 text-base font-bold">SAVED CARDS</p>
        <p  v-else class="inline-block text-gray-600 text-base font-bold">ADD NEW CARD</p>
      <!-- <p class="inline-block text-gray-600 text-base font-bold">ADD NEW CARD</p> -->
        <transition :name="transitionName" mode="out-in">
          <!-- <keep-alive > -->
            <SavedCards
              v-if="!newCard"
              key="savedCards"
              class="child-view"
              :loadTrigger="loadTrigger"
              :checkout="false"
              @add-new-card="addNewCard"/>
            <NewCard
              v-else
              key="newCard"
              class="child-view"
              :tenant="tenant"
              :loadTrigger="loadTrigger"
              :clearNewCardForm="clearNewCardForm"
              :checkout="false"
              @cancel="cancelNewCard"
              @complete-order="completeOrder"/>
          <!-- </keep-alive> -->
        </transition>
      </div>
    </div>

    <el-dialog
      title="Edit Company Details"
      :visible.sync="modalOpen"
      width="30%"
      :close-on-click-modal="false"
      >
        <CompanyForm :formFields="editTenantData" :rules="rules" ref="companyForm" v-loading="loading"/>

        <span slot="footer" class="dialog-footer">
          <ButtonGroupWrapper>
            <template slot-scope="{disabled, setDisabled}">
              <!-- The below span is there to trigger reactivity inside slot scope -->
              <span hidden>{{ loading }}</span>
              <el-button
                :disabled="disabled || loading"
                @click="modalOpen = false"
              >
                Cancel
              </el-button>
              <ButtonWrapper
                type="primary"
                :clickHandler="updateTenant"
                :disabled="disabled || loading"
                @cb-start="setDisabled(true)"
                @cb-end="setDisabled(false)"
              >
                Update
              </ButtonWrapper>
            </template>
          </ButtonGroupWrapper>
        </span>
    </el-dialog>

  </div>
</template>

<script>
import { Auth, API } from 'aws-amplify';
import EstimatedTotal from './EstimatedTotal'
import SavedCards from './SavedCards'
import NewCard from './NewCard'
import CompanyForm from '../../CompanyDetails/components/CompanyForm'
import { updateTenant } from '@/graphql/mutations'
import { getTenantUpdatedTimestamp } from '@/utilities/timestampQueries'
import Validator from '@/utilities/FormValidator'
import ButtonWrapper from '@/components/shared/ButtonWrapper/ButtonWrapper';
import ButtonGroupWrapper from '@/components/shared/ButtonGroupWrapper/ButtonGroupWrapper';

export default {
  name: 'PlanOverview',
  props: ['selected', 'costPerDriver', 'numActiveStaff', 'tenant', 'loadTrigger'],
  components:{
    EstimatedTotal,
    SavedCards,
    NewCard,
    CompanyForm,
    ButtonWrapper,
    ButtonGroupWrapper,
},
  data(){
    return{
      transitionName: 'slide-right',
      loading: false,
      newCard: false,
      modalOpen: false,
      editTenantData: {},
      rules: {
        stripeBillingEmail: [
          { message: 'Please enter a valid email', trigger: 'blur', validator: Validator.email },
        ],
        companyName: [
          {required: true, message: "Please introduce a company name", trigger: 'blur'}
        ],
      }
    }
  },

  computed:{
    addressLine3(){
      var city = this.tenant.addressCity
      var state = this.tenant.addressState
      var zip = this.tenant.addressZip
      if(!city && !state && !zip) return ''
      return `${city}, ${state} ${zip}`
    },

    premiumBundleActive(){
      if(!this.tenant.bundleDeactivationAllowedAt) return false
      var currentTime = new Date()
      var endTime = new Date(this.tenant.bundleDeactivationAllowedAt)
      var expTimePassed = currentTime.getTime() >= endTime.getTime()
      return !expTimePassed && this.tenant.accountPremiumStatus.includes('bundle')
    },
    

    showTrial(){
      return !this.$store.getters.isTrialExpired
    },
    trialDaysRemainingDisplay(){
      if(!this.tenant.trialExpDate) return ''
      else{
        var daysRemaining = Math.ceil( ( new Date(this.tenant.trialExpDate).getTime() -  new Date().getTime()) / (1000 * 3600 * 24) )
        return daysRemaining < 0 ? 0 : daysRemaining
      }
    },

  },

  methods:{
    handleEdit(){
      this.$emit('edit-purchase-options')
    },

    addNewCard(){
      this.transitionName = 'slide-left'
      this.clearNewCardForm = !this.clearNewCardForm
      this.newCard = true
    },

    cancelNewCard(){
      this.$confirm('All unsaved changes will be lost. Continue?', 'Warning', {
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
      .then(() => {     
        this.transitionName = 'slide-right'
        this.newCard = false
      })
      .catch(() => {
        console.error("canceled delete")         
      });
    },

    completeOrder(allInvoicesPaid){
      this.displayUserNotification({
        title: "Success",
        type: "success",
        message: "Card added and set as Active",
        offset: allInvoicesPaid ? 0 : 125
      })

      this.transitionName = 'slide-right'
      this.newCard = false
    },

    /** Copy tenant data to editTenantData and open modal */
    openEditTenantModal(){
      this.modalOpen = true        
      let tenant = {...this.tenant}
      delete tenant.users
      this.editTenantData = tenant        
    },

    async updateStripeCustomer(){
      try{
          let apiName = 'stripeSetup'
          let path = '/customer/' + this.tenant.stripeCustomerId
          let post = {
            body: {
              name: this.editTenantData.companyName,
              email: this.editTenantData.stripeBillingEmail,
              address:{
                city: this.editTenantData.adddressCity,
                line1: this.editTenantData.addressLine1,
                line2: this.editTenantData.addressLine2,
                postal_code: this.editTenantData.addressZip,
                state: this.editTenantData.addressState
              }
            }
          }
          var result = await safeFunction(API.put)(apiName, path, post)
          return result.customer
      }catch(e){
          console.error(e)
          this.displayUserError(e)
      }finally{
          this.isLoading = false
      }
    },

    async updateTenantNameInCognitoUserAttributes(group, tenantName){
      let apiName = 'AdminQueries';
      let path = '/updateTenantName';
      let post = {
        body: {
          "group": group,
          "tenantName": tenantName 
        }, 
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
      }
      safeFunction(API.post)(apiName, path, post);
    },

    // async updateIntercomCustomer(){
    //   try{
    //     let apiName = 'intercomUpdateCompanies'
    //     let path = '/intercom-update/'
    //     let post = {
    //       body: {
    //         id: this.tenant.id,
    //         name: this.editTenantData.companyName,
    //         email: this.editTenantData.stripeBillingEmail,
    //       },
    //       headers: {
    //         'Content-Type' : 'application/json',
    //         Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    //       } 
    //     }
    //     var result = await safeFunction(API.post)(apiName, path, post)
    //     return result
    //   }catch(e){
    //     this.displayUserError(e)
    //   }
    // },

    async updateTenant(){
      try {
        this.loading = true
         // validate form
        this.$refs.companyForm.$refs.form.validate((valid) => {
          if (!valid) {
            throw{
              errors: [
                { message: "Please fill all required form fields"}
              ]
            }
          }
        })

        // udpate Dynamo
       
        let newnameLog = this.tenant.nameLog;
        if (this.tenant.companyName != this.editTenantData.companyName){
          //name changed
          if(this.$store.getters.isSystemAdmin){
            var timestamp = this.getTenantTimeCustomFormat();
            var oldLog = this.tenant.nameLog ? this.tenant.nameLog : ''
            newnameLog = oldLog + 'Name changed from "' + this.tenant.companyName + '" to "' + this.editTenantData.companyName + '" at ' + timestamp + '<br/>'
          }else{
            this.editTenantData.companyName = this.tenant.companyName
          }
          //Update Tenant Name in Cognito user attributes
          await this.updateTenantNameInCognitoUserAttributes(this.$store.state.userInfo.tenant.group, this.editTenantData.companyName)
        }

        const input = {
          ...this.editTenantData,
          id: this.tenant.id,
          nameLog: newnameLog,
          timeZone: this.tenant.timeZone,
          updatedAt: this.tenant.updatedAt
        }
        const validateCompanyForm =  this.validateForm()
        if(validateCompanyForm){
          await this.validateTenantBillingInfo(input)
          var result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
          
          // update Intercom
          // await this.updateIntercomCustomer()
          
          // update Stripe
          if(this.tenant.stripeCustomerId) await this.updateStripeCustomer()
          
          this.modalOpen = false
          
          this.$message({
            type: 'success',
            message: 'Company Updated'
          });
          
          
          this.$emit('load-tenant')
        }
      } catch(e) {
        this.displayUserError(e)        
      } finally {
        this.loading = false
      }
    },
    async validateTenantBillingInfo(input){
      let apiName = 'tenant'
      let payload = {
        "id": input.id,
        "stripeBillingEmail": input.stripeBillingEmail,
        "stripeCustomerId": input.stripeCustomerId
      }
      let path = '/validate-billing-info'
      let post = {
        body: payload
      }
      var result = await safeFunction(API.post)(apiName, path, post)
      if(!result.success){
        throw new Error(result.message)
      }
    },
    validateForm(){
      if (!this.editTenantData.companyName) throw 'Please fill company name'
      return true
    },
  }
}
</script>

<style scoped>
  .child-view {
    transition: all .5s cubic-bezier(.55,0,.1,1);
  }
  .slide-left-enter, .slide-right-leave-active {
    opacity: 0;
    -webkit-transform: translate(30px, 0);
    transform: translate(30px, 0);
  }
  .slide-left-leave-active, .slide-right-enter {
    opacity: 0;
    -webkit-transform: translate(-30px, 0);
    transform: translate(-30px, 0);
  }
</style>

<template>
    <div>
        <TableCard tablecard-class="roster-checklist-table-card mt-4 rounded-lg">
            <template v-slot:table-filters>
                <ContainerFilter inTable>
                <template v-slot:title>
                    <span class="uppercase">ROSTER CHECKLIST TEMPLATES</span>
                </template>
                </ContainerFilter>
            </template>
            
            <template v-slot:table-searchbox>
                <search-box inTable @search-data="searchData"/>
            </template>

            <!--Table-->
            <CustomTable
            inTable
            ref="table"
            v-loading="loading"
            :defaultSort = "{prop: 'date', order: 'descending'}" 
            :columns="cols"
            :data="filteredTableData"
            :totalRecords="totalRosterChecklists"
            emptyText="No Roster Checklists"
            footerTable="Total Roster Checklists"
            withTableMenu
            @click-menu="handleCommand($event, 'create-new-roster-checklist', {})"
            :optionsHeader="optionsContextHeader"
            :optionsRows="[]"
            :expandCollapseColumn="showExpandCollapseColumn"
            :paginationCurrentPage="1"
            :pagination="false"
            >
                <template #assignedToId="row">
                    {{ itemById(rosterChecklistAssignedToOptions, row.assignedToId, 'option') | text }}
                    <ol v-if="row.assignedToId">
                        <template v-if="showUserMenu(row)">
                            <li class="text-blue-700" v-for="(rclUsr, i) in (row.assignedUsers.items||[])" :key="row.id + rclUsr.id + i">
                                {{ itemById(userList, rclUsr.userId, (user) => user ? user.firstName + ' ' + user.lastName : null) | text }}
                            </li>
                        </template>
                        <template v-else-if="row.checklistTasks">
                            <li class="text-blue-700" v-for="(rclChkbx, i) in checklistTaskAssociates(row)" :key="row.id + rclChkbx.id + i">
                                {{ itemById(rosterChecklistTasks, rclChkbx.taskId, 'option') | text }}
                            </li>
                        </template>
                    </ol>
                </template>
                <template #completionFrequencyId="row">
                    {{ itemById(rosterChecklistCompletionFrequencies, row.completionFrequencyId, 'option') | text }}
                </template>
                <template #items="row">
                    <ol>
                        <template v-if="row.rosterChecklistItems && row.rosterChecklistItems.items && row.rosterChecklistItems.items.length">
                            <li v-for="(item, i) in row.rosterChecklistItems.items" :key="row.id + item.id + i">
                                {{item.titleQuestion}} <span class="text-blue-700">({{ itemById(rosterChecklistItemTypes, item.typeId, 'option') | text }})</span>
                            </li>
                        </template>
                    </ol>
                </template>
                <template #status="row">
                    <div class="inline-flex">
                        <span
                        class="font-bold rounded-full bg-opacity-70 px-3 text-xs max-w-full flex-initial"
                        :class="{'bg-red-500 text-red-900': row.status !== 'ENABLED', 'bg-green-500 text-green-900': row.status === 'ENABLED'}"
                        >{{row.status === 'ENABLED'?'Enabled' : 'Disabled'}}</span>
                    </div>
                </template>
                <template #custom-dropdown="row">
                    <span class="context-menu mt-px mr-1" @click="popoverHandler($event, 'click', row)">
                        <i class="uil uil-ellipsis-h roster-checklist-popover-reference"></i>
                    </span>
                </template>
            </CustomTable>
        </TableCard>
        <el-popover
            v-for="count in 2" :key="count"
            ref="popoverElement"
            width="300"
            trigger="click"
            popper-class="checklist-popper"
            :open-delay="0"
            :close-delay="0"
            >
            <div class="checklist-menu w-full" v-if="popoverElement.item">
                <div class="checklist-menu-item leading-8" @click="handleCommand($event, 'start-edit-roster-checklist', popoverElement.item)">Edit this Roster Checklist Template</div>
                <div class="el-dropdown-menu__item--divided"></div>
                <div class="el-radio-group flex pt-2 pb-1">
                    <label class="el-button el-button--mini checklist-menu-item flex-1" :class="{'checklist-menu-item-enabled':popoverElement.item.status === 'ENABLED'}" @click="handleCommand($event, 'enable-roster-checklist', popoverElement.item)">Enable</label>
                    <label class="el-button el-button--mini checklist-menu-item flex-1" :class="{'checklist-menu-item-enabled':popoverElement.item.status === 'DISABLED'}" @click="handleCommand($event, 'disable-roster-checklist', popoverElement.item)">Disable</label>
                </div>
                <div class="el-dropdown-menu__item--divided"></div>
                <div class="checklist-menu-item leading-8" @click="handleCommand($event, 'delete-roster-checklist', popoverElement.item)">Delete this Roster Checklist Template</div>
            </div>

        </el-popover>
    </div>
</template>
<script>

import TableCard from "@/components/TableCard/TableCard"
import ContainerFilter from "@/components/TableCard/ContainerFilter"
import SearchBox from "@/components/TableCard/SearchBox"
import CustomTable from "@/components/TableCard/CustomTable"
import {
    rosterChecklistFragment,
    rosterChecklistUserFragment,
    rosterChecklistTaskFragment,
    rosterChecklistItemFragment
} from '../fragments'
import rosterChecklistMixin from '../rosterChecklist'
import { multipleMutations } from '@/utilities/gqlMutations'
import { mapState, mapGetters } from 'vuex'

export default{
    mixins: [ rosterChecklistMixin ],
    components: {
        TableCard,
        CustomTable,
        SearchBox,
        ContainerFilter
    },
    data(){
        return {
            loading: false,
            tableData: [],
            cols: [
                { name: "Roster Checklist Name", col: "name", fixed: false, width: "350", sortable: true, sortMethod: this.sortLastName },
                { name: "Items", col: "items", fixed: false, width: "475", sortable: true, sortMethod: this.sortLastName },
                { name: "Frequency", col: "completionFrequencyId", fixed: false, width: "175", sortable: true, sortMethod: this.sortLastName },
                { name: "Assigned To", col: "assignedToId", fixed: false, width: "400", sortable: true, sortMethod: this.sortLastName },
                { name: "Status", col: "status", fixed: false, width: "100"},
                ],
            search: null,
            optionsContextHeader: [
                { label: "Create New Roster Checklist Template", divided:  false},
            ],
            showExpandCollapseColumn: true,
            currentPage: 1,
            popoverElement: {
                show: null,
                reference: null,
                item: null,
                previousStyle: null,
                disableUp: true,
                disableDown: true,
                popoverIndex: 0
            },
        }
    },
    computed:{
        ...mapState(['valuelists', 'userInfo']),
        ...mapGetters(['getCustomListLookupMap']),
        ...mapState('subscriptionStore', [
            'userList',
        ]),
        rosterChecklistCompletionFrequencies(){
            return this.getCustomListLookupMap['roster-checklist-completion-frequency']?.options || []
        },
        rosterChecklistItemTypes(){
            return this.getCustomListLookupMap['roster-checklist-item-type']?.options || []
        },
        rosterChecklistAssignedToOptions(){
            return this.getCustomListLookupMap['roster-checklist-assigned-to-option']?.options || []
        },
        rosterChecklistTasks(){
            return this.getCustomListLookupMap['roster-checklist-associate-task']?.options || []
        },
        totalRosterChecklists(){
            return this.tableData?.length
        },
        filteredTableData(){
            return this.customFilterArray(this.tableData, this.search)
        }
    },
    methods:{

        checklistTaskAssociates(row){
            const checklistTasks = row.checklistTasks?.items || []
            return checklistTasks.filter(ct => ct.type === "ASSOCIATE_TASK")
        },
                
        customFilterArray(tableData, search){
            const found = this.filterArray(tableData, search)
            const itemsCollection = tableData.map(rcl => rcl.rosterChecklistItems?.items)
            if(itemsCollection.length){
                const itemsFound = this.filterArray(itemsCollection, search)
                return tableData.filter(rcl=> found.some(rcl1=>rcl1 === rcl) || itemsFound.some(items1=>items1 === (rcl.rosterChecklistItems?.items || [])))
            }else{
                return found
            }
        },
        searchData(text){
            this.search = text
        },
        showUserMenu(row){
            const itemKey = this.itemById(this.rosterChecklistAssignedToOptions, row.assignedToId, 'key')
            return itemKey === 'RCHKLST_AS_SPECIFIC_HERA_USERS'
        },
        async handleCommand(event, action, row){
            switch(action){
                case 'create-new-roster-checklist':{
                    this.$router.push({
                        name: 'SettingsRosterChecklistsEditor',
                        params: {
                            rosterChecklistId: 'create-new',
                            parentProps: {
                                tableData: this.tableData
                            }
                        }
                    })
                    break
                }
                case 'start-edit-roster-checklist':{
                    this.$router.push({
                        name: 'SettingsRosterChecklistsEditor',
                        params: {
                            rosterChecklistId: row.id,
                            parentProps: {
                                formData: row,
                                tableData: this.tableData
                            }
                        }
                    })
                    break
                }
                case 'enable-roster-checklist':{
                    if(row.status === 'ENABLED'){
                        break
                    }
                    const multiQuery = {
                        RosterChecklist: {
                            update: {
                                input: {
                                    id: row.id,
                                    status: 'ENABLED'
                                }
                            },
                            fragment: rosterChecklistFragment
                        }
                    }

                    const response = await multipleMutations(multiQuery, 'MutationsOnRosterChecklistsTableCard')
                    
                    const newStatus = response.data.updateRosterChecklist[0].status

                    const rcl = this.tableData.find(rcl => rcl.id === row.id)

                    rcl.status = row.status = newStatus

                    console.log({enable:action, response})
                    break
                }
                case 'disable-roster-checklist':{
                    if(row.status === 'DISABLED'){
                        break
                    }
                    const multiQuery = {
                        RosterChecklist: {
                            update: {
                                input: {
                                    id: row.id,
                                    status: 'DISABLED'
                                }
                            },
                            fragment: rosterChecklistFragment
                        }
                    }

                    const response = await multipleMutations(multiQuery, 'MutationsOnRosterChecklistsTableCard')
                    
                    const newStatus = response.data.updateRosterChecklist[0].status

                    const rcl = this.tableData.find(rcl => rcl.id === row.id)

                    rcl.status = row.status = newStatus

                    console.log({disable:action, response})
                    break
                }
                case 'delete-roster-checklist':{

                    const performDelete = await this.$confirm('This will permanently delete this Roster Checklist Template. Continue?', 'Warning', {
                        type: 'warning',
                        confirmButtonText: 'Delete',
                        cancelButtonText: 'Cancel',
                    }).catch(e => e )

                    if(performDelete === 'confirm'){
                        
                        const usersInput = row.assignedUsers.items?.map(item => {
                            return {
                                input: {
                                    id: item.id
                                }
                            }
                        })

                        const tasksInput = row.checklistTasks.items?.map(item => {
                            return {
                                input: {
                                    id: item.id
                                }
                            }
                        })
                        
                        const itemsInput = row.rosterChecklistItems.items?.map(item => {
                            return {
                                input: {
                                    id: item.id
                                }
                            }
                        })

                        const multiQuery = {
                            RosterChecklist: {
                                delete: {
                                    input: {
                                        id: row.id
                                    }
                                },
                                fragment: rosterChecklistFragment
                            },
                            RosterChecklistUser:{
                                delete: usersInput,
                                fragment: rosterChecklistUserFragment
                            },
                            RosterChecklistTask:{
                                delete: tasksInput,
                                fragment: rosterChecklistTaskFragment
                            },
                            RosterChecklistItem: {
                                delete: itemsInput,
                                fragment: rosterChecklistItemFragment
                            }
                        }

                        const response = await multipleMutations(multiQuery, 'MutationsOnRosterChecklistsTableCard')
                        if(response.data?.deleteRosterChecklist.length){
                            const rowIndex = this.tableData.findIndex(rcl => rcl.id === row.id)
                            this.tableData.splice(rowIndex, 1)
                        }

                    }
                    
                    break
                }
            }
            if(action === 'start-edit-roster-checklist' || action === 'delete-roster-checklist'){
                this.popoverHandler(event, false, row)
            }
        },
        
        bodyClickHandler(event){
            event.preventDefault()
            if(!event.target.classList.contains('roster-checklist-popover-reference')){
                this.popoverHandler(event, false, this.popoverElement.item)
            }
        },

        popoverHandler(event, show, row){
            this.popoverElement.item = row
            const popoverEls = this.$refs['popoverElement']
            const oldPopper = popoverEls[this.popoverElement.popoverIndex]

            oldPopper.doClose()
            oldPopper.referenceElm = undefined
            oldPopper.updatePopper()

            this.popoverElement.popoverIndex = [1, 0][this.popoverElement.popoverIndex]

            if(show){
                const trEl = event.target.closest('tr')
                this.popoverElement.disableUp = !trEl.previousSibling || trEl.previousSibling.nodeName !== 'TR'
                this.popoverElement.disableDown = !trEl.nextSibling || trEl.nextSibling.nodeName !== 'TR'
                const newPopper = popoverEls[this.popoverElement.popoverIndex]
                const style = newPopper.$refs.popper.getAttribute('style')
                const displayNone = /display\s*:\s*none/gi.test(style)

                if(displayNone){
                    newPopper.referenceElm = event.target
                    newPopper.updatePopper()
                    newPopper.doShow()
                }
            }else{
                this.popoverElement.item = null
            }

        },
        loadData: async function(){
            let tableData = await this.getRosterChecklists()
            tableData.forEach(rcl => {
                if(rcl.rosterChecklistItems?.items){
                    rcl.rosterChecklistItems.items = rcl.rosterChecklistItems.items.sort((a, b)=> a.order - b.order)
                }
            })
            this.tableData = tableData
        }
    },
    created(){
        this.$nextTick(()=>{
            document.body.addEventListener('click', this.bodyClickHandler)
        })
    },
    async mounted(){
        await this.loadData()
    },
    beforeDestroy(){
        document.body.removeEventListener('click', this.bodyClickHandler)
    }
}
</script>
<style>
    .checklist-popper{
        @apply px-0 py-2 w-72 !important;
    }
    .checklist-menu .checklist-menu-item{
        @apply text-gray-700 hover:text-blue-600 hover:bg-gray-200 cursor-pointer;
    }
    .checklist-menu .checklist-menu-item.el-button{
        @apply h-8 leading-4;
    }
    .checklist-menu .el-dropdown-menu__item--divided::before{
        @apply bg-transparent !important;
    }
    .checklist-menu > div{
        @apply px-4 !important;
    }
    .checklist-menu .el-radio-group{
        @apply w-full inline-flex !important;
    }
    .checklist-menu .el-radio-group .el-radio-button, .checklist-menu .el-radio-group .el-radio-button .el-radio-button__inner{
        @apply w-full !important;
    }
    .checklist-menu .el-radio-group .el-button:nth-child(1){
        border-radius: 5px 0px 0px 5px;
        border-right-color: theme('colors.gray.300');
    }
    .checklist-menu .el-radio-group .el-button:nth-child(1):not(.is-disabled):hover{
        border-right-color: theme('colors.blue.300');
    }
    .checklist-menu .el-radio-group .el-button:nth-child(1):active{
        border-right-color: theme('colors.blue.700');
    }
    .checklist-menu .el-radio-group .el-button:nth-child(2){
        @apply ml-0 !important;
        border-radius: 0px 5px 5px 0px;
        border-left-color: theme('colors.gray.300');
    }
    .checklist-menu .el-radio-group .el-button:nth-child(2):not(.is-disabled):hover{
        border-left-color: theme('colors.blue.300');
    }
    .checklist-menu .el-radio-group .el-button:nth-child(2):active{
        border-left-color: theme('colors.blue.700');
    }
    .checklist-menu .el-radio-group .el-button:active, .checklist-menu-item-enabled{
        @apply bg-blue-700 border-blue-700 text-white !important;
    }
</style>
<style lang="scss">
.roster-checklist-table-card{
    .cell ol {
        > li::before {
            @apply text-blue-700 font-bold inline-block w-1 ml-0;
            content: "\2022";
        }
        > li{
            padding-left: 7px;
            text-indent: -7px;
        }
    }
}
</style>
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionTasksReports
        permissionVehicleManagement
        permissionCustomLists
        permissionManageLabels
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      users {
        items {
          id
          permissionFullAccess
          permissionLogin
          isCreator
          isOwner
        }
      }
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      group
      cognitoSub
      firstName
      lastName
      phone
      email
      emailVerified
      role
      receiveSmsTaskReminders
      receiveEmailTaskReminders
      receiveSmsTaskAssignments
      receiveEmailTaskAssignments
      permissionLogin
      permissionFullAccess
      permissionDocuments
      permissionCounselings
      permissionManageCounselings
      permissionAccidents
      permissionInjuries
      permissionDrugTests
      permissionDailyRostering
      permissionMessenger
      permissionPerformanceCoaching
      permissionDAManagement
      permissionCustomLists
      permissionManageLabels
      permissionCompanySettingsRosterChecklistTemplates
      permissionMessageTemplate
      permissionTasksReports
      permissionVehicleManagement
      permissionVehiclePhotoLogs
      zohoCrmContactRecordId
      createdAt
      updatedAt
    }
  }
`;

export const usersByPhone = /* GraphQL */ `
  query UsersByPhone(
    $phone: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        group
      }
      nextToken
    }
  }
`;
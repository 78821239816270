<template>
    <div v-loading="loading" class="mb-16">
        <div v-if="!loading && !savedCards.length" class="text-gray-600 mt-4">
            No saved cards
        </div>
        <div v-for="(card, index) in savedCards" :key="card.id" class="mt-4">
            <div class="w-full rounded-lg border p-4" :class="{'border-red-600 border-2': card.chargeError != null}">
            <div class="flex">
                <div class="flex-1">
                <div>
                  <span>{{card.billing_details.name}}</span>
                  <span v-if="card.chargeError" class="text-red-600 text-center">
                    <el-tooltip class="item" effect="dark" placement="top-start">
                      <div slot="content">
                        {{card.chargeError}}
                      </div>
                      <i class="uil uil-exclamation-circle"></i>
                    </el-tooltip>
                  </span>
                </div>
                <div class="text-xs text-gray-600">
                    <p><span>{{toTitleCase(card.card.funding)}}</span>
                    <span> ****{{card.card.last4}}</span></p>
                </div>
                <div class="text-xs text-gray-600">
                    <p><span>Exp {{card.card.exp_month}}/{{card.card.exp_year}}</span></p>
                </div>
                </div>
                <div class="flex-1" >
                    <div class="float-right text-right">
                        <el-dropdown v-if="!card.active"  @command="handleCommand" class="block -mt-2 -mb-3" trigger="click">
                            <i 
                                title="Card Options"
                                class="icon-button hover:text-brand uil uil-ellipsis-h"/>
                            <el-dropdown-menu slot="dropdown" >
                                <el-dropdown-item :command="{action: 'setActive', index: index}" v-if="!card.active">Set as Active Card</el-dropdown-item>
                                <el-dropdown-item :command="{action: 'delete', index: index}">Delete</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <i v-if="card.active" class="uil uil-check text-2xl text-great"></i>
                        <div v-if="card.active" class="text-great text-xxs text-center -mt-2">Active</div>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div class="w-full absolute bottom-0 mb-4 pr-8 mt-20"  v-if="!loading">
            <div class="flex">
                <el-button class="flex-1" :disabled="loading" @click="showOrderSummary" v-if="checkout">Back</el-button>
                <el-button type="primary" class="flex-1" @click="continueWithSelected" :disabled="!savedCards.length"  v-if="checkout">Continue With Active</el-button>
                <el-button type="primary" class="flex-1" @click="addNewCard" v-if="!checkout">Add New Card</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Auth, API } from 'aws-amplify';
import { createCard, deleteCard, createNotification } from '@/graphql/mutations'
import { cardsByGroup, invoicesByGroupAndStatus, getTenant, updateCard } from '../queries'
import { getUser } from '@/api/queries'
import generateTemplate from '@/scripts/unpaidInvoiceTemplate.js'
import { mapMutations } from 'vuex'


export default {
  
  name: 'SavedCards',
  props: ['checkout', 'loadTrigger'],
  data(){
    return{
        loading: false,
        savedCards: []
    }
  },

  watch: {
    loadTrigger(){
      this.loadCards()
    }
  },

  mounted(){    
    this.loadCards()
  },

  computed: {
    selectedCard(){
      if(this.savedCards.length < 1) return {}
      return this.savedCards.filter(card => { return card.selected})[0]
    },
    activeCard(){
      if(this.savedCards.length < 1) return {}
      return this.savedCards.filter(card => { return card.active})[0]
    }
  },

  methods:{
    ...mapMutations([
      'setUserInfo'
    ]),

    addNewCard(){
        this.$emit('add-new-card')
    },

    continueWithSelected(){
        this.$emit('complete-order')
    },

    async deleteCard(index){
        try{   
            // delete card from Stripe
            await this.deleteStripeCustomerPaymentMethod(this.savedCards[index])

            // delete card from Dynamo
            let input = {
                id: this.savedCards[index].dbId,
            }
            var result = await this.api(deleteCard, {input})

            this.loadCards()
            this.displayUserNotification({
                type: 'success',
                title: 'Success',
                message: 'Card Deleted'
            });
        }
        catch(e){
            console.error('errpor on deleteCard', e)
            this.displayUserError('Something went wrong. Please try adding it again, or if there continues to be a problem, please contact Hera Support via the chat in the lower right-hand corner.')
        }
    },

    async deleteStripeCustomerPaymentMethod(card){
      let apiName = 'stripeSetup'
      let path = '/customer/' + card.customer + '/' + card.id
      var result = await API.del(apiName, path)
      return result.paymentMethods
    },

    handleCommand(command){
      if(command.action == "delete"){
        if(this.savedCards[command.index].active == true){
            this.displayUserNotification({
                title: "Error",
                type: "error",
                message: "Active card cannot be deleted"
            })
            return
        }
        this.$confirm('This will permanently delete this saved card. Continue?', 'Warning', {
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            type: 'warning'
        })
        .then(() => {     
            this.deleteCard(command.index)
        })
        .catch(() => {
            console.error("canceled delete")         
        });
      }
      else if(command.action == 'setActive'){
          this.confirmUpdateActiveCard(command.index)
      }
    },

    async confirmUpdateActiveCard(index){
      // check for unpaid invoices
      let input = {
        group: this.$store.state.userInfo.tenant.group,
        status: {
          eq: "Payment Error"
        }
      }
      var unpaidInvoices = await this.gLoadListAll(invoicesByGroupAndStatus, input, 'invoicesByGroupAndStatus')
      
      if(unpaidInvoices.length){
        var invoicesHtml = generateTemplate(unpaidInvoices)
        this.$confirm('Warning', {
          type: 'warning',
          title: 'Warning',
          message: invoicesHtml,
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'Continue',
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false
        }).then(() => {
          this.updateActiveCard(index, true)          
        }).catch(() => {
          console.error("canceled confirm")         
        });
      }
      else{
        this.updateActiveCard(index, false)
      }
    },

    async updateActiveCard(index, chargeOutstandingInvoices){
      this.loading = true
      try{

        await this.setAllCardstoInactive()

        let input = {
            id: this.savedCards[index].dbId,
            active: true
        }
        
        result = await this.api(updateCard, {input})

        // also update default card in stripe
        try{
          let apiName = 'stripeSetup'
          let path = '/customer/' + this.savedCards[index].customer
          let post = {
            body: {
            name: this.savedCards[index].billing_details.name,
            email: this.savedCards[index].billing_details.email,
            address: this.savedCards[index].billing_details.address,
            defaultPaymentId: this.savedCards[index].id
            }
          }
          await safeFunction(API.put)(apiName, path, post)
        }catch(e){
          console.error('error updating customer', e)
          this.displayUserError(e)
        }
        
        // charge outstanding invoices
        var allInvoicesPaid
        if(chargeOutstandingInvoices){
          let apiName = 'stripeSetup'
          let path = '/payment-intent/' + this.$store.state.userInfo.tenant.id
          let post = {body: {}}
          var result = await API.post(apiName, path, post)
          allInvoicesPaid = result.allInvoicesPaid

          // check if all invoices were charged successfully
          if(allInvoicesPaid){
            // update user info of logged in user
            const userId = this.$store.state.userInfo.id
            const user = await this.api(getUser, {id: userId, group: 'system_admin'})

            //Set user info
            this.setUserInfo(user.data.getUser)

            // Update user info for all other users of tenant using notification system
            const tenant = await this.api(getTenant,{id: this.$store.state.userInfo.tenant.id});
            const users = tenant.data.getTenant.users.items

            //Create Notifications
            for await (const user of users) {

              let input = {
                title: "Outstanding Invoices Paid",
                description: 'All outstanding invoices for your account have been paid.',
                isReadS: "true",
                clickAction: "RELOAD",
                owner: user.cognitoSub,
                group: 'system_admin',
                expirationTTL: Math.floor((new Date().getTime() + 90 * 24 * 60 * 60 * 1000) / 1000)                
              }
              if( input.owner && user.id != this.$store.state.userInfo.id){
                await this.api(createNotification, {input} )
              }
            }

            // reload window to updae top nav display
            location.reload()
          }
          else{
            // display error that not all invoices were charged successfully
            this.displayUserError('All outstanding invoices were not charged successfully. Please try an alternate card.')
          }
        }

        await this.loadCards()

        this.displayUserNotification({
          type: 'success',
          title: 'Success',
          message: 'Active Card Updated',
          offset: allInvoicesPaid ? 0 : 125
        });

      }
      catch(e){
        console.error(e)
        this.displayUserError(e)
      }finally{
          this.loading = false
      }
    },

    async setAllCardstoInactive() {
        let savedCards = this.savedCards

        for (const card of savedCards) {
          let cardDbId = card.dbId
          let input = {
            id: cardDbId,
            active: false
          }

          await this.api(updateCard, {input})
        }
        
    },

    async getStripeCustomerPaymentMethods(customerId){
      try{
          let apiName = 'stripeSetup'
          let path = '/customer/' + customerId
          var result = await API.get(apiName, path)
          return result.paymentMethods
      }catch(e){
          console.error(e)
      }
    },

    async loadCards(){
      this.loading = true
      try{
        // load details from Stripe
        if(this.$store.state.userInfo.tenant.stripeCustomerId){
          var paymentMethods = await this.getStripeCustomerPaymentMethods(this.$store.state.userInfo.tenant.stripeCustomerId)
        }

        //console.log('paymentMethods', JSON.stringify(paymentMethods))

        if(paymentMethods) {
          // load card from Dynamo
          await Promise.all(paymentMethods.data.map(async(card)=>{
            let input = {
              group: this.$store.state.userInfo.tenant.group,
              filter: {
                stripePaymentMethodId: {
                  eq: card.id
                }
              }
            }
            let dbCard = await this.gLoadListAll(cardsByGroup, input, 'cardsByGroup')
            if(dbCard[0]){
              card.active = dbCard[0].active
              card.dbId = dbCard[0].id
              card.chargeError = dbCard[0].chargeError
            }
          }))

          this.savedCards = paymentMethods.data
        }
      }catch(e){
        console.error(e)
        this.displayUserError(e)
      }finally{
          this.loading = false
      }
    },

    showOrderSummary(){
      this.$emit('show-order-summary')
    },
  }
}
</script>

<style scoped>

    .block{
        display: block !important;
    }
    .border-2{
      border-width: 2px !important;
    }

</style>